import React, { FC, Fragment, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import { useDispatch } from 'react-redux'; // Import useDispatch
import { toast } from 'react-toastify';
import { PlatformDataType } from 'src/interfaces/Platform.interface';
import { uploadAsync } from 'src/redux/actions/uploadAction';
import { useAppDispatch } from 'src/redux/storeHooks';

const CSVUploadModal :FC<{ show: boolean, handleClose: () => void, id: string, platformData:PlatformDataType|null }> = ({ show, handleClose, id, platformData }) => {
    // Component logic
    const [csvFile, setCSVFile] = useState<File | null>(null);
    const dispatch = useAppDispatch(); // Initialize useDispatch

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0]; // Access the first file from the FileList
        console.log("selectedFile;",selectedFile)
        if (selectedFile) {
            setCSVFile(selectedFile);
        } else {
            console.error("No file selected.");
        }
    };

    const handleUploadClick = async () => {
        try {
            if (csvFile instanceof File) {
                 dispatch(uploadAsync({ id: id, csvFile }));
    
                // Display success message using react-toastify
                toast.success("File uploaded successfully! It might take up to 10 minutes to process your file.", {
                    autoClose: 10000, // Close the toast after 10 seconds
                });

                setCSVFile(null);
                handleClose();
            } else {
                console.error("Invalid file object:", csvFile);
                throw new Error("Invalid file object.");
            }
        } catch (error) {
            console.error("Error uploading CSV file:", error);
        }
    };

    

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload CSV of Products from {platformData?.display_name ?? '' }</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <span >Please go to your {platformData?.display_name ?? '' } Seller Portal & download the Product list from there to upload it here.</span>
                    <br/>
                    {/* Input field for uploading CSV file */}
                    <input type="file" className='mt-3' onChange={handleFileChange} />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant='primary' onClick={handleUploadClick}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
};

export default CSVUploadModal;






// import React, { FC, Fragment, useState } from 'react';
// import { Modal, Button } from 'react-bootstrap';

// import { useDispatch } from 'react-redux'; // Import useDispatch
// import { uploadAsync } from 'src/redux/actions/uploadAction';
// import { useAppDispatch } from 'src/redux/storeHooks';



// const CSVUploadModal :FC<{ show: boolean, handleClose: () => void, id: string }> = ({ show, handleClose, id }) => {
//     // Component logic
//     const [csvFile, setCSVFile] = useState<File | null>(null);
//     const [relativePath, setRelativePath] = useState<string | null>(null); // State to hold relative path
//     const dispatch = useAppDispatch(); // Initialize useDispatch

//     // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     //     const selectedFile = event.target.files?.[0]; // Access the first file from the FileList
//     //     if (selectedFile) {
//     //         setCSVFile(selectedFile);
//     //     } else {
//     //         console.error("No file selected.");
//     //     }
//     // };
//     const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const selectedFile = event.target.files?.[0];
//         if (selectedFile) {
//           setCSVFile(selectedFile);
      
//           // Use the library to extract relative path (example with file-saver)
//           const reader = new FileReader();
//           reader.readAsDataURL(selectedFile);
//           reader.onload = (event) => {
//             const dataURL = event.target?.result as string;
//             const blob = new Blob([dataURL], { type: selectedFile.type });
//             const path = URL.createObjectURL(blob); // Create relative path
//             setRelativePath(path); // Set relative path state
//           };
//         } else {
//           console.error("No file selected.");
//         }
//       };
      
//       const handleUploadClick = () => {
//         try {
//             if (csvFile instanceof File) {
//                 if (!relativePath) {
//                     throw new Error("Relative path not available.");
//                 }

//                 dispatch(uploadAsync({ id: id,csvFile: relativePath })); // Pass relative path to action
//                 setCSVFile(null);
//                 handleClose();
//             } else {
//                 console.error("Invalid file object:", csvFile);
//                 throw new Error("Invalid file object.");
//             }
//         } catch (error) {
//             console.error("Error uploading CSV file:", error);
//         }
//     };
    

//     return (
//         <Fragment>
//             <Modal show={show} onHide={handleClose}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Upload CSV</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {/* Input field for uploading CSV file */}
//                     <input type="file" onChange={handleFileChange} />
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <Button variant='secondary' onClick={handleClose}>
//                         Close
//                     </Button>
//                     <Button variant='primary' onClick={handleUploadClick}>
//                         Upload
//                     </Button>
//                 </Modal.Footer>
//             </Modal>
//         </Fragment>
//     );
// };

// export default CSVUploadModal;
