import React, {FC, useState} from 'react'
import {Button, Offcanvas} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import { PRODUCT_TYPE } from 'src/app/_interfaces/product.interface'

const ColProductName: FC<{
  name: {title: string; subtitle: string; productImage: string}
  onClickProductName: () => void
  onClose:()=>void
}> = ({name, onClickProductName, onClose }) => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <img src={name.productImage} alt={name.title} className='w-25px h-25px ' />
        <div className='ms-2'>
          <p className='mb-0'>
            <Button
              variant='link'
              className=' fw-bold fs-5 link-underline-info py-0 text-success text-decoration-underline mb-0'
              onClick={() => {
                onClickProductName()
              }}
            >
              {name.title}
            </Button>
          </p>
          <p className='h6 text-muted'>{name.subtitle}</p>
        </div>
      </div>
    </>
  )
}

const CommonColumn: FC<{rowData: string}> = ({rowData}) => {
  return (
    <>
      <p>{rowData}</p>
    </>
  )
}

const AllProducts: FC = () => {
  const [openProductDataInfo, setOpenProductDataInfo] = useState(false)
  const [productData, setProductData] = useState<PRODUCT_TYPE|null>(null)

  const onOpenProductDataCanvas = (itemData) =>{
    setOpenProductDataInfo(true)
    setProductData(itemData)
  }

const onCloseProductDataCanvas = () =>{
    setOpenProductDataInfo(false)
    setProductData(null)
  }

  const columns = [
    {
      name: 'Name',
      cell: (row) => (
        <ColProductName name={row.name} onClickProductName={()=>onOpenProductDataCanvas(row)} onClose={onCloseProductDataCanvas} />
      ),
      sortable: true,
      grow: 2,
      width: '240px',
    },
    {
      name: 'SKU',
      cell: (row) => <CommonColumn rowData={row.sku} />,
      sortable: true,
      width: '160px',
    },
    {
      name: 'Origin',
      cell: (row) => <img src={row.origin} alt={row.origin} className='w-45px h-45px ' />,
      sortable: true,
      right: true,
      width: '100px',
      center: true,
    },
    {
      name: 'Manage Stock',
      cell: (row) => <CommonColumn rowData={row.mngStock} />,
      sortable: true,
      right: true,
      width: '160px',
      center: true,
    },
    {
      name: 'In Stock',
      cell: (row) => <CommonColumn rowData={row.inStock + ''} />,
      sortable: true,
      right: true,
      width: '120px',
      center: true,
    },
    {
      name: 'Total Available',
      cell: (row) => <CommonColumn rowData={row.totalAvailable} />,
      sortable: true,
      right: true,
      width: '160px',
      center: true,
    },
    {
      name: 'Variants',
      cell: (row) => <CommonColumn rowData={row.variants} />,
      sortable: true,
      right: true,
      width: '120px',
      center: true,
    },
    {
      name: 'Price',
      cell: (row) => <CommonColumn rowData={row.price} />,
      sortable: true,
      right: true,
      width: '100px',
      center: true,
    },
    {
      name: 'Sale Price',
      cell: (row) => <CommonColumn rowData={row.salePrice} />,
      sortable: true,
      right: true,
      width: '120px',
      center: true,
    },
    {
      name: 'Product Type',
      cell: (row) => <CommonColumn rowData={row.productType} />,
      sortable: true,
      right: true,
      width: '160px',
      center: true,
    },

    {
      name: 'Status',
      cell: (row) => <p className='badge badge-success'>{row.status}</p>,
      sortable: true,
      right: true,
      width: '100px',
      center: true,
    },
    {
      name: 'Last Modified',
      cell: (row) => <CommonColumn rowData={row.lastModified} />,
      sortable: true,
      right: true,
      width: '160px',
      center: true,
    },

    {
      name: 'Listing',
      cell: (row) =>
        row.listing.map((item) => {
          return <img src={item} className='w-15px h-15px ms-2' />
        }),
      sortable: true,
      right: true,
      width: '140px',
      center: true,
    },
  ]

  const data = [
    {
      name: {
        title: 'Sample Product',
        subtitle: 'This is sample product',
        productImage: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      },
      sku: 'SOFTBUNNYSKU-01',
      origin: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      mngStock: 'Yes',
      inStock: true,
      totalAvailable: '20',
      variants: '6',
      price: '20',
      salePrice: '0',
      productType: 'Market-Connect',
      status: 'Active',
      lastModified: 'Feb 26 2024',
      listing: [
        'https://app.litcommerce.com/static/images/markets/squarespace.png',
        'https://app.litcommerce.com/static/images/markets/shopify.svg',
      ],
    },
    {
      name: {
        title: 'Sample Product',
        subtitle: 'This is sample product',
        productImage: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      },
      sku: 'SOFTBUNNYSKU-01',
      origin: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      mngStock: 'Yes',
      inStock: true,
      totalAvailable: '20',
      variants: '6',
      price: '20',
      salePrice: '0',
      productType: 'Market-Connect',
      status: 'Active',
      lastModified: 'Feb 26 2024',
      listing: [
        'https://app.litcommerce.com/static/images/markets/squarespace.png',
        'https://app.litcommerce.com/static/images/markets/shopify.svg',
      ],
    },
    {
      name: {
        title: 'Sample Product',
        subtitle: 'This is sample product',
        productImage: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      },
      sku: 'SOFTBUNNYSKU-01',
      origin: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      mngStock: 'Yes',
      inStock: true,
      totalAvailable: '20',
      variants: '6',
      price: '20',
      salePrice: '0',
      productType: 'Market-Connect',
      status: 'Active',
      lastModified: 'Feb 26 2024',
      listing: [
        'https://app.litcommerce.com/static/images/markets/squarespace.png',
        'https://app.litcommerce.com/static/images/markets/shopify.svg',
      ],
    },
    {
      name: {
        title: 'Sample Product',
        subtitle: 'This is sample product',
        productImage: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      },
      sku: 'SOFTBUNNYSKU-01',
      origin: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      mngStock: 'Yes',
      inStock: true,
      totalAvailable: '20',
      variants: '6',
      price: '20',
      salePrice: '0',
      productType: 'Market-Connect',
      status: 'Active',
      lastModified: 'Feb 26 2024',
      listing: [
        'https://app.litcommerce.com/static/images/markets/squarespace.png',
        'https://app.litcommerce.com/static/images/markets/shopify.svg',
      ],
    },
    {
      name: {
        title: 'Sample Product',
        subtitle: 'This is sample product',
        productImage: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      },
      sku: 'SOFTBUNNYSKU-01',
      origin: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      mngStock: 'Yes',
      inStock: true,
      totalAvailable: '20',
      variants: '6',
      price: '20',
      salePrice: '0',
      productType: 'Market-Connect',
      status: 'Active',
      lastModified: 'Feb 26 2024',
      listing: [
        'https://app.litcommerce.com/static/images/markets/squarespace.png',
        'https://app.litcommerce.com/static/images/markets/shopify.svg',
      ],
    },
    {
      name: {
        title: 'Sample Product',
        subtitle: 'This is sample product',
        productImage: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      },
      sku: 'SOFTBUNNYSKU-01',
      origin: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      mngStock: 'Yes',
      inStock: true,
      totalAvailable: '20',
      variants: '6',
      price: '20',
      salePrice: '0',
      productType: 'Market-Connect',
      status: 'Active',
      lastModified: 'Feb 26 2024',
      listing: [
        'https://app.litcommerce.com/static/images/markets/squarespace.png',
        'https://app.litcommerce.com/static/images/markets/shopify.svg',
      ],
    },
    {
      name: {
        title: 'Sample Product',
        subtitle: 'This is sample product',
        productImage: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      },
      sku: 'SOFTBUNNYSKU-01',
      origin: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      mngStock: 'Yes',
      inStock: true,
      totalAvailable: '20',
      variants: '6',
      price: '20',
      salePrice: '0',
      productType: 'Market-Connect',
      status: 'Active',
      lastModified: 'Feb 26 2024',
      listing: [
        'https://app.litcommerce.com/static/images/markets/squarespace.png',
        'https://app.litcommerce.com/static/images/markets/shopify.svg',
      ],
    },
    {
      name: {
        title: 'Sample Product',
        subtitle: 'This is sample product',
        productImage: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      },
      sku: 'SOFTBUNNYSKU-01',
      origin: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      mngStock: 'Yes',
      inStock: true,
      totalAvailable: '20',
      variants: '6',
      price: '20',
      salePrice: '0',
      productType: 'Market-Connect',
      status: 'Active',
      lastModified: 'Feb 26 2024',
      listing: [
        'https://app.litcommerce.com/static/images/markets/squarespace.png',
        'https://app.litcommerce.com/static/images/markets/shopify.svg',
      ],
    },
    {
      name: {
        title: 'Sample Product',
        subtitle: 'This is sample product',
        productImage: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      },
      sku: 'SOFTBUNNYSKU-01',
      origin: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      mngStock: 'Yes',
      inStock: true,
      totalAvailable: '20',
      variants: '6',
      price: '20',
      salePrice: '0',
      productType: 'Market-Connect',
      status: 'Active',
      lastModified: 'Feb 26 2024',
      listing: [
        'https://app.litcommerce.com/static/images/markets/squarespace.png',
        'https://app.litcommerce.com/static/images/markets/shopify.svg',
      ],
    },
    {
      name: {
        title: 'Sample Product',
        subtitle: 'This is sample product',
        productImage: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      },
      sku: 'SOFTBUNNYSKU-01',
      origin: 'https://app.litcommerce.com/static/images/markets/shopify.svg',
      mngStock: 'Yes',
      inStock: true,
      totalAvailable: '20',
      variants: '6',
      price: '20',
      salePrice: '0',
      productType: 'Market-Connect',
      status: 'Active',
      lastModified: 'Feb 26 2024',
      listing: [
        'https://app.litcommerce.com/static/images/markets/squarespace.png',
        'https://app.litcommerce.com/static/images/markets/shopify.svg',
      ],
    },
  ]

  const handleChange = () => {}

  return (
    <>
      <div className='d-flex justify-content-end'>
        <input
          className='form-control mr-sm-2 w-25 input-sm'
          type='search'
          placeholder='Search'
          aria-label='Search'
        />
      </div>
      <DataTable
        title='All Products'
        fixedHeader={true}
        data={data}
        columns={columns}
        selectableRows
        onSelectedRowsChange={handleChange}
        pagination
        customStyles={{
          table: {
            style: {
              height: '60vh',
            },
          },
          headCells: {
            style: {fontWeight: 'bold', fontSize: '14px'},
          },
          cells: {
            style: {
              padding: '10px',
              borderBottom: '1px solid gainsboro',
            },
          },
        }}
      />
      <Offcanvas
        placement='end'
        as='div'
        show={openProductDataInfo}
        onHide={()=>onCloseProductDataCanvas()}
        style={{width:"70%"}}
      >
        <Offcanvas.Header closeButton ></Offcanvas.Header>
        <Offcanvas.Body>
         <div className='p-8' >
          <div className='d-flex align-items-center  ' >
            <label className=' h5 mb-0 text-muted w-200px' >Products Origin :</label>
            <div className='d-flex ms-8 align-items-center' >
              <img src={productData?.name.productImage} alt={productData?.name.title} className='h-30px w-30px ' />
            <p className='ms-4 mb-0 h3' >Main Store</p>
            </div>
          </div>
          <div className='separator mt-4 mb-8  border-2' ></div>
          <div className='d-flex align-items-center  ' >
            <label className=' h5 mb-0 text-muted w-200px' >Name :</label>
            <div className='d-flex ms-4 align-items-center' >
            
            <p className='ms-4 mb-0 h3' >{productData?.name.title}</p>
            </div>
          </div>
          <div className='separator mt-4 mb-8 border-2' ></div>
          <div className='d-flex align-items-center  ' >
            <label className=' h5 mb-0 text-muted w-200px' >Status :</label>
            <div className='d-flex ms-8 align-items-center' >
            <p className='badge badge-success' >{productData?.status}</p>
            </div>
          </div>
          <div className='separator mt-4 mb-8 border-2' ></div>
         </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default AllProducts
