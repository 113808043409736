import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

// export const getOrdersListAsync = createAsyncThunk(
//   'orders/getOrdersListAsync',
//   async function (params:{id:string, subparams:any  }, toolkit) {
//     return await AxiosClient('GET', `/api/platform-orders-list`, [], toolkit, params.subparams,  )
//   }
// )

export const getOrdersListAsync = createAsyncThunk(
  'orders/getOrdersListAsync',
  async function (
    params: {
      paramData: {page: number; limit?: number; sort_by: string; sort_order: string;sync_status:string;api_created_at_min?:string;
        api_created_at_max:string}
      selectedPlatform: string
    },
    toolkit
  ) {
    console.log('Order Params', params)
    return await AxiosClient(
      'GET',
      `/api/platform-orders-list/${params.selectedPlatform}`,
      [],
      toolkit,
      params.paramData
    )
  }
)

export const getOrderDetailsAsync = createAsyncThunk(
  'orders/getOrderDetailsAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', `/api/platform-orders/${params}`, [], toolkit, [])
  }
)
export const orderResyncAsync = createAsyncThunk(
  'orders/orderResyncAsync',
  async function (payload: {failed_order_ids: number[]}, toolkit) {
    return await AxiosClient('POST', `/api/resync-failed-orders`, payload, toolkit, [])
  }
)
