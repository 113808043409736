import React, {FC, useState} from 'react'

const Article: FC<{item: any}> = ({item}) => {
  const [isReadMoreOn, setIsReadMoreOn] = useState(false)

  return (
    <div className='d-flex flex-column '>
      <small className='text-end fs-8 '>
        {item.date}
        {/* <FontAwesomeIcon icon={faBullhorn} /> <FontAwesomeIcon icon={faNewspaper} /> */}
      </small>
      <span className='mb-0 text-secondary fw-bold '>{item.title}</span>
      <div className={ !isReadMoreOn ? 'text-truncate' : '' }>
        <small className='fs-6'>{item.description}</small>
      </div>
      <button className='btn btn-sm text-primary fw-bold text-end' onClick={()=>setIsReadMoreOn(!isReadMoreOn)} >{ isReadMoreOn ? 'Read Less' : 'Read More...'}</button>
    </div>
  )
}

export default Article
