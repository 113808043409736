import React, {FC} from 'react'
import {Modal} from 'react-bootstrap'
import { KTSVG } from 'src/marketConnect/helpers'
type CommonAlertModalPropsType = {
  title: string
  subtitle: string
  isVisible: boolean
  onSuccess: () => void
  onCancel: () => void
  successDisabled?:boolean
  isTermsVisible?:boolean
  cancelBtnTitle?: string
  successBtnTitle?: string
  successBtnClasses?:string
  setIsVisible ?: (isVisible: boolean) => void
  showSaveButton ?: boolean
  showCloseButton?:boolean
  iconPath?:string
  iconClass?:string
}
const CommonAlertModal: FC<CommonAlertModalPropsType> = ({
  isVisible,
  setIsVisible,
  subtitle,
  title,
  cancelBtnTitle = 'Cancel',
  successBtnTitle = 'Yes',
  onCancel,
  onSuccess,
  successDisabled=false,
  isTermsVisible=true,
  showSaveButton=true,
  successBtnClasses='btn btn-sm btn-danger  m-2',
  showCloseButton=true,
  iconPath="/media/icons/duotune/general/gen044.svg",
  iconClass="svg-icon-danger svg-icon-4hx"
  
}) => {
  return (
    <Modal show={isVisible} centered  contentClassName='bg-light-danger' >
      <Modal.Body>
        <div className='alert alert-dismissible bg-light-danger d-flex flex-center flex-column py-6 px-10 px-lg-20 '>
          <div className='text-center'>
            <div className='mb-4'>
            <KTSVG path={iconPath} className={iconClass} />
            </div>
            
            {/* <h5 className='fw-bold fs-1 mb-3'>Alert</h5>
            <div className='separator separator-dashed border-danger opacity-25 mb-5'></div> */}
            <div className='mb-7'>
             <h4>{title}</h4> <small>{subtitle}</small>
              { isTermsVisible ?
                <>
                  <a href='#' className='fw-bolder me-1'>
                  Terms and Conditions
                </a>{' '}
                for more info.
                </> : null
              }
            </div>
            <div className='d-flex flex-center flex-wrap'>
              { showCloseButton ? <button
                onClick={onCancel}
                className='btn btn-outline  btn-outline-danger btn-active-danger m-2'
              >
                {cancelBtnTitle}
              </button> : null }
              {showSaveButton ? <button disabled={successDisabled} onClick={onSuccess} className={successBtnClasses}>{successBtnTitle}</button>: null}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
export default CommonAlertModal