import React, {Fragment} from 'react'
import { KTIcon } from 'src/marketConnect/helpers'

const TemplateListItemcopy = ({name, onUpdate, onDelete}) => {
  return (
    <>
      
      <tr className='w-100' >
      <td className='w-75 max-w-100px  ps-3'>
        <span className='text-dark fw-bold text-hover-primary fs-6'>{name}</span>
      </td>
      <td className='w-25 text-center p-1'>
        <div className='d-flex justify-content-center'>
          <button
           
            className='btn btn-icon btn-bg-light btn-color-primary btn-sm me-3 mt-1'
            onClick={onUpdate}
          >
            <KTIcon iconName='pencil'  className='fs-4' />
          </button>
          <button
           
            className='btn btn-icon btn-bg-light btn-color-danger btn-sm mt-1'
            onClick={onDelete}
          >
            <KTIcon iconName='trash' className='fs-4' />
          </button>
        </div>
      </td>
    </tr>
           

      {/* <div className='d-flex   justify-content-between align-items-center  m-2 mt-2'>
        <div className='d-flex justify-content-start col-9'>
          <p className='mb-0 ms-4'>{name}</p>
        </div>
        <div className='d-flex align-items-center col-3 justify-content-end me-3'>
          {' '}
         
          <button
            className='btn btn-primary d-flex align-items-center justify-content-center '
            onClick={onUpdate}
          >
            <i className='bi bi-pencil-square'></i>
          </button>
      
          <button
            className='btn  btn-danger d-flex align-items-center justify-content-center ms-3
          '
            onClick={onDelete} 
          >
            <i className='bi bi-trash'></i>
          </button>
        </div>
        <div className='border-bottom border-2 my-2 mt-5' /> 
      </div> */}
    </>
  )
}

export default TemplateListItemcopy
