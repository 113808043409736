import {FC} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTIcon, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import { useAppDispatch } from 'src/redux/storeHooks'
import {  setProductTablePaginationData, setReset } from 'src/redux/slices/productSlice'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  imgSrc?: string
}

const SidebarMenuItem2: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
  imgSrc,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {app} = config
  const dispatch=useAppDispatch()

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to} style={{ textDecoration: 'none' }} onClick={()=>{
        // alert("side")
        dispatch(setProductTablePaginationData({
          page: 1,
          limit: 10,
          search_string: '',
          sort_by: '', 
          sort_order:''
        }))
        dispatch(setReset());
      }}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className='menu-icon'>
            {' '}
           <img src={imgSrc}   className='h-25px w-25px' />
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className='menu-title 'style={{  fontSize: '16px' }} >{title}</span>
      </Link>
      {children}
    </div>
  )     





   
}

export {SidebarMenuItem2}
