import {createSlice, isAnyOf} from '@reduxjs/toolkit'

import { fetchUserAccountDetailsAsync } from '../actions/accountActions';
import { USER_DATA } from 'src/app/_interfaces/auth.interface';


type fetchUserAccountPayoad = {
  fetchedUserAccountData: USER_DATA | null
  fetchedUserAccountLoading: boolean
}

const initialState: fetchUserAccountPayoad = {
    fetchedUserAccountData: null,
    fetchedUserAccountLoading: false,
}

const fetchUserAccountDataSlice = createSlice({
  name: 'fetchUserAccountDataSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(fetchUserAccountDetailsAsync.pending), (state, action) => {
      state.fetchedUserAccountLoading = true
    })
    builder.addMatcher(isAnyOf(fetchUserAccountDetailsAsync.fulfilled), (state, action) => {
      state.fetchedUserAccountData = action?.payload
      console.log("state.fetchedUserAccountData:",state.fetchedUserAccountData)
      state.fetchedUserAccountLoading = false
    })
    builder.addMatcher(isAnyOf(fetchUserAccountDetailsAsync.rejected), (state) => {
      state.fetchedUserAccountLoading = false
    })
  },
  reducers: {
    getFetchedUserAccountData: (state, action: {payload: fetchUserAccountPayoad; type: string}) => {
      state.fetchedUserAccountLoading = action.payload?.fetchedUserAccountLoading
      state.fetchedUserAccountData = action?.payload.fetchedUserAccountData
    },
  },
});

export const {getFetchedUserAccountData} = fetchUserAccountDataSlice.actions
export default fetchUserAccountDataSlice.reducer;
