import React, {FC, useState} from 'react'
import Dropzone from 'react-dropzone'
import { InitialValuesForCreateProduct, OPTION_ADD_ITEM, OPTION_VALUE } from 'src/interfaces/createProductInterface'
import {KTSVG} from 'src/marketConnect/helpers'

type MappedVariantSubItemProps = {
  item : string, 
  index : number , 
  mapIndex : number , 
  options : OPTION_ADD_ITEM[]|[]
  values:InitialValuesForCreateProduct
}

const MappedVariantSubItem:FC<MappedVariantSubItemProps> = ({item, index, mapIndex, options, values }) => {
  const [selectedImages, setSelectedImages] = useState<string | null>(null)
  const [isQuantityFieldDisable, setIsQuantityFieldDisable] = useState<boolean>(true)

  return (
    <div className='ps-8'>
      <div>
        <div className='row w-100 align-items-center'>
          <div className='col-2'>
            <Dropzone
              onDrop={(acceptedFiles) => {
                console.log(acceptedFiles)
                if (acceptedFiles.length) {
                  setSelectedImages(URL.createObjectURL(acceptedFiles[0]))
                }
              }}
            >
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()} className='text-center'>
                    <input {...getInputProps()} />
                    {!selectedImages ? (
                      <KTSVG
                        path='media/icons/duotune/general/gen006.svg'
                        className='svg-icon-muted svg-icon-2hx cursor-pointer'
                      />
                    ) : (
                      <img src={selectedImages} className='h-30px w-30px shadow cursor-pointer' />
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className='col-3'>
            <div
              className='accordion-header py-3 d-flex'
              data-bs-toggle='collapse'
              data-bs-target={`#kt_accordion_mappedVariantItem${index + 1}_item_${mapIndex + 1}`}
            >
              <div className='w-100'>
                <h5 className='mb-0'>{item}</h5>
              </div>
            </div>
          </div>
          <div className='col-5 d-inline-flex justify-content-end'>
            <input style={{width:"84%"}} type='text' defaultValue={values.basePrice} className='form-control form-control-sm' />
          </div>
          <div className='col-2'>
            <input type='text' disabled={isQuantityFieldDisable} defaultValue={10} className='form-control form-control-sm' />
          </div>
        </div>
      </div>
     <div className="separator"></div>
    </div>
  )
}

export default MappedVariantSubItem
