import React, { FC, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CustomUploadAdapterPlugin } from 'src/utils/CustomUploadAdapterPlugin';


type AppCustomEditorPropsType = {
  value: string;
  onChangeData: (params: string) => void;
  placeholder?: string;
};

const AppCustomEditor: FC<AppCustomEditorPropsType> = ({ value, placeholder, onChangeData }) => {
  const [editorD, setEditor] = useState<ClassicEditor | null>(null);

  return (
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={{
          placeholder: placeholder,
          // plugins:[ Image],
          image: {
            toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side', 'resizeImage'],
            resizeUnit: '%',
            resizeOptions: [
              {
                name: 'resizeImage:original',
                value: null,
                icon: 'original',
              },
              {
                name: 'resizeImage:50',
                value: '50',
                icon: 'medium',
              },
              {
                name: 'resizeImage:75',
                value: '75',
                icon: 'large',
              },
            ],
          },
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            'blockQuote',
            'undo',
            'redo',
            'imageUpload',
          ],
          extraPlugins: [CustomUploadAdapterPlugin],
          ui: {
            poweredBy: {
              label: 'Commurcium',
              horizontalOffset: 0,
              position: 'border',
              side: 'right',
              verticalOffset: 0,
              forceVisible: false,
            },
          },
        }}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log('Editor is ready to use!', editor);
          setEditor(editor);
        }}
        onChange={(event, editor) => {
          if (editorD) {
            onChangeData(editorD?.getData());
          }
          // onChangeData(event.source)
          console.log('event', event);
          console.log('oncha', editorD?.getData());
        }}
        onBlur={(event, editor) => {
          console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          console.log('Focus.', editor);
        }}
      />
  );
};

export default AppCustomEditor;
