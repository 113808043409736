import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import useAppToast from 'src/hooks/useAppToast';
import { updateForgottenPasswordAsync } from 'src/redux/actions/authActions';
import { Link, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const { forgetPasswordToken ,forgetPasswordTokenLoading} = useAppSelector((state) => state.auth);
  const { successToast, errorToast } = useAppToast();
  const [resetSuccess, setResetSuccess] = useState(false); // State for handling password reset success
  const navigate = useNavigate()

  const initialValues = {
    forgetPasswordToken:'', 
    password: '',
    confirm_password: '',
  };

  const passwordSchema = Yup.object().shape({
    password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$/,
      'Use 8 or more characters with at least one uppercase letter, one lowercase letter, one special character, and one number'
    )
      .required('Password is required'),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match')
      .required('Confirm password is required'),
  });
  

  const formik = useFormik({
    initialValues,
    validationSchema: passwordSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        
            if (!forgetPasswordToken) {
             return errorToast('Forget password token is missing');
            }
         dispatch(updateForgottenPasswordAsync({
          forgot_password_token: forgetPasswordToken,
          password: values.password,
          confirm_password: values.confirm_password,
        })).then((response)=>{
            if (response?.payload?.status_code === 200) {
                successToast(response?.payload?.massage)
                setTimeout(() => {
                    navigate("/login")
                  }, 1000);
            }else{
                errorToast(response?.payload?.massage)
            }
        })
       
        resetForm(); // Clear form fields on success
      
      } catch (error) {
        errorToast('Failed to update password');
      }
    },
  });

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      onSubmit={formik.handleSubmit}
    >

{forgetPasswordTokenLoading ? (
      <div className="text-center mb-10">
        <span className="spinner-border spinner-border-sm"></span>
        <span className="ms-2">Loading...</span>
      </div>
    ) : (
      <>
         {/* Title and instructions */}
         <div className="text-center mb-10">
        <h1 className="text-dark fw-bolder mb-3">Reset Password</h1>
        <div className="text-gray-500 fw-semibold fs-6">
          Enter your new password below to reset your account password.
        </div>
      </div>

      {/* Password fields with error handling */}
      <div className="fv-row mb-3">
        <label htmlFor="password" className="form-label fw-bolder text-dark fs-6 mb-0">
          Password
        </label>
        <input
          id="password"
          placeholder="Password"
          type="password"
          autoComplete="off"
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className="fv-row mb-3">
        <label htmlFor="confirm_password" className="form-label fw-bolder text-dark fs-6 mb-0">
          Confirm Password
        </label>
        <input
          id="confirm_password"
          placeholder="Confirm Password"
          type="password"
          autoComplete="off"
          {...formik.getFieldProps('confirm_password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password,
            },
            {
              'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
            }
          )}
        />
        {formik.touched.confirm_password && formik.errors.confirm_password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.confirm_password}</span>
            </div>
          </div>
        )}
      </div>

      {/* Success/Error Message */}
      {resetSuccess && (
        <div className="alert alert-success" role="alert">
          Password updated successfully!
        </div>
      )}
      {formik.submitCount > 0 && formik.errors.password && (
        <div className="alert alert-danger" role="alert">
          {formik.errors.password}
        </div>
      )}

      {/* Submit button with loading indicator */}
      <div className="d-flex flex-wrap justify-content-center pb-lg-0">
        <button
          type="submit"
          className={clsx(
            'btn btn-primary me-4',
            formik.isSubmitting && 'spinner-border spinner-border-sm'
          )}
          disabled={formik.isSubmitting}
        >
          <span className="indicator-label">Reset Password</span>
          {formik.isSubmitting && <span className="spinner-grow spinner-grow-sm ms-2"></span>}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancel
          </button>
        </Link>{' '}
      </div>
      </>
    )}

  
    </form>
  );
};
export default ResetPassword;
