import React, {FC, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import { verifyForgetPasswordAsync, verifyRegistrationAsync } from 'src/redux/actions/authActions'
import { setForgetPasswordToken } from 'src/redux/slices/authSlice'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'

const VerifyForgetPassword: FC = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { errorToast, successToast,  warningToast } = useAppToast()
  const {forgetPasswordTokenLoading} = useAppSelector((state) => state.auth)

  useEffect(() => {
    if(params?.forgot_password_token){
        dispatch(verifyForgetPasswordAsync({forgot_password_token: params.forgot_password_token }))
        .then((response)=>{
            console.log('response verify', response)
            if(response?.payload?.status_code===200){
              // successToast(response?.payload?.massage)
              if(params?.forgot_password_token){
              dispatch(setForgetPasswordToken({token:params?.forgot_password_token}))  
              setTimeout(() => {
                navigate("/auth/change-password")
              }, 200);
            
              }
            }else{
                warningToast("Something went wrong")
            }
        })
        // alert(params?.forgot_password_token)
       
       

    }
  }, [])

  return forgetPasswordTokenLoading ? <Loader /> : null;
}

export default VerifyForgetPassword
