import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'
import {PlatformSettingsDataType, connectedPlatformPayload} from 'src/interfaces/Platform.interface'
import { PlatformSettingDataTypesValues } from 'src/app/_interfaces/setting.interface'

export const fetchPlatformsAsync = createAsyncThunk(
  'platform/fetchPlatforms',
  async function (payload, toolkit) {
    return await AxiosClient('GET', '/available-platform', [], toolkit, [])
  }
)

// Async action to connect a new platform
export const connectPlatformAsync = createAsyncThunk(
  'platform/connectPlatform',
  async function (payload: connectedPlatformPayload, toolkit) {
    return await AxiosClient('POST', '/platform', payload, toolkit, [])
  }
)

export const fetchConnectedPlatformAsync = createAsyncThunk(
  'platform/fetchConnectedPlatform',
  async function (payload, toolkit) {
    return await AxiosClient('GET', '/platform', [], toolkit, [])
  }
)

export const retrivePlatformDataAsync = createAsyncThunk(
  'platform/retrivePlatformData',
  async function (payload: {id: any}, toolkit) {
    const url = `/api/retrieve-platform-data-new/${payload.id}`
    return await AxiosClient('GET', url, [], toolkit, [])
  }
)

export const deletePlatformDataAsync = createAsyncThunk(
  'platform/deletePlatformData',
  async function (payload: {id: number}, toolkit) {
    const url = `/platform/${payload.id}`
    return await AxiosClient('DELETE', url, [], toolkit, [])
  }
)

export const createPlatformSettingAsync = createAsyncThunk(
  'platform/createPlatformSetting',
  async function (payload: PlatformSettingDataTypesValues, toolkit) {
    const url = `/api/create-platform-settings/${payload.platformId}/settings`
    delete payload.platformId
    return await AxiosClient('POST', url, payload, toolkit, [])
  }
)


export const refreshPlatformDataAsync = createAsyncThunk(
  'platform/refreshPlatformDataAsync',
  async function (params: { platformId:number }, toolkit) {
    return await AxiosClient('POST', `/refresh_init_data/${params.platformId}`, [], toolkit, [])
  }
)


//uninstall shopify 

export const shopifyappUninstallAsync = createAsyncThunk(
  'platform/shopifyappUninstallAsync',
  async function (payload: {id: number}, toolkit) {
    const url = `/api/shopifyapp/uninstalled/platform_id/${payload.id}`
    return await AxiosClient('DELETE', url, [], toolkit, [])
  }
)