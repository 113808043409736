import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {savePlatformPriceAsync} from '../actions/productPricingAction'
import {
  getPlatformPriceAsync,
  deletPlatformPriceAsync,
  updatePlatformPriceAsync,
} from '../actions/productPricingAction'
type retrivePlatformProductPayload = {
  platformPricingTemplateData: any
  platformPricingTemplateLoading: boolean
  getPriceingtemplate: any[]
  getCountPrice: number
  previousPrice: any
  nextPrice: any
}
const initialState: retrivePlatformProductPayload = {
  platformPricingTemplateData: null,
  platformPricingTemplateLoading: false,

  getPriceingtemplate: [],
  getCountPrice: 0,
  previousPrice: null,
  nextPrice: null,
}
const templateAndPricingSlice = createSlice({
  name: 'templateAndPricingSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(savePlatformPriceAsync.pending), (state, action) => {
      state.platformPricingTemplateLoading = true
    })
    builder.addMatcher(isAnyOf(savePlatformPriceAsync.fulfilled), (state, action) => {
      state.platformPricingTemplateLoading = false
      state.platformPricingTemplateData = action?.payload
    })
    builder.addMatcher(isAnyOf(savePlatformPriceAsync.rejected), (state) => {
      state.platformPricingTemplateLoading = false
    })

    // GET

    builder.addMatcher(isAnyOf(getPlatformPriceAsync.pending), (state, action) => {
      state.platformPricingTemplateLoading = true
    })
    builder.addMatcher(isAnyOf(getPlatformPriceAsync.fulfilled), (state, action) => {
      if (action.payload?.results) {
        state.getPriceingtemplate = action?.payload?.results
        state.getCountPrice = action?.payload?.count
        state.previousPrice = action.payload?.previous
        state.nextPrice = action.payload?.next
      }
      state.platformPricingTemplateLoading = false
    })
    builder.addMatcher(isAnyOf(getPlatformPriceAsync.rejected), (state) => {
      state.platformPricingTemplateLoading = false
    })

    // update

    builder.addMatcher(isAnyOf(updatePlatformPriceAsync.pending), (state, action) => {
      state.platformPricingTemplateLoading = true
    })
    builder.addMatcher(isAnyOf(updatePlatformPriceAsync.fulfilled), (state, action) => {
      state.platformPricingTemplateLoading = false
      state.platformPricingTemplateData = action?.payload
    })
    builder.addMatcher(isAnyOf(updatePlatformPriceAsync.rejected), (state) => {
      state.platformPricingTemplateLoading = false
    })

    // delete

    builder.addMatcher(isAnyOf(deletPlatformPriceAsync.pending), (state, action) => {
      state.platformPricingTemplateLoading = true
    })
    builder.addMatcher(isAnyOf(deletPlatformPriceAsync.fulfilled), (state, action) => {
      console.log('action payload delete :', action?.payload)
      state.platformPricingTemplateLoading = false
      // console.log('action in slice ', action.payload.deletedPlatformId)
      // state.platformPricingTemplateData = state.platformPricingTemplateData?.filter(
      //   (platform) => platform.id !== action.payload.deletedPlatformId
      // )
    })
    builder.addMatcher(isAnyOf(deletPlatformPriceAsync.rejected), (state) => {
      state.platformPricingTemplateLoading = false
    })
  },
  reducers: {
    setPlatformPricingTemplateData: () => initialState,
  },
})
export const {setPlatformPricingTemplateData} = templateAndPricingSlice.actions
export default templateAndPricingSlice.reducer
