import React, {FC, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import useAppToast from 'src/hooks/useAppToast'
import { verifyRegistrationAsync } from 'src/redux/actions/authActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'

const VerifyUser: FC = () => {
  const params = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { errorToast, successToast,  warningToast } = useAppToast()
  const {authLoading} = useAppSelector((state) => state.auth)

  useEffect(() => {
    if(params?.activation_token){
        dispatch(verifyRegistrationAsync({activation_token: params.activation_token }))
        .then((response)=>{
            console.log('response verify', response)
            if(response.payload){

            }else{
                warningToast("Something went wrong")
            }
        })
    }
  }, [])

  if (authLoading) return <Loader />

  return null
}

export default VerifyUser
