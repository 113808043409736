import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'
import {PasswordChangeType} from 'src/app/_interfaces/passwordChange.interface'

export const updatepasswordAsync = createAsyncThunk(
  'user/updateUserpassword',
  async function (payload: PasswordChangeType, toolkit) {
    return await AxiosClient('POST', '/user/update-password', payload, toolkit, [])
  }
)
