export const accessSessionTokenKeyName = 'access_token'
export const refreshSessionTokenKeyName = 'refresh_token'
export const loggedInUserDataKeyName = 'loggedInUser'
export const shopifyDetailsForOauth = 'shopifyDetailsForOauth'
export const shopifyDetailsForWithoutAuth = 'shopifyDetailsForWithoutAuth'
export const loggedInAdminAccessToken = 'loggedInAdminAccessToken'
export const loggedInAdminRefreshToken = 'loggedInAdminRefreshToken'


export const shopifyAccessScope = [
  {label: 'read_orders', value: 'read_orders'},
  {label: 'write_orders', value: 'write_orders'},
  {label: 'read_draft_orders', value: 'read_draft_orders'},
  {label: 'write_draft_orders', value: 'write_draft_orders'},
  {label: 'read_files', value: 'read_files'},
  {label: 'write_files', value: 'write_files'},
  {label: 'read_inventory', value: 'read_inventory'},
  {label: 'write_inventory', value: 'write_inventory'},
  {label: 'read_locales', value: 'read_locales'},
  {label: 'write_locales', value: 'write_locales'},
  {label: 'read_markets', value: 'read_markets'},
  {label: 'write_markets', value: 'write_markets'},
  {label: 'read_orders', value: 'read_orders'},
  {label: 'write_orders', value: 'write_orders'},
  {label: 'read_products', value: 'read_products'},
  {label: 'write_products', value: 'write_products'},
  {label: 'read_returns', value: 'read_returns'},
  {label: 'write_returns', value: 'write_returns'},
]
