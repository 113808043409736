import React, { FC } from 'react';
import './PlatformCard.css'

import { Platform } from 'src/interfaces/Platform.interface';

type PlatformCardPropType = {
  data: Platform;
  onClickConnect: () => void;
};

const PlatformCard: FC<PlatformCardPropType> = ({ data, onClickConnect }) => {
  return (
    <div className='col-sm-6 col-lg-6 col-xl-4 col-md-6 m-0'>
      <div className='card position-relative card-xl-stretch mb-md-3 mb-2 mb-xl-8  bg-hover-secondary cursor-pointer'>
        <div className='card-body align-items-center p-7 pb-2  '>
          {/* Platform information */}
          <div className='d-flex row'>
            <div className='col-xxl-9 col-md-8 col-9'>
              <div className='d-flex flex-column flex-grow-1 py-2 py-lg-9 me-2 w-100'>
                <div className=' fw-bolder fs-4 fs-md-2 mb-2'>{data.display_name}</div>
              </div>
            </div>
            <div className='col-xxl-3 col-md-4 col-3 d-flex justify-content-end'>
              <div className='w-80px h-50px' >
                <img src={data.logo_ref}
                 alt={data.display_name}
                  style={{ width: '100%',height:'100%', objectFit:'contain' }} />
              </div>
            </div>
          </div>

          

          {/* Connect button */}
          
        </div>
        <div className='d-flex justify-content-end my-4'>
            <button  className="btn btn-primary btn-sm me-4" onClick={onClickConnect}>
              Connect
            </button>
          </div>
      </div>
    </div>
  );
};

export default PlatformCard;
// PlatformCard.tsx

// import React, { FC } from 'react';
// import { Platform } from 'src/interfaces/Platform.interface';

// import './PlatformCard.css'; // Import the styles for PlatformCard

// type PlatformCardPropType = {
//   data: Platform;
//   onClickConnect: () => void;
// };

// const PlatformCard: FC<PlatformCardPropType> = ({ data, onClickConnect }) => {
//   return (
//     <div className='platform-card col-sm-6 col-lg-4 col-xl-3 m-0'>
//       <div className='card position-relative card-xl-stretch mb-5 mb-xl-8 bg-light-info bg-hover-secondary cursor-pointer'>
//         <div className='card-body align-items-center p-7 pb-2'>
//           <div className='platform-info d-flex row'>
//             <div className='col-xxl-8 col-lg-7 col-6'>
//               <div className='info-container d-flex flex-column flex-grow-1 py-2 py-lg-9 me-2 w-100'>
//                 <div className='platform-name fw-bolder fs-4 mb-2'>{data.display_name}</div>
//               </div>
//             </div>
//             <div className='col-xxl-4 col-lg-5 col-6 d-flex align-self-center'>
//               <div className='platform-logo'>
//                 <img src={data.logo_ref} alt={data.display_name} className='img-fluid' />
//               </div>
//             </div>
//           </div>

//           <div className='connect-button d-flex justify-content-end my-4'>
//             <button className='btn btn-primary' onClick={onClickConnect}>
//               Connect
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PlatformCard;
