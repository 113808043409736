import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import { ORDER_ITEM } from 'src/app/_interfaces/order.interface'
import { getOrdersListAsync, orderResyncAsync } from '../actions/orderActions'

type InitialState = {
    orderListLoading:boolean
    orderList:ORDER_ITEM[]|[]
    orderPaginationData:{
        // next:string|null
        // previous:string|null
        pageNumber:number
        count:number
    }
    orderResyncLoading:boolean
    orderResyncData:any
}
const initialState:InitialState  = {
  orderListLoading:false,
  orderList:[],
  orderPaginationData:{
    count:0,
    pageNumber:0
  },
  orderResyncLoading:false,
  orderResyncData:null


  
}

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  extraReducers: (builder) => {
    // login start
    builder.addMatcher(isAnyOf(getOrdersListAsync.pending), (state) => {
      console.log('getOrdersListAsync is in pending state ')
      state.orderListLoading = true
    })
    builder.addMatcher(isAnyOf(getOrdersListAsync.rejected), (state) => {
      console.log('getOrdersListAsync is in rejected state ')
      state.orderListLoading = false
    })
    builder.addMatcher(isAnyOf(getOrdersListAsync.fulfilled), (state, action) => {
        console.log('getOrdersListAsync is in fulfilled state ', action.payload )
        state.orderListLoading = false
        state.orderList = action.payload?.results
        state.orderPaginationData.count = action.payload?.count

          // Update the current page number in the state
        // state.orderPaginationData.pageNumber = action.payload.currentPageNumber+1; // Adjust this line based on your actual response data structure
   

    })
      //order resync slice start

    builder.addMatcher(isAnyOf(orderResyncAsync.pending), (state) => {
      console.log('orderResyncAsync is in pending state ')
      state.orderResyncLoading = true
    })
    builder.addMatcher(isAnyOf(orderResyncAsync.rejected), (state) => {
      console.log('orderResyncAsync is in rejected state ')
      state.orderResyncLoading = false
    })
    builder.addMatcher(isAnyOf(orderResyncAsync.fulfilled), (state, action) => {
        console.log('orderResyncAsync is in fulfilled state ', action.payload )
        state.orderResyncLoading=false
        state.orderResyncData=action?.payload?.message

    })
            //order resync slice ends

     
  },
  reducers: { 
    
  },
})

export const {} = orderSlice.actions

export default orderSlice.reducer
