import { FormikState } from 'formik';
import React, { FC, useEffect, useState } from 'react'
import { PLATFORM_FIELD, PlatformDataType } from 'src/interfaces/Platform.interface'
import { RECIPE_FORM_DATA } from 'src/interfaces/Template.interface';
import { getPlatformFieldOtherTemplatesAsync } from 'src/redux/actions/templateAndPricingActions';
import { useAppDispatch } from 'src/redux/storeHooks';


type AdditionalTemplateMappingItemProps = {
  item:PLATFORM_FIELD
  selectedSecondaryPlatform: PlatformDataType | null
  otherFieldIndex:number
  values:RECIPE_FORM_DATA
  handleChange:(e: string | React.ChangeEvent<any>) => void;
  setFormikState: (f: FormikState<RECIPE_FORM_DATA> | ((prevState: FormikState<RECIPE_FORM_DATA>) => FormikState<RECIPE_FORM_DATA>), cb?: (() => void) | undefined) => void
}

const AdditionalTemplateMappingItem:FC<AdditionalTemplateMappingItemProps> = ({
item,
selectedSecondaryPlatform,
otherFieldIndex,
values,
handleChange,
setFormikState

}) => {

  const [otherTemplateFieldDataOptions, setOtherTemplateFieldDataOptions] = useState([])
  const [otherTemplateFieldDataOptionsLoader, setOtherTemplateFieldDataOptionsLoader] = useState(false)
  const [isFieldItemsFetched, setIsFieldItemsFetched] = useState(false)
  const dispatch = useAppDispatch()

  const getOtherFieldTemplateDataOptions = (fieldId) => {
    if(!isFieldItemsFetched){
      setOtherTemplateFieldDataOptionsLoader(true)
      dispatch(getPlatformFieldOtherTemplatesAsync({platform_id:selectedSecondaryPlatform?.id!+'', field_id: fieldId}))
      .then((response)=>{
        console.log('options for the template field-----', response.payload);
        if(response.payload?.results){
          setOtherTemplateFieldDataOptions(response.payload.results)
        }
        setIsFieldItemsFetched(true)
        setOtherTemplateFieldDataOptionsLoader(false)
      })
    }
  }

  console.log( otherFieldIndex, 'values.other_templates', values.other_templates)

  useEffect(() => {
    if(item.id){
      getOtherFieldTemplateDataOptions(item.id)
    }
  }, [])
  

  return(
    <div className='row align-items-center mt-3'>
      <div className="col-12 col-sm-4 ">
        <p className='form-label w-300' >
        {item.display_name}
        </p>
      </div>
      <div className="col-12 col-sm-8">
        <select
          id='dropdown'
          disabled={otherTemplateFieldDataOptionsLoader}
          className='form-select'
          name={`other_templates${otherFieldIndex}[template_id]`}
          // value={values.other_templates[otherFieldIndex]?.template_id}
          value={values.other_templates.find((it)=>(it?.fieldId==item.id))?.template_id}
          onChange={(e)=>{
            let shadowOtherTemplate = [...values.other_templates]
            shadowOtherTemplate[otherFieldIndex] ={ id: values.other_templates[otherFieldIndex]?.id ? values.other_templates[otherFieldIndex]?.id : null,  template_id: Number(e.target.value), fieldId:item.id }
            setFormikState(pre=>{
              return {...pre, values:{...pre.values, other_templates:shadowOtherTemplate }}
            })
          }}
          onClick={()=>getOtherFieldTemplateDataOptions(item.id)}
        >
          <option value=''>{otherTemplateFieldDataOptionsLoader ? 'Loading...' : '-- Please Select --'}</option>
          {otherTemplateFieldDataOptions.length && otherTemplateFieldDataOptions.map((item:any, index) => {
            return (
              <option key={'otherfieldTemplate' + index} value={item?.id}>
                {item?.name}
              </option>
            )
          })}
        </select>
      </div>

  </div>
  )
}

export default AdditionalTemplateMappingItem