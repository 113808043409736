import React, {FC, useId} from 'react'
import {FIELD_MAPPING_DATA, SECTION_WISE_SAVED_FIELD_MAPPING, SETTINGS_OF_PLATFORM} from 'src/interfaces/Platform.interface'
import CustomInput from './CustomInput'
import {PlatformSettingDataTypesValues} from 'src/app/_interfaces/setting.interface'
import ShortCodeInput from './ShortCodeInput'
import MappingInput from './MappingInput'
import { useParams } from 'react-router-dom'
import InfoTooltip from './InfoTooltip'
import moment from 'moment'
import { useAppSelector } from 'src/redux/storeHooks'

const DynamicChildSetting: FC<{
  childItem: SETTINGS_OF_PLATFORM
  childItemIndex: number
  settingField: string
  values: PlatformSettingDataTypesValues
  handleChange: (e: React.ChangeEvent<any>) => void
  savedMappings : SECTION_WISE_SAVED_FIELD_MAPPING[]|[]
  settingName:string
  settingId:number|null
  setFieldMappings: React.Dispatch<React.SetStateAction<FIELD_MAPPING_DATA[]>>
  fieldMappings: FIELD_MAPPING_DATA[]
}> = ({childItem, childItemIndex, settingField, values, handleChange, savedMappings, settingId, settingName, setFieldMappings, fieldMappings }) => {
  const id = useId()
  const { selectedPlatformId } = useParams()
  const { mainStoreData } = useAppSelector((state) => state.fetchConnectedPlatform)

  function formatDateString(dateString:string) {
    // Parse the date string to a Date object
    let date;
    if(dateString){
      if (dateString.endsWith("Z")) {
          let zremovedval = dateString.slice(0, dateString.length-4)
          console.log('zremovedval', zremovedval)
          // If the date string ends with 'Z', it's in UTC
          date = new Date(zremovedval);
      } else {
          // Otherwise, it's in the local time zone
          date = new Date(dateString);
      }
      // Adjust the date to the local time zone if needed
      const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
  
      // Format the local date for the datetime-local input
      return localDate.toISOString().slice(0, 16);
    }else{
      return null
    }

  }


  if (childItem.input_type === 'BOOLEAN') {
    return (
      <div className='col-12 col-sm-6 mt-4'>
        <CustomInput
          label={childItem.display_name}
          labelClass='form-label'
          message={childItem.description}
          containerClasses=''
          inputWrapperClasses='form-check form-switch form-check-solid'
          inputProps={{
            type: 'checkbox',
            className: 'form-check-input h-30px w-50px',
            name: settingField ? `${settingField}_setting.${childItem.name}` : childItem.name,
            placeholder : childItem?.placeholder ?? '' ,
            checked: settingField
              ? values[`${settingField}_setting`][`${childItem.name}`]
              : values[childItem.name],
            onChange: handleChange,
            id: childItem.name + childItemIndex,
          }}
        />
        <div className='row mt-4'>
          {values[`${settingField}_setting`][`${childItem.name}`]
            ? childItem.children.length
              ? childItem.children.map(
                  (childSetting2: SETTINGS_OF_PLATFORM, childIndex2: number) => {
                    return (
                      <DynamicChildSetting
                        key={id + childIndex2 + childItemIndex}
                        childItem={childSetting2}
                        childItemIndex={childIndex2}
                        settingField={settingField}
                        values={values}
                        handleChange={handleChange}
                        settingName={settingName}
                        settingId={settingId}
                        savedMappings={savedMappings}
                        setFieldMappings={setFieldMappings}
                        fieldMappings={fieldMappings}
                      />
                    )
                  }
                )
              : null
            : null}
            { values[`${settingField}_setting`] && values[`${settingField}_setting`][`${childItem.name}`] ? (
            childItem.field_mappings?.length ? 
            childItem.field_mappings?.map((field_mapping)=>{
              return (
                <div className='row p-4'>
                  <MappingInput
                    selectedPlatformId={Number(selectedPlatformId)}
                    isMappingVisible={
                      values[`${settingField}_setting`][`${childItem.name}`]
                        ? values[`${settingField}_setting`][`${childItem.name}`]
                        : false
                    }
                    handleChange={handleChange}
                    field_mapping={field_mapping}
                    sourceSide={mainStoreData?.id!}
                    destinationSide={Number(selectedPlatformId)}
                    savedMapping={savedMappings?.length ? savedMappings?.find((it)=>(it?.destination_field?.api_code===field_mapping.field_code)) : null}
                    settingName={settingName}
                    settingId={settingId}
                    setFieldMappings={setFieldMappings}
                    fieldMappings={fieldMappings}
                  />
                </div>
              )
            }) : null
          ) : null}
        </div>
      </div>
    )
  } else if (childItem.input_type === 'INTEGER') {
    return (
      <CustomInput
        label={childItem.display_name}
        message={childItem.description}
        inputProps={{
          type: 'number',
          className: 'form-control form-control-lg',
          name: settingField ? `${settingField}_setting.${childItem.name}` : childItem.name,
          placeholder : childItem?.placeholder ?? '' ,
          value: settingField
            ? values[`${settingField}_setting`][`${childItem.name}`]
            : values[childItem.name],
          onChange: handleChange,
          id: childItem.name + childItemIndex,
        }}
      />
    )
  } else if (childItem.input_type === 'DATETIME') {


  const formattedLocalDateString = formatDateString(values[`${settingField}_setting`][`${childItem.name}`])

    return (
      <CustomInput
        label={childItem.display_name}
        message={childItem.description}
        inputProps={{
          type: 'datetime-local',
          className: 'form-control form-control-lg',
          name: settingField ? `${settingField}_setting.${childItem.name}` : childItem.name,
          placeholder : childItem?.placeholder ?? '' ,
          value:formattedLocalDateString?formattedLocalDateString:'',
          // value: settingField
          //   ? `${new Date(values[`${settingField}_setting`][`${childItem.name}`])
          //       .toISOString()
          //       .slice(0, 16)}`
          //   : '',
          onChange: handleChange,
          id: childItem.name + childItemIndex,
        }}
      />
    )
  } else if (childItem.input_type === 'SELECT') {
    //if input type is select

    <div className='col-12 col-sm-6'>
    <label className='form-label' htmlFor={childItem.name}>
      {childItem.display_name}
      &nbsp;&nbsp;<InfoTooltip message={childItem.description} />
    </label>
    <select
      name={childItem.name}
      value={values[`${childItem.name}`]}
      onChange={handleChange}
      className='form-select form-select-lg'
    >
      <option value=''>{childItem.placeholder}</option>
      {childItem.setting_options.map((item: any) => {
        return (
          <option key={item.id} value={item.name}>
            {item.display_name}
          </option>
        )
      })}
    </select>
  </div>
  } else {
    //default is text field
    if (childItem.showShortcodes) {
      return (
        <>
          <ShortCodeInput
            label={childItem.display_name}
            message={childItem.description}
            inputProps={{
              type: 'text',
              className: 'form-control form-control-lg',
              name: settingField ? `${settingField}_setting.${childItem.name}` : childItem.name,
              placeholder : childItem?.placeholder ?? '' ,
              value: settingField
                ? values[`${settingField}_setting`][`${childItem.name}`]
                : values[childItem.name],
              onChange: handleChange,
              id: childItem.name + childItemIndex,
            }}
          />
        </>
      )
    } else {
      return (
        <CustomInput
          label={childItem.display_name}
          message={childItem.description}
          inputProps={{
            type: 'text',
            className: 'form-control form-control-lg',
            name: settingField ? `${settingField}_setting.${childItem.name}` : childItem.name,
            placeholder : childItem?.placeholder ?? '' ,
            value: settingField
              ? values[`${settingField}_setting`][`${childItem.name}`]
              : values[childItem.name],
            onChange: handleChange,
            id: childItem.name + childItemIndex,
          }}
        />
      )
    }
  }

  return null
}

export default DynamicChildSetting
