import { createSlice } from "@reduxjs/toolkit";

type MESSAGE_PAYLOAD = { message: string, subtitle: string, isVisible:boolean,  messageType: "info" | "warning" | "danger" | "success",  } 

const errorSlice = createSlice({
    name:"errorSlice",
    initialState:{
        message:"",
        subtitle:"",
        isVisible:false,
        messageType:"error" 
    },
    extraReducers: builder =>{},
    reducers:{
        setAlertMessage : (state,   action : { payload : MESSAGE_PAYLOAD } ) => {
            state.message = action.payload.message
            state.subtitle = action.payload.subtitle
            state.isVisible = action.payload.isVisible
            state.messageType = action.payload.messageType
            
        },

        hideDialog : (state) => {
            state.message = ""
            state.isVisible = false;
            state.messageType = "info"
        }
    }
})

export const { setAlertMessage, hideDialog } = errorSlice.actions
export default errorSlice.reducer