import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {
  deleteFieldMappingAsync,
  getAllProductsFromChannelAsync,
  getFieldMappingAsync,
  getPlatformFieldsAsync,
  getPlatformFieldsForMainStoreAsync,
  getPlatformProductAdjustmentAsync,
  getPlatformProductAdjustmentSecondaryAsync,
  getPlatformProductDetailAsync,
  getPlatformProductVarientDetailAsync,
  productVarientResyncAsync,
  saveFieldMappingAsync,
} from '../actions/productActions'
import {INITIAL_STATE_OF_PRODUCT_SLICE} from 'src/interfaces/Platform.interface'

const initialState: INITIAL_STATE_OF_PRODUCT_SLICE = {
  loadingProductsFromChannel: false,
  productsOfSingleChannel: [],
  productsCountOfSingleChannel: 0,
  platformAdjustmentLoader: false,
  platformAdjustmentCount: 0,
  platformAdjustments: [],

  platformProductDetailLoader: false,
  platformProductDetail: null,
  fieldValuesLoader: false,
  shipmentFieldMappings:[],
  shipmentData: {
    mainStoreShipmentData: null,
    selectedStoreShipmentData: null,
  },
  reset:false,
  productTablePaginationData:{
   
      page: 1,
      limit: 10,
      search_string: '',
      sort_by: '', 
      sort_order:'',
      sync_status:'',

  },
  varientResyncLoading:false,
  varientResyncData:null,

  theRangeId:null
}

const productSlice = createSlice({
  name: 'productSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAllProductsFromChannelAsync.pending), (state, action) => {
      state.loadingProductsFromChannel = true
    })
    builder.addMatcher(isAnyOf(getAllProductsFromChannelAsync.rejected), (state) => {
      state.loadingProductsFromChannel = false
    })
    builder.addMatcher(isAnyOf(getAllProductsFromChannelAsync.fulfilled), (state, action) => {
      console.log('getAllProductsFromChannelAsync is in fulfilled state ', action.payload)
      state.productsOfSingleChannel = action?.payload?.results
      state.productsCountOfSingleChannel = action?.payload?.count
      state.loadingProductsFromChannel = false
    })

    // platform product detail start
    builder.addMatcher(isAnyOf(getPlatformProductDetailAsync.pending), (state, action) => {
      state.platformProductDetailLoader = true
    })
    builder.addMatcher(isAnyOf(getPlatformProductDetailAsync.rejected), (state) => {
      state.platformProductDetailLoader = false
    })

    builder.addMatcher(isAnyOf(getPlatformProductDetailAsync.fulfilled), (state, action) => {
      console.log('platform detail of product ----- ', action.payload)
      state.platformProductDetail = action.payload
      state.platformProductDetailLoader = false
    })
    // platform product detail end


    //platform product detail varint start

    builder.addMatcher(isAnyOf(getPlatformProductVarientDetailAsync.pending), (state, action) => {
      state.platformProductDetailLoader = true
    })
    builder.addMatcher(isAnyOf(getPlatformProductVarientDetailAsync.rejected), (state) => {
      state.platformProductDetailLoader = false
    })

    builder.addMatcher(isAnyOf(getPlatformProductVarientDetailAsync.fulfilled), (state, action) => {
      console.log('platform  linked product variant detail  ----- ', action.payload)
      state.platformProductDetail = action.payload
      state.platformProductDetailLoader = false
    })
    //platform product detail varint end

    // get  platform adjustment start
    builder.addMatcher(isAnyOf(getPlatformProductAdjustmentAsync.pending,getPlatformProductAdjustmentSecondaryAsync.pending), (state, action) => {
      state.platformAdjustmentLoader = true
    })
    builder.addMatcher(isAnyOf(getPlatformProductAdjustmentAsync.pending,getPlatformProductAdjustmentSecondaryAsync.pending), (state) => {
      state.platformAdjustmentLoader = false
    })

    builder.addMatcher(isAnyOf(getPlatformProductAdjustmentAsync.fulfilled), (state, action) => {
      console.log('platform detail of product ----- ', action.payload)
      state.platformAdjustments = action.payload?.results
      state.platformAdjustmentCount = action.payload?.count
      state.platformAdjustmentLoader = false
    })


    // secondary 
    builder.addMatcher(isAnyOf(getPlatformProductAdjustmentSecondaryAsync.fulfilled), (state, action) => {
      console.log('platform detail of product ----- ', action.payload?.results)
      if(action.payload?.results.length){
        state.platformAdjustments = action.payload?.results
      }

      state.platformAdjustmentCount = action.payload?.count
      state.platformAdjustmentLoader = false
    })


    // get  platform adjustment end

    // get  platform fields start
    builder.addMatcher(
      isAnyOf(
        getPlatformFieldsAsync.pending,
        getPlatformFieldsForMainStoreAsync.pending,
        saveFieldMappingAsync.pending,
        getFieldMappingAsync.pending,
        deleteFieldMappingAsync.pending
      ),
      (state, action) => {
        state.fieldValuesLoader = true
      }
    )
    builder.addMatcher(
      isAnyOf(
        getPlatformFieldsAsync.rejected,
        getPlatformFieldsForMainStoreAsync.rejected,
        saveFieldMappingAsync.rejected,
        getFieldMappingAsync.rejected,
        deleteFieldMappingAsync.rejected
      ),
      (state) => {
        state.fieldValuesLoader = false
      }
    )

    builder.addMatcher(isAnyOf(getPlatformFieldsAsync.fulfilled), (state, action) => {
      console.log('getPlatformFieldsAsync fulfilled state', action.payload)
      if(action?.payload?.length){
        //taking first value or array
        state.shipmentData.selectedStoreShipmentData = action.payload[0]

      }
   
      state.fieldValuesLoader = false
    })

    builder.addMatcher(isAnyOf(getPlatformFieldsForMainStoreAsync.fulfilled), (state, action) => {
      console.log('getPlatformFieldsForMainStoreAsync fulfilled state', action.payload)
      if(action?.payload?.length){
        //taking first value or array
        state.shipmentData.mainStoreShipmentData = action.payload[0]

      }
   
      state.fieldValuesLoader = false
    })

    builder.addMatcher(isAnyOf(saveFieldMappingAsync.fulfilled), (state, action) => {
      console.log('saveFieldMappingAsync fulfilled state', action.payload)
      state.fieldValuesLoader = false
    })

    builder.addMatcher(isAnyOf(getFieldMappingAsync.fulfilled), (state, action) => {
      console.log('getFieldMappingAsync fulfilled state', action.payload)
      if(action.payload?.length){
        state.shipmentFieldMappings = action.payload
      }
      state.fieldValuesLoader = false
    })

    builder.addMatcher(isAnyOf(deleteFieldMappingAsync.fulfilled), (state, action) => {
      console.log('deleteFieldMappingAsync fulfilled state', action.payload)
      state.fieldValuesLoader = false
    })
    // get  platform fields end


   //variens resync slice start


   builder.addMatcher(isAnyOf(productVarientResyncAsync.pending), (state) => {
    console.log('orderResyncAsync is in pending state ')
    state.varientResyncLoading = true
  })
  builder.addMatcher(isAnyOf(productVarientResyncAsync.rejected), (state) => {
    console.log('orderResyncAsync is in rejected state ')
    state.varientResyncLoading = false
  })
  builder.addMatcher(isAnyOf(productVarientResyncAsync.fulfilled), (state, action) => {
      console.log('orderResyncAsync is in fulfilled state ', action.payload )
      state.varientResyncLoading=false
      state.varientResyncData=action?.payload?.message

  })


   //  //variens resync slice ends
   
   
  },
  reducers: {
    setProductTablePaginationData: (state,action:{payload:any ,type:string})=>{
      state.productTablePaginationData ={...state.productTablePaginationData, ...action.payload }
    },
    setReset: (state) => {
      state.reset = !state.reset; // Toggle the reset field
    },
   
    setTheRangeId : (state, action)=> {
      state.theRangeId = action.payload
    }

  },
})

export const {setProductTablePaginationData,setReset, setTheRangeId} = productSlice.actions
export default productSlice.reducer
