import React, {FC, useState} from 'react'
import AppCustomEditor from 'src/utils/CustomEditor'

const FeatureRequest: FC = () => {
  
    const [description, setDescription] = useState('')

  const handleSendEmail = (e) => {
    e.preventDefault()
    // Handle email sending logic here
    console.log(`Email: ${description}`)
  }

  return (
    <div>
      <div className='row'>
        <h3 style={{
            // backgroundImage:'url("/media/auth/bg1.jpg")',
            // WebkitBackgroundClip:'text',
            // WebkitTextFillColor:'transparent',
            // backgroundPosition:'60% 50%',
            // fontSize:'50px'
        }} >Request a Feature</h3>
      </div>
      <div className='card' >
        <div className="card-body shadow p-8">
            <div className=''>
                <form onSubmit={handleSendEmail}>
                <div className='mb-10 customEditorWrapper ' style={{minHeight:'55vh'}} >
                    <AppCustomEditor
                      value={description}
                      placeholder='Describe about feature'
                      onChangeData={(val)=>{
                        setDescription(val)
                      }}
                    />
                </div>
                <div className='d-flex justify-content-end' >
                    <button type='submit' className='btn btn-primary fw-bold'>
                        Submit Request
                    </button>
                </div>
                </form>
            </div>
        </div>
      </div>
    </div>
  )
}

export default FeatureRequest
