// platformSlice.js

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchPlatformsAsync } from "../actions/platformActions";
import { Platform } from "src/interfaces/Platform.interface";

type platformPayload={
    platformData:Platform[]| []
    platformLoading:boolean

}


const initialState :platformPayload= {
  platformLoading:false,
  platformData : []
};

const platformSlice = createSlice({
  name: "platformSlice",
  initialState,
   extraReducers: (builder) => {
   
    builder.addMatcher(isAnyOf(fetchPlatformsAsync.pending), (state,action)=>{
        state.platformLoading=true; 
      })
      builder.addMatcher(isAnyOf(fetchPlatformsAsync.fulfilled), (state,action)=>{
      
        state.platformLoading=false; 
        if(action?.payload?.data?.length){
          state.platformData = action?.payload?.data;
  
        }
       
      })
      builder.addMatcher(isAnyOf(fetchPlatformsAsync.rejected), (state)=>{
        state.platformLoading=false; 
      })
      
  },
  reducers: {
    setPlatformData: (state, action: {payload: platformPayload; type: string}) => {
      state.platformLoading = action.payload?.platformLoading
      state.platformData = action.payload.platformData
    },

      
  }  
});


export const {setPlatformData} = platformSlice.actions
export default platformSlice.reducer;
