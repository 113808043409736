import { createAsyncThunk } from "@reduxjs/toolkit";
import AxiosClient from "../AxiosClient";

export const requestSubscriptionAsync = createAsyncThunk(
    'auth/requestSubscriptionAsync',
    async (payload:any, toolkit) => {
      return await AxiosClient('POST', '/api/request-subscription', payload, toolkit, [] );
    }
  )

  export const cancelSubscriptionAsync = createAsyncThunk(
    'auth/cancelSubscriptionAsynccancelSubscriptionAsync',      
    async (payload:any, toolkit) => {
      return await AxiosClient('POST', '/api/cancel-subscription', payload, toolkit, [] );
    }
  )

  export const updateSubscriptionAsync = createAsyncThunk(
    'auth/updateSubscriptionAsync',
    async (payload:any, toolkit) => {
      return await AxiosClient('POST', '/api/change-subscription', payload, toolkit, [] );
    }
  )
//get all plans 
  export const getAllPlansAsync = createAsyncThunk(
    'auth/getAllPlansAsync',
    async ( payload,toolkit) => {
      return await AxiosClient('GET', '/api/billing-plan', [], toolkit, [] );
    }
  )

  export const getUserPlanAsync = createAsyncThunk(
    'auth/getUserPlanAsync',
    async ( payload,toolkit) => {
      return await AxiosClient('GET', '/api/activate-billing-plan/', [], toolkit, [] );
    }
  )


  //approval
  export const getApproveSubscriptionAsync = createAsyncThunk(
    'auth/getApproveSubscriptionAsync',
    async ( params:any,toolkit) => {
      return await AxiosClient('GET', `/api/approve-subscription`, [], toolkit, params );
    }
    
  )


