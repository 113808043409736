/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {setLoggingData} from 'src/redux/slices/authSlice'
import {accessSessionTokenKeyName, loggedInAdminAccessToken, loggedInAdminRefreshToken, refreshSessionTokenKeyName} from 'src/utils/constants'
import {fetchUserAccountDetailsAsync} from 'src/redux/actions/accountActions'
import {USER_DATA} from 'src/app/_interfaces/auth.interface'

const HeaderUserMenu: FC = () => {
  const {currentUser, logout} = useAuth()
  const [userData, setUserData] = useState<USER_DATA>()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {fetchedUserAccountData} = useAppSelector((state) => state.userAccountData)
  console.log('user detail : ', fetchedUserAccountData)

  // useEffect(() => {
  //   // Dispatch the fetch user data action on component mount
  //   dispatch(fetchUserAccountDetailsAsync())
  //     .then((response) => {
  //       console.log('User account data:', response.payload)
  //       setUserData(response.payload) // Log the response data
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching user data:', error)
  //     })
  // }, [localStorage.getItem(accessSessionTokenKeyName)])

  return (
    <div
      className='menu menu-sub  menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px '
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center'>
          <div className='symbol symbol-50px  '>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/man_5-1024.webp')} />
          </div>
          <div className='d-flex flex-column p-2'>
            <div className='fw-bolder d-flex align-items-center fs-5'>{fetchedUserAccountData?.name!}</div>
            <div
              className=' align-items-start grey'
              style={{
                color: 'grey',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '180px',
              }}
            >
              {fetchedUserAccountData?.email!}
            </div>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>
      {/* 
      <div className='menu-item px-5'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          My Profile
        </Link>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          <span className='menu-text'>My Projects</span>
          <span className='menu-badge'>
            <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>
          </span>
        </a>
      </div>

      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title'>My Subscription</span>
          <span className='menu-arrow'></span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4'>
          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Referrals
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Billing
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link px-5'>
              Payments
            </a>
          </div>

          <div className='menu-item px-3'>
            <a href='#' className='menu-link d-flex flex-stack px-5'>
              Statements
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='View your statements'
              ></i>
            </a>
          </div>

          <div className='separator my-2'></div>

          <div className='menu-item px-3'>
            <div className='menu-content px-3'>
              <label className='form-check form-switch form-check-custom form-check-solid'>
                <input
                  className='form-check-input w-30px h-20px'
                  type='checkbox'
                  value='1'
                  defaultChecked={true}
                  name='notifications'
                />
                <span className='form-check-label text-muted fs-7'>Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className='menu-item px-5'>
        <a href='#' className='menu-link px-5'>
          My Statements
        </a>
      </div>

      <div className='separator my-2'></div>

      <Languages /> */}

      <div className='menu-item px-4 my-1 '>
        <Link
          to='/account/settings'
          className='menu-link '
          style={{textDecoration: 'none'}}
        >
          Account Settings
        </Link>
      </div>

      <div className='menu-item px-4'>
        <a
          onClick={() => {
            localStorage.removeItem(accessSessionTokenKeyName)
            localStorage.removeItem(refreshSessionTokenKeyName)
            localStorage.clear()
            dispatch(setLoggingData({isLoggedIn: false, userData: null}))
            document.location.reload();
          }}
          className='menu-link '
          style={{textDecoration: 'none'}}
        >
          Sign Out
        </a>
      </div>
      { localStorage.getItem(loggedInAdminAccessToken) && 
        <div className='menu-item px-4'>
          <a
            onClick={() => {
              let currentaccesstokenofadmin =  localStorage.getItem(loggedInAdminAccessToken)
              let currentrefreshtokenofadmin = localStorage.getItem(loggedInAdminRefreshToken)
              if(currentaccesstokenofadmin&&currentrefreshtokenofadmin){
                localStorage.setItem(accessSessionTokenKeyName,currentaccesstokenofadmin)
                localStorage.setItem(refreshSessionTokenKeyName, currentrefreshtokenofadmin)
                localStorage.removeItem(loggedInAdminAccessToken)
                localStorage.removeItem(loggedInAdminRefreshToken)
                navigate('/')
              }

              document.location.reload();
            }}
            className='menu-link text-primary'
            style={{textDecoration: 'none'}}
          >
            Back to Admin
          </a>
        </div>
      }
    </div>
  )
}

export {HeaderUserMenu}
