/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useThemeMode} from 'src/marketConnect/partials'
import {getCSS, getCSSVariableValue} from 'src/marketConnect/assets/ts/_utils'
import AppDatePickerRange from '../orders/components/AppDatePickerRange'
import moment from 'moment'
import { useAppSelector } from 'src/redux/storeHooks'

type Props = {
  className: string
}

const OrderReportChart: React.FC<Props> = ({className}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [isDatePickerVisible, setIsDatePickerVisible] = useState<boolean>(false)
  const {fetchedConnectedPlatformData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const [dtRange, setDtRange] = useState<{startDt:string, endDt:string}>({startDt:'',endDt:''})
  const {mode} = useThemeMode()
  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header bg-white border-bottom border-0 '>
        <h3 className='card-title align-items-start flex-column '>
          <span className='card-label fw-bold fs-4 mb-1'>Order Report</span>

          {/* <span className='text-muted fw-semibold fs-7'>More than 1000 new records</span> */}
        </h3>

        {/* begin::Toolbar */}
        <div className='card-toolbar' data-kt-buttons='true'>
          <div className='channel'>
            <select name='' id='' className='form-select'>
              <option value=''>All Channels</option>
              {
                fetchedConnectedPlatformData?.map((channel)=>{
                  if(!channel?.isMainStore){
                    return(
                      <option value={channel?.id}>{channel?.display_name}</option>
                    )
                  }
                })
              }
            </select>
          </div>

          <div className='ms-2'>
            <button
              className='btn fs-6 btn-sm bg-white  border text-dark h-30px align-items-center d-flex justify-content-center '
              onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}
            >
              {dtRange.startDt && dtRange.endDt
                ? `${moment(dtRange.startDt).format('YYYY/MM/DD')}  -  ${moment(
                    dtRange.endDt
                  ).format('YYYY/MM/DD')}`
                : 'Pick a Date Range'}
            </button>
            <AppDatePickerRange
              isVisible={isDatePickerVisible}
              handleClose={() => setIsDatePickerVisible(false)}
              onPickRange={({startDate, endDate}) => {
                // Update the selected start and end dates
              
                setDtRange({
                  startDt:moment(startDate).format('YYYY-MM-DD'),
                  endDt:moment(endDate).format('YYYY-MM-DD')
                })
                
              }}
            />
          </div>

          <button
            className='btn btn-sm btn-warning fw-bold ms-2 h-30px px-4'
          >
            Reset
          </button>
        </div>
        {/* end::Toolbar */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export default OrderReportChart

function getChartOptions(height: number): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  return {
    series: [
      {
        name: 'Net Profit',
        data: [30, 40, 40, 90, 90, 70, 70],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'line',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
     show:false
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'straight',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug'],
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      axisBorder:{
        show:true
      }
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    // tooltip: {
    //   style: {
    //     fontSize: '12px',
    //   },
    //   y: {
    //     formatter: function (val) {
    //       return '$' + val + ' thousands'
    //     },
    //   },
    // },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
