import { FieldArrayRenderProps, FormikState } from 'formik'
import React, {FC, useState} from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { InitialValuesForCreateProduct, OPTION_ADD_ITEM } from 'src/interfaces/createProductInterface'
import {KTSVG} from 'src/marketConnect/helpers'

type AddVariantOptionItemProps = {
  arrayHelper:FieldArrayRenderProps
  values: InitialValuesForCreateProduct
  option:OPTION_ADD_ITEM
  index:number
  setFormikState: (
    f:
      | FormikState<InitialValuesForCreateProduct>
      | ((prevState: FormikState<InitialValuesForCreateProduct>) => FormikState<InitialValuesForCreateProduct>),
    cb?: (() => void) | undefined
  ) => void
}

const AddVariantOptionItem:FC<AddVariantOptionItemProps> = ({arrayHelper, values, option, index, setFormikState}) => {
  const [isSaved, setIsSaved] = useState(false)
  const [alreadyChosenItem, setAlreadyChosenItem] = useState({isChosen:false, msg:'' })
  const [isValueBlank, setIsValueBlank] = useState({isError:false, msg:""})
  const variantOptions = [
    {value: 'color', label: 'Color'},
    {value: 'size', label: 'Size'},
    {value: 'material', label: 'Material'},
    {value: 'style', label: 'Style'},
  ]

  return (
    <div>
      <div className='form-group align-items-center gap-5'>
        {!isSaved ? (
          <div>
            <div className='row mt-2'>
              <div className='col-5'>
                <Select
                  placeholder='Option Name'
                  key={'saveChange' + isSaved}
                  value={
                    option.name
                      ? variantOptions.filter((it) => it.value === option.name)
                      : undefined
                  }
                  options={variantOptions}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      let shodowvariant = [...values.options]
                      let currentVariant = {
                        name: selectedOption?.value,
                        option_values: option.option_values,
                      }
                      let isAlreadySelected = shodowvariant.find((item)=>(item.name===selectedOption.value))
                      if(!isAlreadySelected){
                        shodowvariant[index] = currentVariant
                        setFormikState((pre) => {
                          return {...pre, values: {...pre.values, options: shodowvariant}}
                        })
                        setAlreadyChosenItem({ isChosen:false, msg:'' })
                      }else{
                        setAlreadyChosenItem({ isChosen:true, msg: `You have already used this option name "${selectedOption.label}"` })
                      }
                      console.log('isAlreadySelected', isAlreadySelected)
                    }
                  }}
                  // className='w-200px me-4'
                />
                { alreadyChosenItem.isChosen && <p className="text-danger fw-semibold"><i className="bi bi-info-circle text-danger"></i> {alreadyChosenItem.msg} </p>}
              </div>
              <div className='col-5'>
                <CreatableSelect
                  key={'saveChange' + isSaved}
                  isDisabled={alreadyChosenItem.isChosen}
                  placeholder='Option Value'
                  isMulti
                  value={
                    option.option_values
                      ? option.option_values.map((it) => ({label: it.value, value: it.value}))
                      : []
                  }
                  onChange={(newValues) => {
                    if (newValues.length) {
                      
                      let selectedValues = newValues.map((it: any) => ({value: it['value']}))
                      let shodowvariant = [...values.options]
                      let currentVariant = {
                        name: option?.name,
                        option_values: selectedValues,
                      }
                      shodowvariant[index] = currentVariant
                      setFormikState((pre) => {
                        return {...pre, values: {...pre.values, options: shodowvariant}}
                      })
                      setIsValueBlank({isError:false, msg:''})
                    }
                  }}
                />
                { isValueBlank.isError && <p className="text-danger fw-semibold"><i className="bi bi-info-circle text-danger"></i> {isValueBlank.msg} </p>}

                {/* <input
                    type='text'
                    className='form-control mw-100 w-200px'
                    name={`kt_ecommerce_add_product_options[${index}][product_option_value]`}
                    placeholder='Option Value'
                  /> */}
              </div>
              <div className='col-2'>
                <button
                  type='button'
                  data-repeater-delete=''
                  className='btn btn-sm btn-icon btn-light-danger ms-4'
                  onClick={() => arrayHelper.remove(index)} // Call deletevariant function with index
                >
                  <i className='ki-duotone ki-cross fs-1'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>
                </button>
              </div>
            </div>
            <div className='row'>
              <div className='col'></div>
              <div className='col-12 align-items-end mt-1'>
                <button className='btn btn-sm btn-success' onClick={() =>{
                  if(option.option_values.length === 0 && option.name=='' ){
                    setAlreadyChosenItem({ isChosen:true, msg: `Option name is required` })
                    setIsValueBlank({isError:true, msg:'Option values are required'})
                  }
                  else if(option.name=='' ){
                    setAlreadyChosenItem({ isChosen:true, msg: `Option name is required` })
                     
                  } else if(option.option_values.length === 0){
                    setIsValueBlank({isError:true, msg:'Option values are required'})
                  }
                   else{
                    setIsValueBlank({isError:false, msg:''})
                    setIsSaved(true)
                  }
                   
                  }}>
                  Save
                </button>
                {/* <button className='btn btn-sm btn-danger ms-2' >Delete</button> */}
              </div>
            </div>
          </div>
        ) : (
          <div className='mt-2' draggable>
            <div className='card'>
              <div className='card-header px-4' style={{minHeight: '0'}}>
                <div className='card-title'>{(option.name + '').toLocaleUpperCase()}</div>
                <div className='card-toolbar'>
                  <button
                    className='btn btn-sm btn-icon btn-light-primary'
                    onClick={() => setIsSaved(false)}
                  >
                    <KTSVG
                      path='media/icons/duotune/art/art005.svg'
                      className='svg-icon-muted svg-icon-md'
                    />
                  </button>
                </div>
              </div>
              <div className='card-body'>
                {option.option_values.map((it) => {
                  return (
                    <span
                      key={it.value}
                      className='text-white p-2 px-4 rounded bg-primary ms-2 '
                      style={{minWidth: '80px'}}
                    >
                      {it.value}
                    </span>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default AddVariantOptionItem
