import React, { FC, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './appeditor2.css'

type AppCustomEditor2PropsType = {
  value: string;
  onChangeData: (params: string) => void;
  placeholder?: string;
};


const AppCustomEditor2: FC<AppCustomEditor2PropsType> = ({ value, placeholder, onChangeData }) => {
  const [editorD, setEditor] = useState<ClassicEditor | null>(null);

  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={{
          placeholder: placeholder,
          toolbar: [
            'heading',
            '|',
            'bold',
            'italic',
            'bulletedList',
            'numberedList',
            'blockQuote',
          ],
          heading:{
            options:[
              {
                model: 'paragraph',
                title: 'Paragraph',
                class: 'fs-6 ck-heading_paragraph',
              },
              {
                model: 'heading1',
                view: 'h1',
                title: 'Heading 1',
                class: 'fs-6 ck-heading_heading1',
              },
              {
                model: 'heading2',
                view: 'h2',
                title: 'Heading 2',
                class: 'fs-6 ck-heading_heading2',
              },
              {
                model: 'heading3',
                view: 'h3',
                title: 'Heading 3',
                class: 'fs-6 ck-heading_heading3',
              },
              {
                model: 'heading4',
                view: 'h4',
                title: 'Heading 4',
                class: 'fs-6 ck-heading_heading4',
              },
            ],
          },
          ui: {
            poweredBy: {
              label: 'Commurium',
              horizontalOffset: 0,
              position: 'border',
              side: 'left',
              verticalOffset: 0,
              forceVisible: true,
            },
          },
        }}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          console.log('Editor is ready to use!', editor);
          setEditor(editor);
        }}
        onChange={(event, editor) => {
          if (editorD) {
            onChangeData(editorD?.getData());
          }
          // onChangeData(event.source)
          console.log('event', event);
          console.log('oncha', editorD?.getData());
        }}
        onBlur={(event, editor) => {
          console.log('Blur.', editor);
        }}
        onFocus={(event, editor) => {
          console.log('Focus.', editor);
        }}
      />
    </div>
  );
};

export default AppCustomEditor2;
