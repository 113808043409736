import {Route, Routes, useParams, useSearchParams} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import ResetPassword from './components/ResetPassword'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'src/redux/storeHooks'
import { checkShopifyUserExistence, handleShopifyOauthCallbackAsync } from 'src/redux/actions/authActions'
import { accessSessionTokenKeyName, refreshSessionTokenKeyName } from 'src/utils/constants'
import { setLoggingData } from 'src/redux/slices/authSlice'
import ShopifyUserCredForm from './ShopifyUserCredForm'
import useAppToast from 'src/hooks/useAppToast'
import Loader from 'src/utils/Loader'

const AuthPage = () => {

  const dispatch = useAppDispatch()
  const {successToast, warningToast, errorToast} = useAppToast()
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true)
  const [openShopifyForm, setOpenShopifyForm] = useState(false)
  const [detailsToSend, setDetailsToSend] = useState<{platformId:null|string, redirect_uri:Location, shop:null|string}>({platformId:null, redirect_uri:window.location, shop:null})
  const payload = {
    name: 'SHOPIFY',
    type: 'ECOMMERCE',
    code: 'SHOPIFY',
    display_name: '',
    platform_info: '',
    credentials: {
      identifier: '',
      client_id: '',
      client_secret: '',
      token_id: 'token',
      token_secret: null,
      refresh_token: null,
      mode: 'LIVE',
    },
    isConnected: true,
    isActive: true,
    isMainStore: true,
    isOrderSyncProcessing: false,
    isNewOrderSyncProcessing: false,
    isOrderCreateProcessing: false,
  } 

  useEffect(() => {
    const hmac = searchParams.get('hmac')
    const host = searchParams.get('host')
    const shop = searchParams.get('shop')
    const code = searchParams.get('code')

    setLoading(true)
    if(hmac&&host&&shop){
      if(!code){
        dispatch(checkShopifyUserExistence({shop:shop}))
        .then((response)=>{
          console.log('response of shopify user existence', response.payload);
          if(response.payload?.status_code=='200'){
            localStorage.setItem(accessSessionTokenKeyName, response?.payload?.data?.access_token)
            localStorage.setItem(refreshSessionTokenKeyName, response?.payload?.data?.refresh)
            setLoading(false)
            dispatch(setLoggingData({
              isLoggedIn:true,
              userData:{...response.payload?.data}
            }))
          }     
          console.log(window.location);
          
          if(response.payload?.data?.status_code=='422'){
            warningToast(response.payload?.data?.massage)
            setLoading(false)
            setDetailsToSend((pre)=>{
              return {...pre, shop:shop, platformId:response.payload?.data?.data?.available_platform_id,  }
            })
            setOpenShopifyForm(true)
          }     
          setLoading(false)
          
        })
        .catch((err)=>{
          warningToast('er')
        })
      }else{
        const shopifyCredDetails  = localStorage.getItem('shopifyOAuthData')
        if(shopifyCredDetails){
          const parsedDetails = JSON.parse(shopifyCredDetails)
          payload.display_name = shop.split('.')[0]
          payload.platform_info  = parsedDetails?.platformId
          payload.credentials.identifier = parsedDetails?.shop
          payload.credentials.client_id = parsedDetails?.clientId
          payload.credentials.client_secret = parsedDetails?.clientSecret

          dispatch(handleShopifyOauthCallbackAsync({body:payload,params:{code:code}}))
          .then((response)=>{
            if(response?.payload?.status_code=='400'){
                warningToast(response.payload?.massage)
                setLoading(false)
            }

            if(response.payload?.status_code=='201'){
              localStorage.setItem(accessSessionTokenKeyName, response?.payload?.data?.access_token)
              localStorage.setItem(refreshSessionTokenKeyName, response?.payload?.data?.refresh)
              setLoading(false)
              dispatch(setLoggingData({
                isLoggedIn:true,
                userData:{...response.payload?.data}
              }))
            }
            console.log('calback response', response.payload);
            setLoading(false)
          })
        }

      }
    }else{
      setLoading(false)
    }
    // setLoading(false)
     
  }, [])
  
  if(loading) return <Loader/>
  
  return(
  <>
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='change-password' element={<ResetPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
  <ShopifyUserCredForm
    isVisible={openShopifyForm}
    detailsToSend={detailsToSend}
    closeModal={()=>{}}
  />
  </>
)}

export {AuthPage}
