// platformSlice.js

import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { fetchPlatformsAsync } from "../actions/platformActions";
import { Platform } from "src/interfaces/Platform.interface";

type plinkedProductVarientDataPayload={
    linkedProductVarientData:any[]| []
    linkedProductVarientDataLoading:boolean

}


const initialState :plinkedProductVarientDataPayload= {
    linkedProductVarientDataLoading:false,
  linkedProductVarientData : []
};

const linkProductVarientSlice = createSlice({
  name: "platformSlice",
  initialState,
   extraReducers: (builder) => {
   
    builder.addMatcher(isAnyOf(fetchPlatformsAsync.pending), (state,action)=>{
        state.linkedProductVarientDataLoading=true; 
      })
      builder.addMatcher(isAnyOf(fetchPlatformsAsync.fulfilled), (state,action)=>{
      
        state.linkedProductVarientDataLoading=false; 
        state.linkedProductVarientData = action?.payload?.data;
      })
      builder.addMatcher(isAnyOf(fetchPlatformsAsync.rejected), (state)=>{
        state.linkedProductVarientDataLoading=false; 
      })
      
  },
  reducers: {
   
      
  }  
});


export const {} = linkProductVarientSlice.actions
export default linkProductVarientSlice.reducer;
