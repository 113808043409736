import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'
export const savePlatformPriceAsync = createAsyncThunk(
  'platform/savePlatformPriceAsync',
  async function (payload: any, toolkit) {
    const url = `/platform-price-template`
    return await AxiosClient('POST', url, payload, toolkit, [])
  }
)

export const getPlatformPriceAsync = createAsyncThunk(
  'platform/getPlatformPriceAsync',
  async function (
    params: {platform_id?: string; paginationdata: {page: number; limit: number}},
    toolkit
  ) {
    const url = `/platform-price-template?platform_id=${params.platform_id}`
    return await AxiosClient('GET', url, [], toolkit, params.paginationdata)
  }
)

export const updatePlatformPriceAsync = createAsyncThunk(
  'platform/updatePlatformPriceAsync',
  async function (params: {platform_id?: number; payload: any}, toolkit) {
    const url = `/platform-price-template/${params.platform_id}`
    return await AxiosClient('PUT', url, params.payload, toolkit, [])
  }
)

export const deletPlatformPriceAsync = createAsyncThunk(
  'platform/deletPlatformPriceAsync',

  async function (params: {platform_id?: string}, toolkit) {
    const url = `/platform-price-template/${params.platform_id}`
    return await AxiosClient('DELETE', url, [], toolkit, [])
  }
)
