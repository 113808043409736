import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {FormikState} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {MORE_MAPPING_FORM_DATA, MORE_TEMPLATE_DATA, MoreFieldMappingItem, MoreMapping_Item_On_Get} from 'src/interfaces/Template.interface'
import {getPlatformFieldsAsync} from 'src/redux/actions/productActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import TemplateMappingItem from './TemplateMappingItem'
import { deleteMoreMappingDataAsync } from 'src/redux/actions/templateAndPricingActions'
interface AttributeInputProps {
  categoryAttributes: MoreFieldMappingItem
  paltformId: any
  // handleAttributeSelection: (
  //   data: {
  //     attribute_field: number
  //     value_str?: string | null
  //     value?: number | null
  //     mapped_field?: number | null | string
  //   }[]
  // ) => void
  values: MORE_MAPPING_FORM_DATA
  handleChange: (e: string | React.ChangeEvent<any>) => void
  setFormikState: (
    f:
      | FormikState<MORE_MAPPING_FORM_DATA>
      | ((prevState: FormikState<MORE_MAPPING_FORM_DATA>) => FormikState<MORE_MAPPING_FORM_DATA>),
    cb?: (() => void) | undefined
  ) => void
  moreMappingData:MoreMapping_Item_On_Get|null
  getLatestMoreMappingDataList: () => void
}

type OPTION_TYPE = 'NATIVE' | 'CUSTOM' | 'DEFAULT'

const InputAttributes2: FC<AttributeInputProps> = ({
  categoryAttributes,
  paltformId,
  // handleAttributeSelection,
  values,
  handleChange,
  setFormikState,
  moreMappingData,
  getLatestMoreMappingDataList,
}) => {
  const dispatch = useAppDispatch()
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform) || {}
  const [selectedTextValues, setSelectedTextValues] = useState<{[key: number]: string | null}>({})
  const [selectedValues, setSelectedValues] = useState<{[key: number]: number | null}>({})
  const [selectedFieldValues, setSelectedFieldValues] = useState<{[key: number]: number}>({})
  const [attributeMappingOptions, setAttributeMappingOptions] = useState<OPTION_TYPE[]>(['DEFAULT'])
  const [fieldData, setFieldData] = useState<any[]>([])
  const [fieldDataCustom, setFieldDataCustom] = useState<any[]>([])
  const [initialValueLoading, setInitialValueLoading] = useState(true)
  const [deletingTemplateData, setDeletingTemplateData] = useState(false)
  // State to manage additional rows
  const [additionalRows, setAdditionalRows] = useState<any[]>([])
  useEffect(() => {
    if (paltformId) {
      setInitialValueLoading(true)
      Promise.all([
        dispatch(
          getPlatformFieldsAsync({
            platformId: mainStoreData?.id!,
            otherParams: {field_type: 'NATIVE', includeLocalFields: true},
          })
        ).then((response) => {
          setFieldData(response.payload || [])
        }),
        dispatch(
          getPlatformFieldsAsync({
            platformId: mainStoreData?.id!,
            otherParams: {field_type: 'CUSTOM', includeLocalFields: false},
          })
        ).then((response) => {
          setFieldDataCustom(response.payload || [])
        }),
      ]).finally(() => {
        setInitialValueLoading(false)
      })
    }
  }, [paltformId, dispatch, mainStoreData])
  const handleMappingOptionChange = (attributeId: number, option: any) => {
    // setAttributeMappingOptions((prevValues) => [...prevValues, {[attributeId]: option}])

    if (option === 'NATIVE' || option === 'CUSTOM') {
      const fieldType = option === 'CUSTOM' ? 'CUSTOM' : 'NATIVE'
      dispatch(
        getPlatformFieldsAsync({
          platformId: mainStoreData?.id!,
          otherParams: {field_type: fieldType, includeLocalFields: option === 'NATIVE'},
        })
      ).then((response) => {
        if (fieldType === 'NATIVE') {
          setFieldData(response.payload)
        } else {
          setFieldDataCustom(response.payload)
        }
      })
    }
  }


  const handleValueChange = (attributeId: number, value: number) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [attributeId]: value,
    }))
  }
  const handleValueTextChange = (attributeId: number, value: string) => {
    setSelectedTextValues((prevValues) => ({
      ...prevValues,
      [attributeId]: value,
    }))
  }
  const handleFieldValueChange = (attributeId: number, value: number) => {
    setSelectedFieldValues((prevValues) => ({
      ...prevValues,
      [attributeId]: value,
    }))
  }
  // const constructPayloadAttribute = (
  //   mappingOptions: {[key: number]: string},
  //   selectedTextValues: {[key: number]: string | null},
  //   selectedValues: {[key: number]: number | null},
  //   selectedFieldValues: {[key: number]: number | null}
  // ) => {
  //   // alert(2)
  //   if (!Array.isArray(categoryAttributes)) {
  //     console.error('categoryAttributes is not an array')
  //     return
  //   }
  //   // alert(1)
  //   const attributeData = categoryAttributes.map((attribute, ind) => {
  //     const mappingOption = mappingOptions[attribute.id] || 'DEFAULT' // Get the mapping option for the attribute
  //     if (mappingOption === 'DEFAULT') {
  //       return {
  //         id: null,
  //         attribute_field: attribute.id,
  //         value_str: selectedTextValues[attribute.id] || null,
  //         value: selectedValues[attribute.id] || null,
  //         mapped_field: null,
  //       }
  //     } else {
  //       return {
  //         id: null,
  //         attribute_field: attribute.id,
  //         value_str: null,
  //         value: null,
  //         mapped_field: selectedFieldValues[attribute.id] || null,
  //       }
  //     }
  //   })
  //   // Call handleAttributeSelection outside the function
  //   console.log('attributeData', attributeData)
  //   handleAttributeSelection(attributeData)
  // }
  // useEffect(() => {
  //   constructPayloadAttribute(
  //     attributeMappingOptions,
  //     selectedTextValues,
  //     selectedValues,
  //     selectedFieldValues
  //   )
  // }, [attributeMappingOptions, selectedTextValues, selectedValues, selectedFieldValues])

  const handleRemoveRow = (index: number, templateDataId:number|null ) => {

    let copiedArray = [...values.template_data]
    let filteredItems:MORE_TEMPLATE_DATA[] = []
    if(templateDataId){
      //it means data is already created need to call an api for delete
      setDeletingTemplateData(true)
      dispatch(deleteMoreMappingDataAsync({platforn_field_template_data_id:templateDataId+''}))
      .then(response=>{
        filteredItems = copiedArray.filter(
          (it, _) => it.id !== templateDataId
        )
        setFormikState((pre) => {
          return {
            ...pre,
            values: {
              ...pre.values,
              template_data: [...filteredItems],
            },
          }
        })
        setDeletingTemplateData(false)
        getLatestMoreMappingDataList()
      })
    }else{
       filteredItems = copiedArray.filter(
        (_, existingMappingIndex) => existingMappingIndex !== index
      )
      setFormikState((pre) => {
        return {
          ...pre,
          values: {
            ...pre.values,
            template_data: [...filteredItems],
          },
        }
      })
    }

  }
  return (
    <div className='d-flex flex-column '>
      
      {/* MULTI_INSTANCE replaced with MULTI Due to input type also coming MULTILINE_TEXT */}
      {categoryAttributes.input_type.includes('MULTI') ? (
        <div className='text-end col-12  me-3 '>
          <button
            type='button'
            className='btn btn-primary btn-sm '
            onClick={() => {
              setFormikState((pre) => {
                return {
                  ...pre,
                  values: {
                    ...pre.values,

                    template_data: [
                      ...pre.values.template_data,
                      {
                        id: null,
                        mapped_field: null,
                        value: null,
                        value_str: '',
                      },
                    ],
                  },
                }
              })
            }}
          >
            +Add New
          </button>
        </div>
      ) : null}
      {/* Additional Rows */}
      {values.template_data.map((rowData:MORE_TEMPLATE_DATA, index:number) => {
        return (
          <TemplateMappingItem
            handleRemoveRow={handleRemoveRow}
            categoryAttributes={categoryAttributes}
            index={index}
            values={values}
            rowData={rowData}
            handleChange={handleChange}
            handleValueTextChange={handleValueTextChange}
            selectedTextValues={selectedTextValues}
            selectedFieldValues={selectedFieldValues}
            setFormikState={setFormikState}
            moreTemplateItemData={moreMappingData}
            moreTemplateItemDataUnderMap={ moreMappingData? moreMappingData.template_data[index] : null }
            deletingTemplateData={deletingTemplateData}
          />
        )
      })}
      {/* Add New Button */}

    </div>
  )
}
export default InputAttributes2
