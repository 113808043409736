import React, {FC, useEffect, useState} from 'react'
import {Button, Card, Spinner} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {
  getPlatformProductAdjustmentAsync,
  getPlatformProductAdjustmentSecondaryAsync,
  getPlatformProductDetailAsync,
  getPlatformProductVarientDetailAsync,
} from 'src/redux/actions/productActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import clsx from 'clsx'
import DataTable from 'react-data-table-component'
import moment from 'moment'

import {PageLink, PageTitle} from 'src/marketConnect/layout/core'
import {Link} from 'react-router-dom'
import {Varient_Data} from 'src/interfaces/productVariantInterface'
import {VARIANT_DATA} from 'src/interfaces/Platform.interface'
const CommonColumn: FC<{rowData: string}> = ({rowData}) => {
  return (
    <>
      <p>{rowData}</p>
    </>
  )
}

const ProductVariantDetail: FC = () => {
  const params = useParams()

  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
  })

  const {
    platformProductDetailLoader,
    platformProductDetail,
    platformAdjustmentCount,
    platformAdjustments,
    platformAdjustmentLoader,
  } = useAppSelector((state) => state.productsOfSingleChannel)

  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const dispatch = useAppDispatch()
  console.log('mainstore', mainStoreData)

  useEffect(() => {
    console.log('params', params)

    if (params?.productVariantId) {
      dispatch(getPlatformProductVarientDetailAsync({productVarientId: params?.productVariantId}))
      // .then((response)=>{
      //     console.log('response varient data', response?.payload?.varients_data)
      //     console.log('response varient data2', platformProductDetail?.varients_data)
      // })
    }
  }, [mainStoreData])

  useEffect(() => {
    if (params?.platformId && params?.variantId && params?.productId) {
      dispatch(
        getPlatformProductAdjustmentSecondaryAsync({
          platformId: params?.platformId,
          otherParams: {
            ...paginationData,
            variant_id: params?.variantId,
            product_id: params?.productId,
          },
        })
      )
    }
  }, [paginationData])

  if (platformProductDetailLoader || platformAdjustmentLoader) return <Loader />

  const variants_table_header_column = [
    {
      name: 'Variant Name',
      cell: (row) => (
        <CommonColumn
          rowData={
            row?.platforminventoryadjustmentsyncdata_set[0]?.updated_product_variant?.name ==
            'Default Title'
              ? row?.platforminventoryadjustmentsyncdata_set[0]?.updated_product_variant
                  ?.platform_product?.name
              : row?.platforminventoryadjustmentsyncdata_set[0]?.updated_product_variant
                  ?.platform_product?.name
          }
        />
      ),
      sortable: false,
      grow: 2,
    },
    {
      name: 'Available Quantity',
      cell: (row) => (
        <p className='mb-0'>{row?.platforminventoryadjustmentsyncdata_set[0]?.updated_quantity}</p>
      ),

      sortable: false,
      right: true,
      center: true,
    },

    // {
    //   name: 'Price',
    //   cell: (row) => <CommonColumn rowData={row?.product_variant?.price?.value} />,
    //   sortable: true,
    //   right: true,
    //   center: true,
    // },

    {
      name: 'Created At',
      cell: (row) =>
        row ? (
          <CommonColumn
            rowData={moment
              .utc(new Date(row?.platforminventoryadjustmentsyncdata_set[0]?.created_at))
              .format('hh:mm A,  DD MMM YY')}
          />
        ) : null,
      sortable: true,
      sortField: 'api_created_at',
    },
    {
      name: 'Status',
      cell: (row) => (
        <p className='mb-0'>
          {row?.platforminventoryadjustmentsyncdata_set[0]?.destination_sync_status}
        </p>
      ),
      sortable: true,
      sortField: 'Status',
    },
  ]

  const handleSort = (ev, sortDirection) => {
    console.log('ev', ev)
    setPaginationData((pre) => {
      return {...pre, sort_by: ev.sortField, sort_order: (sortDirection + '').toUpperCase()}
    })
  }
  console.log('getPlatformProductVarientDetailAsync', platformProductDetail)
  return (
    <>
      {/* <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
        <li className='nav-item'>
          <a className='nav-link fs-5 fw-bold active text-grey-900' data-bs-toggle='tab' href='#kt_tab_pane_4'>
            General Info
          </a> */}
      {/* <Breadcrum /> */}
      {/* <PageTitle breadcrumbs={accountBreadCrumbs}>Overview</PageTitle>
          <ol className='breadcrumb text-muted fs-6 fw-bold'>
            <li className='breadcrumb-item pe-3 '>
              <Link to='/listing-product-table/37' className='pe-3 text-muted'>
                Product Tabel
              </Link>
            </li>
            <li className='breadcrumb-item px-3 text-muted'>Product Detail</li>
          </ol>
        </li>
      </ul> */}
      <div
        id='kt_page_title'
        data-kt-swapper='true'
        data-kt-swapper-mode='prepend'
        data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
        className='page-title d-flex flex-wrap me-3 flex-column justify-content-center mb-4'
      >
        <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 my-0 flex-column justify-content-center'>
          General Info
        </h1>
        <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 mt-2'>
          <li className='breadcrumb-item text-muted'>
            <Link
              to={`/listing-product-table/${params?.platformId}`}
              className='pe-3 text-hover-primary'
              style={{textDecoration: 'none', color: 'inherit', cursor: 'pointer'}}
              onMouseOver={(e) => (e.currentTarget.style.color = 'blue')}
              onMouseOut={(e) => (e.currentTarget.style.color = 'inherit')}
            >
              Product Table
            </Link>
          </li>
          <li className='breadcrumb-item'>
            <span className='bullet bg-gray-500 w-5px h-2px'></span>
          </li>
          <li className='breadcrumb-item px-3 text-muted text-hover-primary'>
            Linked Product Detail
          </li>
        </ul>
      </div>

      <div className='tab-content' id='myTabContent'>
        <div className='tab-pane fade show active' id='kt_tab_pane_4' role='tabpanel'>
          <div className='card'>
            <div className='p-4'>
              <div className='border border-bottom border-top-0 border-start-0 border-end-0 py-2'>
                <label htmlFor='' className='fs-5 text-secondary fw-bold'>
                  Product Details
                </label>
              </div>
              <div className='py-4'>
                {/* <div className='card'>
                  <div className='border border-bottom p-2'>
                    <p className='h5 fw-bold'>Product Name</p>
                  </div>
                </div> */}
                <div className='d-flex align-items-center mt-2 p-4'>
                  {platformProductDetail?.varients_data.length ? (
                    platformProductDetail?.varients_data.find(
                      (item: VARIANT_DATA) => item?.id === Number(params?.productVariantId)
                    ) ? (
                      platformProductDetail?.varients_data.map((item: VARIANT_DATA) =>
                        item?.id === Number(params?.productVariantId) ? (
                          <div key={item?.id}>
                            <div className='d-flex flex-row'>
                            <div className='h4 mb-0 text-info'>
                              {platformProductDetail?.name}{'  '} </div>
                             <div className='h4 ms-4 text-dark'> {item?.name === 'Default Title' ? null : item?.name}</div> 
                            </div>
                   
                           
                            {/* <p className='ms-4 h4 mb-0 text-dark'>
                                   {item?.name === 'Default Title' ? null : ( item?.name)}
                                </p> */}

                            <p className='h5 mt-1 text-dark'>SKU - {item?.sku}</p>
                          </div>
                        ) : null
                      )
                    ) : (
                      <p>Variant not found</p>
                    )
                  ) : null}
                  {/* <div className='d-flex align-items-center  justify-content-around flex-wrap'>
                    <div className='d-flex align-items-center mt-2 p-4'>
                      <p className='h4 mb-0 text-info'>{platformProductDetail?.name}</p>
                      <div className='d-flex align-items-center mt-2 p-4 justify-content-around flex-wrap'>
                        {platformProductDetail?.varients_data.length ? (
                          platformProductDetail?.varients_data.find(
                            (item: VARIANT_DATA) => item.id === Number(params?.productVariantId)
                          ) ? (
                            platformProductDetail?.varients_data.map((item: VARIANT_DATA) =>
                              item.id === Number(params?.productVariantId) ? (
                                <React.Fragment key={item.id}>
                                <p className='h4 mb-0 text-info'>{platformProductDetail?.name}</p>
                                <p className='ms-4 h4 mb-0 text-dark'>
                                   {item.name === 'Default Title' ? null : ( item.name)}
                                </p>
                                <div>
                                  <p className='h5 mb-0 text-dark ms-5'>SKU - {item?.sku}</p>
                                </div>
                              </React.Fragment>
                              ) : null
                            )
                          ) : (
                            <p>Variant not found</p>
                          )
                        ) : null}
                      </div>
                    </div>
                </div> */}
                </div>

                {/* <div className='card mt-4'>
                  <div className='border border-bottom p-2'>
                    <p className='h5 fw-bold'>Product Type</p>
                  </div>
                  <div className='d-flex align-items-center mt-2 p-4'>
                    <p className='h4 mb-0 text-info'>{platformProductDetail?.product_type}</p>
                  </div>
                </div> */}

                {/* <div className='card mt-4'>
                  <div className='border border-bottom p-2'>
                    <p className='h5 fw-bold'>Images</p>
                  </div>
                </div> */}
                <div className='d-flex align-items-center mt-2 p-4  justify-content-around flex-wrap'>
                  {platformProductDetail?.image_set.length
                    ? platformProductDetail?.image_set.map((item, index) => {
                        return (
                          <div className='shadow p-2 rounded'>
                            <img
                              src={item?.image_url}
                              alt={item.image_position}
                              className='w-140px h-80px'
                            />
                          </div>
                        )
                      })
                    : null}
                </div>

                {/* <div className='card mt-4'>
                  <div className='border border-bottom p-2'>
                    <p className='h5 fw-bold'>Tags</p>
                  </div>
                  <div className='d-flex align-items-center mt-2 p-4'>
                    <p className='h5 mb-0 text-secondary'>{platformProductDetail?.tags}</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className='p-4'>
            <div className='pt-4'>
              {params?.platformId !== mainStoreData?.id!.toString() ? (
                <div className='card mt-4'>
                  <div>
                    <label htmlFor='' className='fs-5 text-secondary fw-bold p-2'>
                      Inventory Logs
                    </label>
                    <DataTable
                      title={'Adjustment of ' + platformProductDetail?.name}
                      fixedHeader={true}
                      onSort={handleSort}
                      columns={variants_table_header_column}
                      progressPending={platformAdjustmentLoader}
                      pagination
                      paginationServer
                      paginationTotalRows={platformAdjustmentCount}
                      data={platformAdjustments}
                      // selectableRows
                      onChangePage={(pageNumber, totalRows) => {
                        setPaginationData((pre) => {
                          return {...pre, page: pageNumber}
                        })
                      }}
                      onChangeRowsPerPage={(currentPage) => {
                        setPaginationData((pre) => {
                          return {...pre, limit: currentPage}
                        })
                      }}
                      progressComponent={<Spinner animation='border' />}
                      // onChangePage={(pageNumber, totalRows) => {
                      //   dispatch(getProductsListAsync({id: pageNumber.toString(), subparams:{page:pageNumber} }))
                      // }}

                      // subHeader
                      // subHeaderComponent={subHeaderComponentMemo}
                      customStyles={{
                        table: {
                          style: {
                            height: '60vh',
                          },
                        },
                        headCells: {
                          style: {fontWeight: 'bold', fontSize: '14px'},
                        },
                        cells: {
                          style: {
                            padding: '10px',
                            borderBottom: '1px solid gainsboro',
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {/* <div className='tab-pane fade' id='kt_tab_pane_5' role='tabpanel'>
        </div>
        <div className='tab-pane fade' id='kt_tab_pane_6' role='tabpanel'>
          <div style={{height: '100vh'}} className='overflow-scroll'>
            <div dangerouslySetInnerHTML={{__html: platformProductDetail?.description ?? ''}}></div>
          </div>
        </div> */}
      </div>
    </>
  )
}

export default ProductVariantDetail
