import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const isActiveBoolean = createAsyncThunk(
  'platform/isActive',
  async function (
    payload: {
      display_name: string | undefined
      isActive: boolean | undefined
      id: number | undefined
    },
    toolkit
  ) {
    return await AxiosClient('PUT', `/platform/${payload.id}`, payload, toolkit, [])
  }
)
