import React, {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import {Calendar, DateRangePicker} from 'react-date-range'
import moment from 'moment'

type SelectionRange = {
    startDate: string,
    endDate: string,
  }

type AppDateFilterProps = {
  isVisible: boolean
  handleClose: () => void
  onPickRange:(range:SelectionRange)=>void
  resetDateFilter?:()=>void
}

const AppDatePickerRange: FC<AppDateFilterProps> = ({ handleClose, isVisible, onPickRange }) => {
  const [selectedRange, setSelectedRange] = useState([{
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }])

  return (
    <Modal backdrop='static' centered show={isVisible} className='w-100' >
      <DateRangePicker 
        date={new Date()} 
        ranges={selectedRange} 
        maxDate={new Date()} 
        className='bg-white w-100'
        rangeColors={['#0d6efd', '#3ecf8e', '#fed14c']}
        classNames={{
            selected:'bg-info',
            daySelected:'bg-info',
            calendarWrapper:'overflow-x-scroll w-sm-100'
        }}
        onChange={(ev:any)=>{
            console.log('ev', ev)
            setSelectedRange([ev.selection])
        }}
      />
      <div className='d-flex justify-content-end p-2 bg-white'>
        <button className='btn btn-text btn-sm me-4' onClick={()=>{
            handleClose()
        }} >Close</button>
        <button
          className='btn btn-sm btn-primary'
          onClick={() => {
            onPickRange({
              startDate: moment(selectedRange[0].startDate)
                .utc(true)
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
              endDate: moment(selectedRange[0].endDate)
                .utc(true)
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            })
            handleClose()
          }}
        >
          Apply
        </button>
      </div>
    </Modal>
  )
}

export default AppDatePickerRange