import React, { Fragment, useEffect, useState } from 'react';


interface InputProps {
  id: number;
  name: string;
  display_name: string;
  input_type?: string;
  inputValue: string;
  onDataChange: (data: { id: number;name:string; value: string }) => void;
}

const InputFieldComponent: React.FC<InputProps> = (props) => {

  const {
    id,
    name,
    display_name,
    input_type ,
    inputValue,
    onDataChange,
  } = props;

  const [value, setValue] = useState('');

  // Update local value when input value changes
  useEffect(() => {

    setValue(inputValue);
  }, [inputValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
     const newValue = event.target.value;
    setValue(newValue);
    onDataChange({ id, name, value: newValue });
  
  };
  const inputType = input_type === 'password' ? 'password' : 'text';

  return (
    <Fragment>
      <div className='d-flex flex-column align-items-sm-center flex-sm-row justify-content-start w-sm-75 ms-lg-20 ms-md-10 ms-sm-5 ms-0 ' >
        <label className='form-label' >
          {display_name}
        </label>
        <input
          key={id}
          type={inputType}
          className="form-control form-control-solid ms-sm-4"
          placeholder={display_name}
          value={value}
          onChange={handleChange}
          style={{ width: '250px' }}
        />
        
        
      </div>
    </Fragment>
  );
};

export default InputFieldComponent;
