import React, { FC } from 'react'
import { Spinner } from 'react-bootstrap'

const Loader:FC = () => {
  return (
    <div style={{minHeight:"80vh"}} className='d-flex justify-content-center align-items-center' >
        <Spinner animation='border' />
    </div>
  )
}

export default Loader