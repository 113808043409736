import React from 'react'

const CardToolBar = () => {
  return (
    <div>
           <div className='card-toolbar'>
                  {/*<!--begin::Toolbar-->  */}
                  <div className='d-flex justify-content-end' data-kt-customer-table-toolbar='base'>
                    {/*<!--begin::Filter-->  */}
                    {/* <button
                      type='button'
                      className='btn btn-light-primary me-3'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                    >
                      <i className='ki-duotone ki-filter fs-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>{' '}
                      Filter
                    </button> */}
                    {/*<!--begin::Menu 1-->  */}
                    <div
                      className='menu menu-sub menu-sub-dropdown w-300px w-md-325px'
                      data-kt-menu='true'
                      id='kt-toolbar-filter'
                      style={{}}
                    >
                      {/*<!--begin::Header-->  */}
                      <div className='px-7 py-5'>
                        <div className='fs-4 text-gray-900 fw-bold'>Filter Options</div>
                      </div>
                      {/*<!--end::Header-->  */}

                      {/*<!--begin::Separator-->  */}
                      <div className='separator border-gray-200'></div>
                      {/*<!--end::Separator-->  */}

                      {/*<!--begin::Content-->  */}
                      <div className='px-7 py-5'>
                        {/*<!--begin::Input group-->  */}
                        <div className='mb-10'>
                          {/*<!--begin::Label-->  */}
                          <label className='form-label fs-5 fw-semibold mb-3'>Month:</label>
                          {/*<!--end::Label-->  */}

                          {/*<!--begin::Input-->  */}
                          <select
                            className='form-select form-select-solid fw-bold select2-hidden-accessible'
                            data-kt-select2='true'
                            data-placeholder='Select option'
                            data-allow-clear='true'
                            data-kt-customer-table-filter='month'
                            data-dropdown-parent='#kt-toolbar-filter'
                            data-select2-id='select2-data-9-hsxe'
                            tabIndex={-1}
                            aria-hidden='true'
                            data-kt-initialized={1}
                          >
                            <option data-select2-id='select2-data-11-l3pw'></option>
                            <option value='aug'>August</option>
                            <option value='sep'>September</option>
                            <option value='oct'>October</option>
                            <option value='nov'>November</option>
                            <option value='dec'>December</option>
                          </select>
                          <span
                            className='select2 select2-container select2-container--bootstrap5'
                            dir='ltr'
                            data-select2-id='select2-data-10-6kiu'
                            style={{width: '100%'}}
                          >
                            <span className='selection'>
                              <span
                                className='select2-selection select2-selection--single form-select form-select-solid fw-bold'
                                role='combobox'
                                aria-haspopup='true'
                                aria-expanded='false'
                                tabIndex={0}
                                aria-disabled='false'
                                aria-labelledby='select2-zcrm-container'
                                aria-controls='select2-zcrm-container'
                              >
                                <span
                                  className='select2-selection__rendered'
                                  id='select2-zcrm-container'
                                  role='textbox'
                                  aria-readOnly='true'
                                  title='Select option'
                                >
                                  <span className='select2-selection__placeholder'>
                                    Select option
                                  </span>
                                </span>
                                <span className='select2-selection__arrow' role='presentation'>
                                  <b role='presentation'></b>
                                </span>
                              </span>
                            </span>
                            <span className='dropdown-wrapper' aria-hidden='true'></span>
                          </span>
                          {/*<!--end::Input-->  */}
                        </div>
                        {/*<!--end::Input group-->  */}

                        {/*<!--begin::Input group-->  */}
                        <div className='mb-10'>
                          {/*<!--begin::Label-->  */}
                          <label className='form-label fs-5 fw-semibold mb-3'>Payment Type:</label>
                          {/*<!--end::Label-->  */}

                          {/*<!--begin::Options-->  */}
                          <div
                            className='d-flex flex-column flex-wrap fw-semibold'
                            data-kt-customer-table-filter='payment_type'
                          >
                            {/*<!--begin::Option-->  */}
                            <label className='form-check form-check-sm form-check-custom form-check-solid mb-3 me-5'>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='payment_type'
                                value='all'
                              />
                              <span className='form-check-label text-gray-600'>All</span>
                            </label>
                            {/*<!--end::Option-->  */}

                            {/*<!--begin::Option-->  */}
                            <label className='form-check form-check-sm form-check-custom form-check-solid mb-3 me-5'>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='payment_type'
                                value='visa'
                              />
                              <span className='form-check-label text-gray-600'>Visa</span>
                            </label>
                            {/*<!--end::Option-->  */}

                            {/*<!--begin::Option-->  */}
                            <label className='form-check form-check-sm form-check-custom form-check-solid mb-3'>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='payment_type'
                                value='mastercard'
                              />
                              <span className='form-check-label text-gray-600'>Mastercard</span>
                            </label>
                            {/*<!--end::Option-->  */}

                            {/*<!--begin::Option-->  */}
                            <label className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input
                                className='form-check-input'
                                type='radio'
                                name='payment_type'
                                value='american_express'
                              />
                              <span className='form-check-label text-gray-600'>
                                American Express
                              </span>
                            </label>
                            {/*<!--end::Option-->  */}
                          </div>
                          {/*<!--end::Options-->  */}
                        </div>
                        {/*<!--end::Input group-->  */}

                        {/*<!--begin::Actions-->  */}
                        <div className='d-flex justify-content-end'>
                          <button
                            type='reset'
                            className='btn btn-light btn-active-light-primary me-2'
                            data-kt-menu-dismiss='true'
                            data-kt-customer-table-filter='reset'
                          >
                            Reset
                          </button>

                          <button
                            type='submit'
                            className='btn btn-primary'
                            data-kt-menu-dismiss='true'
                            data-kt-customer-table-filter='filter'
                          >
                            Apply
                          </button>
                        </div>
                        {/*<!--end::Actions-->  */}
                      </div>
                      {/*<!--end::Content-->  */}
                    </div>
                    {/*<!--end::Menu 1-->  */} {/*<!--end::Filter-->  */}
                    {/*<!--begin::Export-->  */}
                    {/* <button
                      type='button'
                      className='btn btn-light-primary me-3'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_customers_export_modal'
                    >
                      <i className='ki-duotone ki-exit-up fs-2'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>{' '}
                      Export
                    </button> */}
                    {/*<!--end::Export-->  */}
                    {/*<!--begin::Add customer-->  */}
                    <button
                      type='button'
                      className='btn btn-primary'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_add_customer'
                    >
                      Add Customer
                    </button>
                    {/*<!--end::Add customer-->  */}
                  </div>
                  {/*<!--end::Toolbar-->  */}
                  {/*<!--begin::Group actions-->  */}
                  <div
                    className='d-flex justify-content-end align-items-center d-none'
                    data-kt-customer-table-toolbar='selected'
                  >
                    <div className='fw-bold me-5'>
                      <span className='me-2' data-kt-customer-table-select='selected_count'></span>{' '}
                      Selected
                    </div>

                    <button
                      type='button'
                      className='btn btn-danger'
                      data-kt-customer-table-select='delete_selected'
                    >
                      Delete Selected
                    </button>
                  </div>
                  {/*<!--end::Group actions-->  */}{' '}
                </div>
    </div>
  )
}

export default CardToolBar