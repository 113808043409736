import React, {ChangeEventHandler, FC} from 'react'

interface InputProps {
  id: number
  name: string
  display_name: string
  input_type?: string
  inputValue: string
  onDataChange: (ev:React.ChangeEvent<HTMLSelectElement>) => void
}

const TikTokCountrySelect: FC<InputProps> = ({
  display_name,
  id,
  inputValue,
  name,
  onDataChange,
  input_type,
}) => {
  return (
      <div className='d-flex flex-column align-items-sm-center flex-sm-row justify-content-start w-sm-75 ms-lg-20 ms-md-10 ms-sm-5 ms-0 '>
        <label className='form-label'>{display_name}</label>
        <select name='' value={inputValue} onChange={onDataChange} id='' className='form-control form-control-solid ms-sm-4 w-50'>
          <option value=''>Select an {display_name} </option>
          <option value='US'>US</option>
          <option value='OTHERS'>OTHERS</option>
        </select>
      </div>
  )
}

export default TikTokCountrySelect
