import React, {FC, useState} from 'react'
import DataTable from 'react-data-table-component'
import VendorListActionCell from './VendorListActionCell'
import AddUpdateVendorData from './AddUpdateVendorData'
import { VENDOR_USER } from 'src/app/_interfaces/customer.interface'
import { setVendorDataForUpdate } from 'src/redux/slices/customerSlice'
import { useAppDispatch } from 'src/redux/storeHooks'
import { useNavigate } from 'react-router-dom'

const StatusCell = ({status}) => {
  if (status === 'active') {
    return (
      <span className='text-success fw-bold text-center w-100px p-2 bg-light-success'>Active</span>
    )
  } else if (status === 'inactive') {
    return (
      <span className='text-warning fw-bold text-center w-100px p-2 bg-light-warning'>
        Inactive
      </span>
    )
  } else if (status === 'suspended') {
    return (
      <span className='text-danger fw-bold text-center w-100px p-2 bg-light-danger'>Suspended</span>
    )
  } else {
    return (
      <span className='text-primary fw-bold text-center w-100px p-2 bg-light-primary'>Pending</span>
    )
  }
}



const Vendors: FC = () => {
  const [searchString, setSearchString] = useState<string>('')
  const [showAddVendorModal, setShowAddVendorModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [paginationData, setPaginationData] = useState({
    page_limit: 10,
    page_num: 1,
    order_by: '-id',
    search: '',
    user_type: 'USER',
    from_date: '',
    to_date: '',
  })

  const columns = [
    {
      name: 'Name',
      cell: (row) => row.name,
    },
    {
      name: 'Email',
      cell: (row) => row.email,
    },
    {
      name: 'Company Name',
      cell: (row) => row.company,
    },
    {
      name: 'Status',
      cell: (row) => <StatusCell status={row.status} />,
      center: true,
    },
    {
      name: 'Created Date',
      cell: (row) => row.createdAt,
    },
    {
      name: 'Actions',
      cell: (row) => <VendorListActionCell vendorDetail={row} 
      updateVendor={() => {
        dispatch(setVendorDataForUpdate(row))
        navigate(`/edit-vendor/${row.id}`)
      }} />,
      center: true,
    },
  ]
  const vendors = [
    {
      id: '1',
      name: 'John Doe',
      email: 'johndoe@example.com',
      status: 'active',
      createdAt: '2023-01-15T10:30:00Z',
      company: 'Doe Enterprises',
      description: 'Supplier of high-quality consumer electronics.',
      shipping_info: {
        addressLine1: '123 Elm Street',
        addressLine2: 'Suite 200',
        town: 'Springfield',
        state: 'IL',
        pincode: '62701',
        originCountry: 'USA',
        useAsBillingAddress: false
      },
      billing_info: {
        tinNumber: '123-456-789',
        accountNumber: '9876543210',
        paymentTerms: 'Net 30',
        paymentDescription: 'Payments due within 30 days from the invoice date.'
      }
    },
    {
      id: '2',
      name: 'Jane Smith',
      email: 'janesmith@example.com',
      status: 'inactive',
      createdAt: '2023-02-20T12:45:00Z',
      company: 'Smith & Co.',
      description: 'Specializes in handmade jewelry and accessories.',
      shipping_info: {
        addressLine1: '456 Oak Avenue',
        addressLine2: 'Building 3',
        town: 'Riverton',
        state: 'WY',
        pincode: '82501',
        originCountry: 'USA',
        useAsBillingAddress: true
      },
      billing_info: {
        tinNumber: '987-654-321',
        accountNumber: '1234567890',
        paymentTerms: 'Net 15',
        paymentDescription: 'Payments due within 15 days from the invoice date.'
      }
    },
    {
      id: '3',
      name: 'Acme Corp',
      email: 'contact@acmecorp.com',
      status: 'active',
      createdAt: '2022-11-05T08:15:00Z',
      company: 'Acme Corporation',
      description: 'Leading provider of industrial tools and machinery.',
      shipping_info: {
        addressLine1: '789 Maple Drive',
        addressLine2: 'Warehouse 5',
        town: 'Metropolis',
        state: 'NY',
        pincode: '10001',
        originCountry: 'USA',
        useAsBillingAddress: false
      },
      billing_info: {
        tinNumber: '321-654-987',
        accountNumber: '0123456789',
        paymentTerms: 'Net 45',
        paymentDescription: 'Payments due within 45 days from the invoice date.'
      }
    },
    {
      id: '4',
      name: 'Tech Solutions',
      email: 'support@techsolutions.com',
      status: 'pending',
      createdAt: '2023-03-10T14:00:00Z',
      company: 'Tech Solutions Inc.',
      description: 'Innovative technology solutions for modern businesses.',
      shipping_info: {
        addressLine1: '101 Pine Lane',
        addressLine2: 'Tech Park',
        town: 'Techville',
        state: 'CA',
        pincode: '94016',
        originCountry: 'USA',
        useAsBillingAddress: true
      },
      billing_info: {
        tinNumber: '654-321-987',
        accountNumber: '2345678901',
        paymentTerms: 'Net 60',
        paymentDescription: 'Payments due within 60 days from the invoice date.'
      }
    },
    {
      id: '5',
      name: 'Global Traders',
      email: 'info@globaltraders.com',
      status: 'suspended',
      createdAt: '2023-04-01T16:30:00Z',
      company: 'Global Traders Ltd.',
      description: 'Global trading company specializing in bulk commodities.',
      shipping_info: {
        addressLine1: '202 Cedar Street',
        addressLine2: '',
        town: 'Commerce City',
        state: 'TX',
        pincode: '77001',
        originCountry: 'USA',
        useAsBillingAddress: false
      },
      billing_info: {
        tinNumber: '789-012-345',
        accountNumber: '3456789012',
        paymentTerms: 'Net 30',
        paymentDescription: 'Payments due within 30 days from the invoice date.'
      }
    },
    {
      id: '6',
      name: 'Quality Goods',
      email: 'sales@qualitygoods.com',
      status: 'active',
      createdAt: '2023-05-22T09:50:00Z',
      company: 'Quality Goods LLC',
      description: 'Distributor of premium household and lifestyle products.',
      shipping_info: {
        addressLine1: '303 Birch Boulevard',
        addressLine2: 'Unit 12',
        town: 'Goodsborough',
        state: 'FL',
        pincode: '33101',
        originCountry: 'USA',
        useAsBillingAddress: true
      },
      billing_info: {
        tinNumber: '123-987-654',
        accountNumber: '4567890123',
        paymentTerms: 'Net 30',
        paymentDescription: 'Payments due within 30 days from the invoice date.'
      }
    },
    {
      id: '7',
      name: 'Service Pro',
      email: 'contact@servicepro.com',
      status: 'inactive',
      createdAt: '2022-10-30T11:20:00Z',
      company: 'Service Professionals',
      description: 'Expert services in maintenance and repair.',
      shipping_info: {
        addressLine1: '404 Redwood Road',
        addressLine2: 'Suite A',
        town: 'Servicetown',
        state: 'OH',
        pincode: '43215',
        originCountry: 'USA',
        useAsBillingAddress: false
      },
      billing_info: {
        tinNumber: '456-123-789',
        accountNumber: '5678901234',
        paymentTerms: 'Net 15',
        paymentDescription: 'Payments due within 15 days from the invoice date.'
      }
    },
    {
      id: '8',
      name: 'Innovative Solutions',
      email: 'hello@innovativesolutions.com',
      status: 'active',
      createdAt: '2023-06-15T13:40:00Z',
      company: 'Innovative Solutions Ltd.',
      description: 'Cutting-edge solutions for a digital world.',
      shipping_info: {
        addressLine1: '505 Ash Lane',
        addressLine2: 'Office 3',
        town: 'Innovation City',
        state: 'WA',
        pincode: '98001',
        originCountry: 'USA',
        useAsBillingAddress: true
      },
      billing_info: {
        tinNumber: '321-789-456',
        accountNumber: '6789012345',
        paymentTerms: 'Net 45',
        paymentDescription: 'Payments due within 45 days from the invoice date.'
      }
    },
    {
      id: '9',
      name: 'Prime Supplies',
      email: 'orders@primesupplies.com',
      status: 'active',
      createdAt: '2023-07-07T15:55:00Z',
      company: 'Prime Supplies Inc.',
      description: 'Supplier of essential office and industrial supplies.',
      shipping_info: {
        addressLine1: '606 Poplar Place',
        addressLine2: 'Suite 10',
        town: 'Supplyville',
        state: 'NJ',
        pincode: '07001',
        originCountry: 'USA',
        useAsBillingAddress: false
      },
      billing_info: {
        tinNumber: '987-321-654',
        accountNumber: '7890123456',
        paymentTerms: 'Net 30',
        paymentDescription: 'Payments due within 30 days from the invoice date.'
      }
    },
    {
      id: '10',
      name: 'Elite Products',
      email: 'contact@eliteproducts.com',
      status: 'pending',
      createdAt: '2023-08-12T07:05:00Z',
      company: 'Elite Products Corp.',
      description: 'High-end products for discerning customers.',
      shipping_info: {
        addressLine1: '707 Willow Way',
        addressLine2: 'Floor 4',
        town: 'Elite Town',
        state: 'OR',
        pincode: '97201',
        originCountry: 'USA',
        useAsBillingAddress: true
      },
      billing_info: {
        tinNumber: '654-987-321',
        accountNumber: '8901234567',
        paymentTerms: 'Net 60',
        paymentDescription: 'Payments due within 60 days from the invoice date.'
      }
    }
  ];
  

  return (
    <>
      <div className='card'>
        <div className='card-header'>
          <div className='card-title'>
            <input
              className='form-control input-sm '
              type='search'
              placeholder='Search By Name or Email'
              aria-label='Search'
              onChange={(ev) => {
                setSearchString(ev.target.value)
              }}
              onKeyDown={(ev) => {
                if (ev.key == 'Enter') {
                  setPaginationData((pre) => {
                    return {...pre, search: searchString, page: 1}
                  })
                }
              }}
              value={searchString}
              style={{minWidth: '180px'}}
            />
          </div>
          <div className='card-toolbar'>
            <button
              className='btn btn-sm btn-primary min-w-100px fw-bold'
              onClick={() => setShowAddVendorModal(true)}
            >
              Add Customer
            </button>
          </div>
        </div>
        <div className='card-body p-2'>
          <DataTable
            fixedHeader
            columns={columns}
            data={[...vendors]}
            responsive
            pagination
            paginationServer
            paginationPerPage={10}
            customStyles={{
              table: {
                style: {
                  height: '58vh',
                },
              },
              headCells: {
                style: {fontWeight: 'bold', fontSize: '14px'},
              },
              cells: {
                style: {
                  fontWeight: '500',
                },
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

export default Vendors
