import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { cancelSubscriptionAsync, getApproveSubscriptionAsync, getUserPlanAsync, requestSubscriptionAsync, updateSubscriptionAsync } from '../actions/pricingActions';
import { CancelSubscriptionResponse, SubscriptionResponse, UserSubscriptionPlan } from 'src/interfaces/Platform.interface';

interface InitialState {
  requestSubscriptionLoading: boolean;
  data: SubscriptionResponse | null;
  error: string | null;
  changeSubscriptionLoading:boolean;
  cancelSubscriptionLoading:boolean;
  dataCancel:CancelSubscriptionResponse|null;
  userCurrentSubscription: UserSubscriptionPlan|null;
  
}

const initialState: InitialState = {
  requestSubscriptionLoading: false,
  data: null,
  error: null,
  changeSubscriptionLoading:false,
  cancelSubscriptionLoading:false,
  dataCancel:null,
  userCurrentSubscription:null,
};

const pricingSlice = createSlice({
  name: 'pricingSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(requestSubscriptionAsync.pending), (state) => {
      console.log('requestSubscriptionAsync is in pending state');
      state.requestSubscriptionLoading = true;
      state.error = null; // Clear previous errors
    });
    builder.addMatcher(isAnyOf(requestSubscriptionAsync.rejected), (state, action) => {
      console.log('requestSubscriptionAsync is in rejected state');
      state.requestSubscriptionLoading = false;
      state.error = action.error.message || 'An error occurred'; // Set error message
    });
    builder.addMatcher(isAnyOf(requestSubscriptionAsync.fulfilled), (state, action) => {
      console.log('requestSubscriptionAsync is in fulfilled state', action.payload);
      state.requestSubscriptionLoading = false;
      state.data = action.payload; // Store response data
      state.error = null; // Clear previous errors
    });

    //change plan updateSubscriptionAsync

    builder.addMatcher(isAnyOf(updateSubscriptionAsync.pending), (state) => {
        console.log('updateSubscriptionAsync is in pending state');
        state.changeSubscriptionLoading = true;
        state.error = null; // Clear previous errors
      });
      builder.addMatcher(isAnyOf(updateSubscriptionAsync.rejected), (state, action) => {
        console.log('updateSubscriptionAsync is in rejected state');
        state.changeSubscriptionLoading = false;
        state.error = action.error.message || 'An error occurred'; // Set error message
      });
      builder.addMatcher(isAnyOf(updateSubscriptionAsync.fulfilled), (state, action) => {
        console.log('updateSubscriptionAsync is in fulfilled state', action.payload);
        state.changeSubscriptionLoading = false;
        state.data = action.payload; // Store response data
        state.error = null; // Clear previous errors
      });

      //cancel cancelSubscriptionAsync
      builder.addMatcher(isAnyOf(cancelSubscriptionAsync.pending), (state) => {
        console.log('cancelSubscriptionAsync is in pending state');
        state.cancelSubscriptionLoading = true;
        state.error = null; // Clear previous errors
      });
      builder.addMatcher(isAnyOf(cancelSubscriptionAsync.rejected), (state, action) => {
        console.log('cancelSubscriptionAsync is in rejected state');
        state.cancelSubscriptionLoading = false;
        state.error = action.error.message || 'An error occurred'; // Set error message
      });
      builder.addMatcher(isAnyOf(cancelSubscriptionAsync.fulfilled), (state, action) => {
        console.log('cancelSubscriptionAsync is in fulfilled state', action.payload);
        state.cancelSubscriptionLoading = false;
        state.dataCancel = action.payload; // Store response data
        state.error = null; // Clear previous errors
      });

      //approval  getApproveSubscriptionAsync
      builder.addMatcher(isAnyOf(getApproveSubscriptionAsync.pending), (state) => {
        console.log('getApproveSubscriptionAsync is in pending state');
        state.cancelSubscriptionLoading = true;
        state.error = null; // Clear previous errors
      });
      builder.addMatcher(isAnyOf(getApproveSubscriptionAsync.rejected), (state, action) => {
        console.log('getApproveSubscriptionAsync is in rejected state');
        state.cancelSubscriptionLoading = false;
        state.error = action.error.message || 'An error occurred'; // Set error message
      });
      builder.addMatcher(isAnyOf(getApproveSubscriptionAsync.fulfilled), (state, action) => {
        console.log('getApproveSubscriptionAsync is in fulfilled state', action.payload);
        state.cancelSubscriptionLoading = false;
        state.dataCancel = action.payload; // Store response data
        state.error = null; // Clear previous errors
      });

      builder.addMatcher(isAnyOf(getUserPlanAsync.pending), (state) => {
        console.log('requestSubscriptionAsync is in pending state');
        state.requestSubscriptionLoading = true;
      });
      builder.addMatcher(isAnyOf(getUserPlanAsync.rejected), (state, action) => {
        console.log('requestSubscriptionAsync is in rejected state');
        state.requestSubscriptionLoading = false;
      });
      builder.addMatcher(isAnyOf(getUserPlanAsync.fulfilled), (state, action) => {
        console.log('get user plan', action.payload);
        
        state.userCurrentSubscription = action.payload; // Store response data
        state.requestSubscriptionLoading = false;
      });


  },
  reducers: {},
});

export default pricingSlice.reducer;