import React, { FC, useState } from 'react'
import { MoreFieldMappingItem, MoreMapping_Item_On_Get } from 'src/interfaces/Template.interface'
import TemplateListItemcopy from './TemplateListItemcopy'
import { Button, Spinner } from 'react-bootstrap'
import { useAppDispatch } from 'src/redux/storeHooks'
import { deleteMoreMappingAsync, getMoreMappingAsync } from 'src/redux/actions/templateAndPricingActions'
import { toast } from 'react-toastify'
import CommonAlertModal from 'src/utils/modals/CommonAlertModal'
import AddNewMoreMappingTemplateModal from './AddNewMoreMappingTemplateModal'
type MoreMappingItemPropType={
    item:MoreFieldMappingItem
    selectedPlatform:any
}
const MoreMappingItem : FC <MoreMappingItemPropType>= ({item,selectedPlatform}) => {
    const [getMoreMappingData, setGetMoreMappingData] = useState<MoreMapping_Item_On_Get[] | []>([])
    const [getShowMoreMappingData, setGetShowMoreMappingData] = useState(false)
    const [showMoreMappingTemplateModal, setShowMoreMappingTemplateModal] = useState<boolean>(false)
    const [updatingMoreMappingData, setUpdatingMoreMappingData] = useState<MoreMapping_Item_On_Get | null>(
        null
      )
    const [selectedTemplateDataForDelete, setSelectedTemplateDataForDelete] = useState<null|any>()
    const [showAlertModalMoreMapping, setShowAlertModalMoreMapping] = useState<boolean>(false)
    const [showMoreMappingTemplateTable, setshowMoreMappingTemplateTable] = useState(false)
    const [selectedMappingItem, setSelectedMappingItem] = useState<MoreFieldMappingItem | null>(null)
    const [moreMappingsPagination, setMoreMappingsPagination] = useState({
        page: 1,
        limit: 5,
        count: 0,
        previous: null,
        next: null,
      })

    const dispatch = useAppDispatch()

      const getMoreMappingDataAsync = (page = 1) => {
        setshowMoreMappingTemplateTable(true)
        setGetShowMoreMappingData(true)
        console.log('selectedMappingItem..............=id        ', selectedMappingItem?.id)
        const fieldId = item.id
        if (fieldId) {
        //    alert(1)
          dispatch(
            getMoreMappingAsync({

              platform_id: selectedPlatform.id,
              field_id: fieldId,
              paginationdata: {page: page, limit: 5},
            })
          ).then((response) => {
            setGetMoreMappingData(response.payload.results)
            // setGetMoreMappingData(response.payload.results)
            console.log('setGetMoreMappingData', getMoreMappingData)
            setMoreMappingsPagination((pre) => ({
              ...pre,
              page: page,
              count: response.payload.count,
              previous: response.payload.previous,
              next: response.payload.next,
            }))
            setGetShowMoreMappingData(false)
          })
        }
      }

  return (
    <div> 
         <div className='accordion p-0 pt-2 p-sm-2' id='moreMappingAccordion'>
    <div className='accordion-item shadow'>
      <h2 className='accordion-header' id='moreMappingHeading'>
        <button
          className='accordion-button'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target={'#moreMappingCollapse' + item?.id}
          aria-expanded='false'
          aria-controls={'moreMappingCollapse' + item?.id}
          onClick={() => {
            setSelectedMappingItem(item)
            getMoreMappingDataAsync(1)

            setshowMoreMappingTemplateTable(!showMoreMappingTemplateTable)
            
          }}
        >
          {item.display_name} Field Mapping Rule
        </button>
      </h2>
      <div
        id={'moreMappingCollapse' + item?.id}
        className='accordion-collapse collapse '
        aria-labelledby='moreMappingHeading'
        data-bs-parent='#moreMappingAccordion'
      >
        <div className='accordion-body'>
          <div>
              <div className="row">
                  <div className='col-sm-6 col-12 align-self-center '>
                      <p className='fw-bold '>
                      {' '}
                      Add {item.display_name} Field Mapping
                      </p>{' '}
                  </div>
                  <div className='col-12 col-sm-6 d-flex justify-content-end pe-0 mb-3'>
                      <button
                      className='btn btn-primary btn-sm '
                      onClick={() => {
                          setUpdatingMoreMappingData(null)
                          setShowMoreMappingTemplateModal(true)
                      }}
                      >
                      + Add new
                      </button>
                  </div>
              </div>

            {showMoreMappingTemplateTable ? (
              <div>
                {getShowMoreMappingData ? (
                  <div className='d-flex align-items-center justify-content-center'>
                    <h1>
                      <Spinner animation='border' variant='primary' />
                    </h1>
                  </div>
                ) : (
                  <>
                  
                      <div>
                          <div className='card-body w-100 py-3'>
                            {/* begin::Table container */}
                            <div className='table-responsive '>
                              {/* begin::Table */}
                              <table className='table table-striped table-hover table-sm   gs-0 gy-4'>
                                {/* begin::Table head */}
                                <thead>
                                  <tr className='fw-bold text-muted  border-bottom border-bottom-2'>
                                    <th className='min-w-150px ps-4'>Rule Name</th>
                                    <th className='min-w-100px text-center'>
                                      Actions
                                    </th>
                                  </tr>
                                </thead>
                                {/* end::Table head */}
                                {/* begin::Table body */}
                                <tbody>
                                  {getMoreMappingData?.length
                                    ? getMoreMappingData.map((templateData) => (
                                        <>
                                          <TemplateListItemcopy
                                            key={templateData.id}
                                            name={templateData.name}
                                            onUpdate={() => {
                                              setShowMoreMappingTemplateModal(true)
                                              setUpdatingMoreMappingData(
                                                templateData
                                              )
                                            }}
                                            onDelete={() => {
                                              setSelectedTemplateDataForDelete(templateData)
                                              setShowAlertModalMoreMapping(true)
                                              // setGetShowMoreMappingData(true)
                                             
                                            }}
                                          />
                                        </>
                                      ))
                                    : null}
                                </tbody>
                                {/* end::Table body */}
                              </table>
                              {/* end::Table */}
                            </div>
                            {/* end::Table container */}
                          </div>
                       
                          <div className='d-flex flex-column'>
                            <div className='border-bottom my-2 border-2'></div>
                            <div className='d-flex flex-row justify-content-center mt-2'>
                              <div className=' m-3'>
                                <strong>
                                  Total {moreMappingsPagination.count} Records{' '}
                                </strong>
                              </div>
                              <Button
                                className='m-2'
                                onClick={() => {
                                  setMoreMappingsPagination((pre) => {
                                    return {
                                      ...pre,
                                      page: moreMappingsPagination.page - 1,
                                    }
                                  })
                                  getMoreMappingDataAsync(
                                    moreMappingsPagination.page - 1
                                  )
                                }}
                                disabled={moreMappingsPagination.previous == null}
                              >
                                -
                              </Button>
                              <Button
                                className='m-2'
                                // onClick={() => {
                                //   handlePaginationCategory(paginationCategoryData.page + 1)
                                //   onSendPaginationCategory(paginationCategoryData)
                                // }}
                                // disabled={next == null}
                                onClick={() => {
                                  setMoreMappingsPagination((pre) => {
                                    return {
                                      ...pre,
                                      page: moreMappingsPagination.page + 1,
                                    }
                                  })
                                  getMoreMappingDataAsync(
                                    moreMappingsPagination.page + 1
                                  )
                                }}
                                disabled={moreMappingsPagination.next == null}
                              >
                                +
                              </Button>
                            </div>
                          </div>
                      </div>
                 
                  </>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  </div>

  {showMoreMappingTemplateModal && selectedMappingItem && (
              <AddNewMoreMappingTemplateModal
                isVisible={showMoreMappingTemplateModal}
                item={selectedMappingItem}
                selectedSecondaryPlatform={selectedPlatform}
                closeModal={() => setShowMoreMappingTemplateModal(false)}
                moreMappingData={updatingMoreMappingData}
                getLatestMoreMappingDataList={() => getMoreMappingDataAsync(1)}
              />
            )}

  <CommonAlertModal
           key={'more mappping'}
           title={` do you want to continue?`}
           subtitle='Press "Ok" for continue'
          //  successBtnTitle={ loggingIn ? 'Logging in...' : 'Confirm'}
          //  successDisabled={loggingIn}
           isVisible={showAlertModalMoreMapping}
           onSuccess={()=>{
          
            dispatch(
              deleteMoreMappingAsync({
                platforn_field_template_id:
                  selectedTemplateDataForDelete.id,
              })
            )
              .then(() => {
                setGetShowMoreMappingData(false)
                getMoreMappingDataAsync(1)
                toast.success(
                  'Deleted Successfully'
                )
              })
              .catch(() => {
                setGetShowMoreMappingData(false)
              })
            setShowAlertModalMoreMapping(false)
           }}
           onCancel={()=>{
         
            setSelectedTemplateDataForDelete(null)
            setShowAlertModalMoreMapping(false)
           }}
           isTermsVisible={false}
          />

  
  
  </div>

  )
}

export default MoreMappingItem