import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {
  deleteCategoryTemplateDataAsync,
  deleteTitleDescriptionTemplateAsync,
  getPlatformCategoryTemplateDataAsync,
  getCategoriesByPlatformIdAsync,
  getPlatformChildFieldDataCategoriesAsync,
  getPlatformFieldDataAttributesAsync,
  getPlatformParentFieldDataCategoriesAsync,
  getPlatformTitleDescriptionTemplateAsync,
  getRecipesAsyncUsingPlatformId,
  getShortCodeAsync,
  postFieldDataAttributesAsync,
  savePlatformTitleDescriptionTemplateAsync,
  updateTitleDescriptionTemplateAsync,
  createMoreMappingAsync,
} from '../actions/templateAndPricingActions'
import { AttributeType, Category, PLATFORM_CATEGORY, RECIPE } from 'src/interfaces/Template.interface'

type retrivePlatformProductPayload = {
  platformTitleDescriptionTemplateData: any
  platformTitleDescriptionTemplateLoading: boolean

  getTemplateData: any[]
  loading: boolean
  getCount: number
  previous: any
  next: any

  getShortCode:string[]|null
  getShortCodeLoading:boolean

  getPlatformParentFieldCategoryDataLoading:boolean
  getPlatformParentFieldCategoryData:Category[]

  getPlatformChildFieldCategoryDataLoading:boolean
  getPlatformChildFieldCategoryData:Category[]|[]

  getAttributeData: AttributeType[]
  getAttributeDataLoading:boolean

  postAttributeDataLoading:boolean
  postAttributeData:any
  recipeLoaderForPlatform:boolean
  recipesOfPlatform:RECIPE[]|[]

  platformCategoryTemplateDataLoading:boolean
  platformCategoryTemplateData:PLATFORM_CATEGORY[]|[]
  
categoryDataLoader:boolean
platformCategories:PLATFORM_CATEGORY[]|[]

postMoreAttributeDataLoading:boolean
postMoreAttributeData:any

}

const initialState: retrivePlatformProductPayload = {
  platformTitleDescriptionTemplateData: null,
  platformTitleDescriptionTemplateLoading: false,
  getTemplateData: [],
  loading: false,
  getCount: 0,
  previous: null,
  next: null,
  getShortCode:null,
  getShortCodeLoading:false,
  getPlatformParentFieldCategoryDataLoading:false,
  getPlatformParentFieldCategoryData:[],
  getAttributeData:[],
  getAttributeDataLoading:false,
  getPlatformChildFieldCategoryDataLoading:false,
  getPlatformChildFieldCategoryData:[],
  postAttributeDataLoading:false,
  postAttributeData:null,

  recipeLoaderForPlatform:false,
  recipesOfPlatform:[],
  
  platformCategoryTemplateDataLoading:false,
  platformCategoryTemplateData:[],

  categoryDataLoader:false,
  platformCategories:[],

  postMoreAttributeDataLoading:false,
  postMoreAttributeData:[],

}

const templateAndPricingSlice = createSlice({
  name: 'templateAndPricingSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(savePlatformTitleDescriptionTemplateAsync.pending),
      (state, action) => {
        state.platformTitleDescriptionTemplateLoading = true
      }
    )
    builder.addMatcher(
      isAnyOf(savePlatformTitleDescriptionTemplateAsync.fulfilled),
      (state, action) => {
        state.platformTitleDescriptionTemplateLoading = false
        state.platformTitleDescriptionTemplateData = action?.payload
      }
    )
    builder.addMatcher(isAnyOf(savePlatformTitleDescriptionTemplateAsync.rejected), (state) => {
      state.platformTitleDescriptionTemplateLoading = false
    })

    //GET

    builder.addMatcher(
      isAnyOf(getPlatformTitleDescriptionTemplateAsync.pending),
      (state, action) => {
        state.loading = true
      }
    )
    builder.addMatcher(
      isAnyOf(getPlatformTitleDescriptionTemplateAsync.fulfilled),
      (state, action) => {
        if (action.payload?.results) {
          state.getTemplateData = action?.payload?.results
          state.getCount = action?.payload?.count
          state.previous = action.payload?.previous
          state.next = action.payload?.next
        }
        state.loading = false
      }
    )
    builder.addMatcher(isAnyOf(getPlatformTitleDescriptionTemplateAsync.rejected), (state) => {
      state.loading = false
    })

    //update

    builder.addMatcher(isAnyOf(updateTitleDescriptionTemplateAsync.pending), (state, action) => {
      state.platformTitleDescriptionTemplateLoading = true
    })
    builder.addMatcher(isAnyOf(updateTitleDescriptionTemplateAsync.fulfilled), (state, action) => {
      state.platformTitleDescriptionTemplateLoading = false
      state.platformTitleDescriptionTemplateData = action?.payload
    })
    builder.addMatcher(isAnyOf(updateTitleDescriptionTemplateAsync.rejected), (state) => {
      state.platformTitleDescriptionTemplateLoading = false
    })

    //delete

    builder.addMatcher(isAnyOf(deleteTitleDescriptionTemplateAsync.pending), (state, action) => {
      state.platformTitleDescriptionTemplateLoading = true
    })
    builder.addMatcher(isAnyOf(deleteTitleDescriptionTemplateAsync.fulfilled), (state, action) => {
      console.log('action payload delete :', action?.payload)
      state.platformTitleDescriptionTemplateLoading = false
      state.platformTitleDescriptionTemplateData =
        state.platformTitleDescriptionTemplateData?.filter(
          (platform) => platform.id !== action.payload.deletedPlatformId
        )
    })
    builder.addMatcher(isAnyOf(deleteTitleDescriptionTemplateAsync.rejected), (state) => {
      state.platformTitleDescriptionTemplateLoading = false
    })



    //GETshortcode
    builder.addMatcher(
      isAnyOf(getShortCodeAsync.pending),
      (state, action) => {
        state.getShortCodeLoading = true
      }
    )
    builder.addMatcher(
      isAnyOf(getShortCodeAsync.fulfilled),
      (state, action) => {
        state.getShortCodeLoading = false
        state.getShortCode = action?.payload
      }
    )
    builder.addMatcher(isAnyOf(getShortCodeAsync.rejected), (state) => {
      state.getShortCodeLoading = false
    })

        // getPlatformParentFieldDataCategoriesAsync
 builder.addMatcher(
  isAnyOf(getPlatformParentFieldDataCategoriesAsync.pending),
  (state, action) => {
    state.getPlatformParentFieldCategoryDataLoading = true
  }
)
builder.addMatcher(
  isAnyOf(getPlatformParentFieldDataCategoriesAsync.fulfilled),
  (state, action) => {
    state.getPlatformParentFieldCategoryDataLoading = false
    state.getPlatformParentFieldCategoryData = action?.payload
  }
)
builder.addMatcher(isAnyOf(getPlatformParentFieldDataCategoriesAsync.rejected), (state) => {
  state.getPlatformParentFieldCategoryDataLoading = false
})

//getPlatformChildFieldDataCategoriesAsync

builder.addMatcher(
  isAnyOf(getPlatformChildFieldDataCategoriesAsync.pending),
  (state, action) => {
    state.getPlatformChildFieldCategoryDataLoading = true
  }
)
// builder.addMatcher(isAnyOf(getPlatformChildFieldDataCategoriesAsync.fulfilled), (state, action) => {
//   state.getPlatformChildFieldCategoryDataLoading = false

//   let shadowArray = [...state.getPlatformChildFieldCategoryData]

//   if (shadowArray?.length) {
//     if (action?.payload?.length) {
//       console.log(state.getPlatformChildFieldCategoryData,'shadow array', shadowArray)
      
//       let processedParentData = shadowArray.find((item) => {
//         console.log(
//           item,
//           'item.id == action?.payload[0].parent_data   ',
//           item.id == action?.payload[0].parent_data
//         )
//         if (item.id == action?.payload[0].parent_data) return item
//       })

//       console.log('processedParentData--  and pindex ', processedParentData)
//       if (processedParentData) {
//         //finde the index those ussing array.findindex to sav the processedParentData
//         // const parentIndex = shadowArray.findIndex(
//         //   (item) => item.id === processedParentData.id
//         // )
//         //   shadowArray[parentIndex].child_data = action?.payload
//         //   state.getPlatformChildFieldCategoryData = shadowArray
//       } else {
//         for (let itemIndex = 0; itemIndex < shadowArray.length; itemIndex++) {
//           const element = shadowArray[itemIndex]
//           let processesParentChildData = element.child_data.find(
//             (item) => item.id === action?.payload[0].parent_data
//           )

//           if (processesParentChildData) {
//             const parentIndex = shadowArray.findIndex(
//               (item) => item.id === processesParentChildData.id
//             )

//             if (parentIndex) {
//               shadowArray[itemIndex].child_data[parentIndex] = action?.payload
//               state.getPlatformChildFieldCategoryData = shadowArray
//             }
//           }
//         }
//       }
//     }
//     console.log(
//       ' if    state.getPlatformChildFieldCategoryData---',
//       state.getPlatformChildFieldCategoryData
//     )
//   } 
//   else {
//     let tempArray = []
//     if (action?.payload?.length) {
//       tempArray = action?.payload.map((item) => {
//         return {
//           ...item,
//           child_data: [],
//         }
//       })
//     }
    
//     state.getPlatformChildFieldCategoryData = tempArray
//     console.log(tempArray,
//       ' else    state.getPlatformChildFieldCategoryData---',
//       state.getPlatformChildFieldCategoryData
//     )
//   }
// })
builder.addMatcher(isAnyOf(getPlatformChildFieldDataCategoriesAsync.fulfilled), (state, action) => {
  state.getPlatformChildFieldCategoryDataLoading = false;


  const newData = action.payload; // Assuming action.payload contains the new data from the API

  // Clone the existing state array
  let updatedCategories = [...state.getPlatformChildFieldCategoryData];

  // If the array is empty or new data is empty, initialize or update the state
  if (!updatedCategories.length || !newData.length) {
    state.getPlatformChildFieldCategoryData = newData.map(item => ({ ...item, child_data: [] }));
  } else {
    // Iterate through the new data
    newData.forEach(newItem => {
      // Find if the parent data already exists in the state
      const existingParent = updatedCategories.find(item => item.id === newItem.parent_data);

      if (existingParent) {
        // If parent data exists, update its child_data with the new data
        existingParent.child_data = newData;
      } else {
        // If parent data doesn't exist, add it to the state


        
        updatedCategories.push({ ...newItem, child_data: [] });
      }
    });

    // Update the state with the modified array
    state.getPlatformChildFieldCategoryData = updatedCategories;
  }

  // Log the updated state
  console.log('Updated state:', state.getPlatformChildFieldCategoryData);
  // console.log(JSON.stringify(state.getPlatformChildFieldCategoryData))
});

// builder.addMatcher(isAnyOf(getPlatformChildFieldDataCategoriesAsync.fulfilled), (state, action) => {
//   state.getPlatformChildFieldCategoryDataLoading = false;

//   if (state.getPlatformChildFieldCategoryData?.length && action?.payload?.length) {
//     const filteredChildCategories = action.payload.filter(
//       (childCategory) => childCategory.parent_data === action?.payload[0].parent_data
//     );

//     const parentIndex = state.getPlatformChildFieldCategoryData.findIndex(
//       (item) => item.id === action?.payload[0].parent_data
//     );

//     if (parentIndex >= 0) {
//       state.getPlatformChildFieldCategoryData[parentIndex].child_data = filteredChildCategories;
//     } else {
//       // Consider handling non-existing parent here (optional)
//     }
//   } else {
//     state.getPlatformChildFieldCategoryData = action?.payload;
//   }
// });


builder.addMatcher(isAnyOf(getPlatformChildFieldDataCategoriesAsync.rejected), (state) => {
  state.getPlatformChildFieldCategoryDataLoading = false
})




//attribute
builder.addMatcher(
  isAnyOf(getPlatformFieldDataAttributesAsync.pending),
  (state, action) => {
    state.getAttributeDataLoading = true
  }
)
builder.addMatcher(
  isAnyOf(getPlatformFieldDataAttributesAsync.fulfilled),
  (state, action) => {
    state.getAttributeDataLoading = false
    state.getAttributeData = action?.payload
  }
)
builder.addMatcher(isAnyOf(getPlatformFieldDataAttributesAsync.rejected), (state) => {
  state.getAttributeDataLoading = false
})

// get recipes using platform id 
builder.addMatcher(
  isAnyOf(getRecipesAsyncUsingPlatformId.pending),
  (state, action) => {
    state.recipeLoaderForPlatform = true
  }
)
builder.addMatcher(
  isAnyOf(getRecipesAsyncUsingPlatformId.fulfilled),
  (state, action) => {
    state.recipeLoaderForPlatform = false
    state.recipesOfPlatform = action.payload.results
    console.log("recipes of platform---", action.payload);
    
  }
)
builder.addMatcher(isAnyOf(getRecipesAsyncUsingPlatformId.rejected), (state) => {
  state.recipeLoaderForPlatform = false
})
// get recipes using platform id 


// get categories using platform id 
builder.addMatcher(
  isAnyOf(getCategoriesByPlatformIdAsync.pending),
  (state, action) => {
    state.categoryDataLoader = true
  }
)
builder.addMatcher(
  isAnyOf(getCategoriesByPlatformIdAsync.fulfilled),
  (state, action) => {
    state.categoryDataLoader = false
    state.platformCategories = action.payload?.results
    console.log("recipes of platform---", action.payload);
    
  }
)
builder.addMatcher(isAnyOf(getCategoriesByPlatformIdAsync.rejected), (state) => {
  state.categoryDataLoader = false
})
// get categories using platform id 





//post attributes postFieldDataAttributesAsync
builder.addMatcher(
  isAnyOf(postFieldDataAttributesAsync.pending),
  (state, action) => {
    state.postAttributeDataLoading = true
  }
)
builder.addMatcher(
  isAnyOf(postFieldDataAttributesAsync.fulfilled),
  (state, action) => {
    state.postAttributeDataLoading = false
    state.postAttributeData = action?.payload
  }
)
builder.addMatcher(isAnyOf(postFieldDataAttributesAsync.rejected), (state) => {
  state.postAttributeDataLoading = false
})


//get getPlatformCategoryTemplateDataAsync
builder.addMatcher(
  isAnyOf(getPlatformCategoryTemplateDataAsync.pending),
  (state, action) => {
    state.platformCategoryTemplateDataLoading = true
  }
)
builder.addMatcher(
  isAnyOf(getPlatformCategoryTemplateDataAsync.fulfilled),
  (state, action) => {
    state.platformCategoryTemplateDataLoading = false
    state.platformCategoryTemplateData = action?.payload?.results
  }
)
builder.addMatcher(isAnyOf(getPlatformCategoryTemplateDataAsync.rejected), (state) => {
  state.platformCategoryTemplateDataLoading = false
})

//delete platform category template data

// builder.addMatcher(
//   isAnyOf(deleteCategoryTemplateDataAsync.fulfilled),
//   (state, action) => {
   
//         // Check if action.payload exists and has the deletedPlatformId property
//         if (action.payload) {
//           state.platformCategoryTemplateData = state.platformCategoryTemplateData.filter(
//             (platform) => platform?.attributes !== action.payload.deletedPlatformId
//           );
//         }
//   }
// )

//more mapping data slice 
builder.addMatcher(
  isAnyOf(createMoreMappingAsync.pending),
  (state, action) => {
    state.postMoreAttributeDataLoading = true
  }
)
builder.addMatcher(
  isAnyOf(createMoreMappingAsync.fulfilled),
  (state, action) => {
    state.postMoreAttributeDataLoading = false
    state.postMoreAttributeData = action?.payload
  }
)
builder.addMatcher(isAnyOf(createMoreMappingAsync.rejected), (state) => {
  state.postMoreAttributeDataLoading = false
})





  },
  reducers: {},
})

export const {} = templateAndPricingSlice.actions
export default templateAndPricingSlice.reducer
