import React, { FC } from 'react'
import FilterToolbar from './FilterToolbar'
import CardToolBar from './CardToolBar'
import AddCustomerModal from './AddCustomerModal'
import ExportModal from './ExportModal'

const ManageCustomerscopy :FC= () => {
  return (
    <div>
      <div className='d-flex flex-column flex-column-fluid'>
        {/*<!--begin::Toolbar-->  */}
        <div id='kt_app_toolbar' className='app-toolbar  py-3 py-lg-6 '>
          {/*<!--begin::Toolbar container-->  */}
          <div
            id='kt_app_toolbar_container'
            className='app-container  container-xxl d-flex flex-stack '
          >
            {/*<!--begin::Page title-->  */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 '>
              {/*<!--begin::Title-->  */}
              <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
                Manage Customers
              </h1>
              {/*<!--end::Title-->  */}

              {/*<!--begin::Breadcrumb-->  */}
              {/* <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
       
                <li className='breadcrumb-item text-muted'>
                  <a href='/metronic8/demo1/index.html' className='text-muted text-hover-primary'>
                    Home{' '}
                  </a>
                </li>

                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-500 w-5px h-2px'></span>
                </li>
            
                <li className='breadcrumb-item text-muted'>Customers </li>
          
              </ul> */}
              {/*<!--end::Breadcrumb-->  */}
            </div>
            {/*<!--end::Page title-->  */}
            {/*<!--begin::Actions-->  */}

            {/* <FilterToolbar/> */}

            {/*<!--end::Actions-->  */}
          </div>
          {/*<!--end::Toolbar container-->  */}
        </div>
        {/*<!--end::Toolbar-->  */}

        {/*<!--begin::Content-->  */}
        <div id='kt_app_content' className='app-content  flex-column-fluid '>
          {/*<!--begin::Content container-->  */}
          <div id='kt_app_content_container' className='app-container  container-xxl '>
            {/*<!--begin::Card-->  */}
            <div className='card'>
              {/*<!--begin::Card header-->  */}
              <div className='card-header border-0 pt-6'>
                {/*<!--begin::Card title-->  */}
                <div className='card-title'>
                  {/*<!--begin::Search-->  */}
                  <div className='d-flex align-items-center position-relative my-1'>
                    <i className='ki-duotone ki-magnifier fs-3 position-absolute ms-1'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>{' '}
                    <input
                      type='text'
                      data-kt-customer-table-filter='search'
                      className='form-control form-control-solid w-250px ps-12'
                      placeholder='Search Customers'
                    />
                  </div>
                  {/*<!--end::Search-->  */}
                </div>
                {/*<!--begin::Card title-->  */}

                {/*<!--begin::Card toolbar-->  */}

                <CardToolBar />
                {/*<!--end::Card toolbar-->  */}
              </div>
              {/*<!--end::Card header-->  */}

              {/*<!--begin::Card body-->  */}
              <div className='card-body pt-0'>
                {/*<!--begin::Table-->  */}
                <div
                  id='kt_customers_table_wrapper'
                  className='dt-container dt-bootstrap5 dt-empty-footer'
                >
                  <div id='' className='table-responsive'>
                    <table
                      className='table align-middle table-row-dashed fs-6 gy-5 dataTable'
                      id='kt_customers_table'
                      style={{width: ' 996.136px'}}
                    >
                      <colgroup>
                        {/* <col style={{width: ' 36.3977px'}} /> */}
                        <col style={{width: ' 138.784px'}} />
                        <col style={{width: ' 172.25px'}} />
                        <col style={{width: ' 198.557px'}} />
                        {/* <col style={{width: ' 146.42px'}} /> */}
                        <col style={{width: ' 186.955px'}} />
                        <col style={{width: ' 116.773px'}} />
                      </colgroup>
                      <thead>
                        <tr
                          className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'
                          role='row'
                        >
                          {/* <th
                            className='w-10px pe-2 dt-orderable-none'
                            data-dt-column={0}
                            rowSpan={1}
                            colSpan={1}
                            aria-label='
                
                    
                
            '
                          >
                            <span className='dt-column-title'>
                              <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  data-kt-check='true'
                                  data-kt-check-target='#kt_customers_table .form-check-input'
                                  value={1}
                                />
                              </div>
                            </span>
                            <span className='dt-column-order'></span>
                          </th> */}
                          <th
                            className='min-w-125px dt-orderable-asc dt-orderable-desc'
                            data-dt-column={1}
                            rowSpan={1}
                            colSpan={1}
                            aria-label='Customer Name: Activate to sort'
                            tabIndex={0}
                          >
                            <span className='dt-column-title' role='button'>
                              Customer Name
                            </span>
                            <span className='dt-column-order'></span>
                          </th>
                          <th
                            className='min-w-125px dt-orderable-asc dt-orderable-desc'
                            data-dt-column='2'
                            rowSpan={1}
                            colSpan={1}
                            aria-label='Email: Activate to sort'
                            tabIndex={0}
                          >
                            <span className='dt-column-title' role='button'>
                              Email
                            </span>
                            <span className='dt-column-order'></span>
                          </th>
                          <th
                            className='min-w-125px dt-orderable-asc dt-orderable-desc'
                            data-dt-column='3'
                            rowSpan={1}
                            colSpan={1}
                            aria-label='Company: Activate to sort'
                            tabIndex={0}
                          >
                            <span className='dt-column-title' role='button'>
                              Last Login Time
                            </span>
                            <span className='dt-column-order'></span>
                          </th>
                          {/* <th
                            className='min-w-125px dt-orderable-asc dt-orderable-desc'
                            data-dt-column='4'
                            rowSpan={1}
                            colSpan={1}
                            aria-label='Payment Method: Activate to sort'
                            tabIndex={0}
                          >
                            <span className='dt-column-title' role='button'>
                              Payment Method
                            </span>
                            <span className='dt-column-order'></span>
                          </th> */}
                          <th
                            className='min-w-125px dt-orderable-asc dt-orderable-desc'
                            data-dt-column='5'
                            rowSpan={1}
                            colSpan={1}
                            aria-label='Created Date: Activate to sort'
                            tabIndex={0}
                          >
                            <span className='dt-column-title' role='button'>
                              SignUp Date and Time
                            </span>
                            <span className='dt-column-order'></span>
                          </th>
                          <th
                            className='text-end min-w-70px dt-orderable-none'
                            data-dt-column='6'
                            rowSpan={1}
                            colSpan={1}
                            aria-label='Actions'
                          >
                            <span className='dt-column-title'>Actions</span>
                            <span className='dt-column-order'></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='fw-semibold text-gray-600'>
                        <tr>
                          {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' value={1} />
                            </div>
                          </td> */}
                          <td>
                            <a
                              href='/metronic8/demo1/apps/customers/view.html'
                              className='text-gray-800 text-hover-primary mb-1'
                            >
                              Emma Smith
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                              smith@kpmg.com
                            </a>
                          </td>
                          <td>18 May 20204, 3:34 pm</td>
                          {/* <td data-filter='mastercard'>
                            <img
                              src='/metronic8/demo1/assets/media/svg/card-logos/mastercard.svg'
                              className='w-35px me-3'
                              alt=''
                            />
                            **** 7319{' '}
                          </td> */}
                          <td data-order='2020-12-14T20:43:00+05:30'>14 Dec 2020, 8:43 pm </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              Actions
                              <i className='ki-duotone ki-down fs-5 ms-1'></i>
                            </a>
                            {/*<!--begin::Menu-->  */}
                            <div
                              className='menu menu-sub  menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                >
                                  View
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}

                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-kt-customer-table-filter='delete_row'
                                >
                                  Deactivate
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}

                              <div className='menu-item  px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                >
                                  Login
                                </a>
                              </div>

                              <div className='menu-item px-3'>
                                <a
                                  href='admin-reports'
                                  className='menu-link px-3'
                                >
                                  Report
                                </a>
                              </div>
                            </div>
                            {/*<!--end::Menu-->  */}
                          </td>
                        </tr>
                        <tr>
                          {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' value={1} />
                            </div>
                          </td> */}
                          <td>
                            <a
                              href='/metronic8/demo1/apps/customers/view.html'
                              className='text-gray-800 text-hover-primary mb-1'
                            >
                              Melody Macy
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                              melody@altbox.com
                            </a>
                          </td>
                          <td>18 May 20204, 3:34 pm </td>
                          {/* <td data-filter='visa'>
                            <img
                              src='/metronic8/demo1/assets/media/svg/card-logos/visa.svg'
                              className='w-35px me-3'
                              alt=''
                            />
                            **** 1244{' '}
                          </td> */}
                          <td data-order='2020-12-01T10:12:00+05:30'>01 Dec 2020, 10:12 am </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              Actions
                              <i className='ki-duotone ki-down fs-5 ms-1'></i>
                            </a>
                            {/*<!--begin::Menu-->  */}
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='/metronic8/demo1/apps/customers/view.html'
                                  className='menu-link px-3'
                                >
                                  View
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}

                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-kt-customer-table-filter='delete_row'
                                >
                                  Delete
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}
                            </div>
                            {/*<!--end::Menu-->  */}
                          </td>
                        </tr>
                        <tr>
                          {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' value={1} />
                            </div>
                          </td> */}
                          <td>
                            <a
                              href='/metronic8/demo1/apps/customers/view.html'
                              className='text-gray-800 text-hover-primary mb-1'
                            >
                              Max Smith
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                              max@kt.com
                            </a>
                          </td>
                          <td>18 May 20204, 3:34 pm</td>
                          {/* <td data-filter='mastercard'>
                            <img
                              src='/metronic8/demo1/assets/media/svg/card-logos/mastercard.svg'
                              className='w-35px me-3'
                              alt=''
                            />
                            **** 9233{' '}
                          </td> */}
                          <td data-order='2020-11-12T14:01:00+05:30'>12 Nov 2020, 2:01 pm </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              Actions
                              <i className='ki-duotone ki-down fs-5 ms-1'></i>
                            </a>
                            {/*<!--begin::Menu-->  */}
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='/metronic8/demo1/apps/customers/view.html'
                                  className='menu-link px-3'
                                >
                                  View
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}

                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-kt-customer-table-filter='delete_row'
                                >
                                  Delete
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}
                            </div>
                            {/*<!--end::Menu-->  */}
                          </td>
                        </tr>
                        <tr>
                          {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' value={1} />
                            </div>
                          </td> */}
                          <td>
                            <a
                              href='/metronic8/demo1/apps/customers/view.html'
                              className='text-gray-800 text-hover-primary mb-1'
                            >
                              Sean Bean
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                              sean@dellito.com
                            </a>
                          </td>
                          <td>18 May 20204, 3:34 pm </td>
                          {/* <td data-filter='american_express'>
                            <img
                              src='/metronic8/demo1/assets/media/svg/card-logos/american-express.svg'
                              className='w-35px me-3'
                              alt=''
                            />
                            **** 9190{' '}
                          </td> */}
                          <td data-order='2020-10-21T17:54:00+05:30'>21 Oct 2020, 5:54 pm </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              Actions
                              <i className='ki-duotone ki-down fs-5 ms-1'></i>
                            </a>
                            {/*<!--begin::Menu-->  */}
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='/metronic8/demo1/apps/customers/view.html'
                                  className='menu-link px-3'
                                >
                                  View
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}

                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-kt-customer-table-filter='delete_row'
                                >
                                  Delete
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}
                            </div>
                            {/*<!--end::Menu-->  */}
                          </td>
                        </tr>
                        <tr>
                          {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' value={1} />
                            </div>
                          </td> */}
                          <td>
                            <a
                              href='/metronic8/demo1/apps/customers/view.html'
                              className='text-gray-800 text-hover-primary mb-1'
                            >
                              Brian Cox
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                              brian@exchange.com
                            </a>
                          </td>
                          <td>18 May 20204, 3:34 pm </td>
                          {/* <td data-filter='visa'>
                            <img
                              src='/metronic8/demo1/assets/media/svg/card-logos/visa.svg'
                              className='w-35px me-3'
                              alt=''
                            />
                            **** 4895{' '}
                          </td> */}
                          <td data-order='2020-10-19T07:32:00+05:30'>19 Oct 2020, 7:32 am </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              Actions
                              <i className='ki-duotone ki-down fs-5 ms-1'></i>
                            </a>
                            {/*<!--begin::Menu-->  */}
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='/metronic8/demo1/apps/customers/view.html'
                                  className='menu-link px-3'
                                >
                                  View
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}

                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-kt-customer-table-filter='delete_row'
                                >
                                  Delete
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}
                            </div>
                            {/*<!--end::Menu-->  */}
                          </td>
                        </tr>
                        <tr>
                          {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' value={1} />
                            </div>
                          </td> */}
                          <td>
                            <a
                              href='/metronic8/demo1/apps/customers/view.html'
                              className='text-gray-800 text-hover-primary mb-1'
                            >
                              Mikaela Collins
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                              mik@pex.com
                            </a>
                          </td>
                          <td>18 May 20204, 3:34 pm </td>
                          {/* <td data-filter='american_express'>
                            <img
                              src='/metronic8/demo1/assets/media/svg/card-logos/american-express.svg'
                              className='w-35px me-3'
                              alt=''
                            />
                            **** 1482{' '}
                          </td> */}
                          <td data-order='2020-09-23T00:37:00+05:30'>23 Sep 2020, 12:37 am </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              Actions
                              <i className='ki-duotone ki-down fs-5 ms-1'></i>
                            </a>
                            {/*<!--begin::Menu-->  */}
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='/metronic8/demo1/apps/customers/view.html'
                                  className='menu-link px-3'
                                >
                                  View
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}

                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-kt-customer-table-filter='delete_row'
                                >
                                  Delete
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}
                            </div>
                            {/*<!--end::Menu-->  */}
                          </td>
                        </tr>
                        <tr>
                          {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' value={1} />
                            </div>
                          </td> */}
                          <td>
                            <a
                              href='/metronic8/demo1/apps/customers/view.html'
                              className='text-gray-800 text-hover-primary mb-1'
                            >
                              Francis Mitcham
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                              f.mit@kpmg.com
                            </a>
                          </td>
                          <td>18 May 20204, 3:34 pm </td>
                          {/* <td data-filter='mastercard'>
                            <img
                              src='/metronic8/demo1/assets/media/svg/card-logos/mastercard.svg'
                              className='w-35px me-3'
                              alt=''
                            />
                            **** 9763{' '}
                          </td> */}
                          <td data-order='2020-09-11T15:15:00+05:30'>11 Sep 2020, 3:15 pm </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              Actions
                              <i className='ki-duotone ki-down fs-5 ms-1'></i>
                            </a>
                            {/*<!--begin::Menu-->  */}
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='/metronic8/demo1/apps/customers/view.html'
                                  className='menu-link px-3'
                                >
                                  View
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}

                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-kt-customer-table-filter='delete_row'
                                >
                                  Delete
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}
                            </div>
                            {/*<!--end::Menu-->  */}
                          </td>
                        </tr>
                        <tr>
                          {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' value={1} />
                            </div>
                          </td> */}
                          <td>
                            <a
                              href='/metronic8/demo1/apps/customers/view.html'
                              className='text-gray-800 text-hover-primary mb-1'
                            >
                              Olivia Wild
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                              olivia@corpmail.com
                            </a>
                          </td>
                          <td>18 May 20204, 3:34 pm </td>
                          {/* <td data-filter='american_express'>
                            <img
                              src='/metronic8/demo1/assets/media/svg/card-logos/american-express.svg'
                              className='w-35px me-3'
                              alt=''
                            />
                            **** 6182{' '}
                          </td> */}
                          <td data-order='2020-09-03T01:08:00+05:30'>03 Sep 2020, 1:08 am </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              Actions
                              <i className='ki-duotone ki-down fs-5 ms-1'></i>
                            </a>
                            {/*<!--begin::Menu-->  */}
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='/metronic8/demo1/apps/customers/view.html'
                                  className='menu-link px-3'
                                >
                                  View
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}

                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-kt-customer-table-filter='delete_row'
                                >
                                  Delete
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}
                            </div>
                            {/*<!--end::Menu-->  */}
                          </td>
                        </tr>
                        <tr>
                          {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' value={1} />
                            </div>
                          </td> */}
                          <td>
                            <a
                              href='/metronic8/demo1/apps/customers/view.html'
                              className='text-gray-800 text-hover-primary mb-1'
                            >
                              Neil Owen
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                              owen.neil@gmail.com
                            </a>
                          </td>
                          <td>18 May 20204, 3:34 pm </td>
                          {/* <td data-filter='visa'>
                            <img
                              src='/metronic8/demo1/assets/media/svg/card-logos/visa.svg'
                              className='w-35px me-3'
                              alt=''
                            />
                            **** 4700{' '}
                          </td> */}
                          <td data-order='2020-09-01T16:58:00+05:30'>01 Sep 2020, 4:58 pm </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              Actions
                              <i className='ki-duotone ki-down fs-5 ms-1'></i>
                            </a>
                            {/*<!--begin::Menu-->  */}
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='/metronic8/demo1/apps/customers/view.html'
                                  className='menu-link px-3'
                                >
                                  View
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}

                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-kt-customer-table-filter='delete_row'
                                >
                                  Delete
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}
                            </div>
                            {/*<!--end::Menu-->  */}
                          </td>
                        </tr>
                        <tr>
                          {/* <td>
                            <div className='form-check form-check-sm form-check-custom form-check-solid'>
                              <input className='form-check-input' type='checkbox' value={1} />
                            </div>
                          </td> */}
                          <td>
                            <a
                              href='/metronic8/demo1/apps/customers/view.html'
                              className='text-gray-800 text-hover-primary mb-1'
                            >
                              Dan Wilson
                            </a>
                          </td>
                          <td>
                            <a href='#' className='text-gray-600 text-hover-primary mb-1'>
                              dam@consilting.com
                            </a>
                          </td>
                          <td data-order='2020-08-18T15:34:00+05:30'>18 May 20204, 3:34 pm </td>
                          {/* <td data-filter='visa'>
                            <img
                              src='/metronic8/demo1/assets/media/svg/card-logos/visa.svg'
                              className='w-35px me-3'
                              alt=''
                            />
                            **** 3026{' '}
                          </td> */}
                          <td data-order='2020-08-18T15:34:00+05:30'>18 Aug 2020, 3:34 pm </td>
                          <td className='text-end'>
                            <a
                              href='#'
                              className='btn btn-sm btn-light btn-flex btn-center btn-active-light-primary'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              Actions
                              <i className='ki-duotone ki-down fs-5 ms-1'></i>
                            </a>
                            {/*<!--begin::Menu-->  */}
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='/metronic8/demo1/apps/customers/view.html'
                                  className='menu-link px-3'
                                >
                                  View
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}

                              {/*<!--begin::Menu item-->  */}
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-kt-customer-table-filter='delete_row'
                                >
                                  Delete
                                </a>
                              </div>
                              {/*<!--end::Menu item-->  */}
                            </div>
                            {/*<!--end::Menu-->  */}
                          </td>
                        </tr>
                      </tbody>
                      <tfoot></tfoot>
                    </table>
                  </div>
                  <div id='' className='row'>
                    <div
                      id=''
                      className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start dt-toolbar'
                    >
                      <div>
                        <select
                          name='kt_customers_table_length'
                          aria-controls='kt_customers_table'
                          className='form-select form-select-solid form-select-sm'
                          id='dt-length-1'
                        >
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                          <option value='100'>100</option>
                        </select>
                        <label></label>
                      </div>
                    </div>
                    <div
                      id=''
                      className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'
                    >
                      <div className='dt-paging paging_simple_numbers'>
                        <ul className='pagination'>
                          <li className='dt-paging-button page-item disabled'>
                            <a
                              className='page-link previous'
                              aria-controls='kt_customers_table'
                              aria-disabled='true'
                              aria-label='Previous'
                              data-dt-idx='previous'
                              tabIndex={-1}
                            >
                              <i className='previous'></i>
                            </a>
                          </li>
                          <li className='dt-paging-button page-item active'>
                            <a
                              href='#'
                              className='page-link'
                              aria-controls='kt_customers_table'
                              aria-current='page'
                              data-dt-idx={0}
                              tabIndex={0}
                            >
                              1
                            </a>
                          </li>
                          <li className='dt-paging-button page-item'>
                            <a
                              href='#'
                              className='page-link'
                              aria-controls='kt_customers_table'
                              data-dt-idx={1}
                              tabIndex={0}
                            >
                              2
                            </a>
                          </li>
                          <li className='dt-paging-button page-item'>
                            <a
                              href='#'
                              className='page-link'
                              aria-controls='kt_customers_table'
                              data-dt-idx='2'
                              tabIndex={0}
                            >
                              3
                            </a>
                          </li>
                          <li className='dt-paging-button page-item'>
                            <a
                              href='#'
                              className='page-link'
                              aria-controls='kt_customers_table'
                              data-dt-idx='3'
                              tabIndex={0}
                            >
                              4
                            </a>
                          </li>
                          <li className='dt-paging-button page-item'>
                            <a
                              href='#'
                              className='page-link next'
                              aria-controls='kt_customers_table'
                              aria-label='Next'
                              data-dt-idx='next'
                              tabIndex={0}
                            >
                              <i className='next'></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<!--end::Table-->  */}{' '}
              </div>
              {/*<!--end::Card body-->  */}
            </div>
            {/*<!--end::Card-->  */}
            {/*<!--begin::Modals-->  */}
            {/*<!--begin::Modal - Customers - Add-->  */}
     
     <AddCustomerModal/>

            {/*<!--end::Modal - Customers - Add-->  */}
            {/*<!--begin::Modal - Adjust Balance-->  */}
         
     <ExportModal/>
         
            {/*<!--end::Modal - New Card-->  */}
            {/*<!--end::Modals-->  */}{' '}
          </div>
          {/*<!--end::Content container-->  */}
        </div>
        {/*<!--end::Content-->  */}
      </div>
    </div>
  )
}

export default ManageCustomerscopy;                     

