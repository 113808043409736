import {ToastOptions, toast} from 'react-toastify'
const useAppToast = () => {
  const successToast = (message: string, options?:ToastOptions ) => toast.success(message, {type: 'success', ...options })
  const warningToast = (message: string) => toast.warning(message, {type: 'warning'})
  const errorToast = (message: string) => toast.error(message, {type: 'error'})

  return {successToast, warningToast, errorToast}
}

export default useAppToast
