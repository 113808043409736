import React, {useState, useRef, FC} from 'react'
import Select, {ActionMeta, MultiValue} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import {toAbsoluteUrl} from 'src/marketConnect/helpers'

import {FormikState} from 'formik'
import { InitialValuesForCreateProduct } from 'src/interfaces/createProductInterface'

interface Option {
  value: string
  label: string
}

const options: Option[] = [
  {value: 'Computers', label: 'Computers'},
  {value: 'Watches', label: 'Watches'},
  {value: 'Headphones', label: 'Headphones'},
  {value: 'Footwear', label: 'Footwear'},
  {value: 'Cameras', label: 'Cameras'},
  {value: 'Shirts', label: 'Shirts'},
  {value: 'Household', label: 'Household'},
  {value: 'Handbags', label: 'Handbags'},
  {value: 'Wines', label: 'Wines'},
  {value: 'Sandals', label: 'Sandals'},
]

interface Props {
  initialValues: {
    selectedStatus: string
    scheduledDateTime: string
    selectedOptions: Option | null
    productTemplate: string
  }
  handleChangeStatus: (selectedOption: Option) => void
  handleChangeDateTime: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleChangeSelect: (selectedOptions: Option) => void
  handleChangeTemplate: (selectedOption: Option) => void
}
type AsideColumnType = {
  values: InitialValuesForCreateProduct
  handleChange: (e: string | React.ChangeEvent<any>) => void
  setFormikState: (
    f:
      | FormikState<InitialValuesForCreateProduct>
      | ((prevState: FormikState<InitialValuesForCreateProduct>) => FormikState<InitialValuesForCreateProduct>),
    cb?: (() => void) | undefined
  ) => void
}

const AsideColumn: FC<AsideColumnType> = ({values, handleChange, setFormikState}) => {
  const [selectedStatus, setSelectedStatus] = useState('')
  const [scheduledDateTime, setScheduledDateTime] = useState('')
  const [selectedOptions, setSelectedOptions] = useState<Option[] >([])
  const [selectedValues, setSelectedValues] = useState<Option[]>([])
  const [inputValue, setInputValue] = useState('')
  const [productTemplate, setProductTemplate] = useState('')

  const [selectedTagValues, setSelectedTagValues] = useState<Option[]>([])
  const [uploadedImage, setUploadedImage] = useState<string | ArrayBuffer | null>(null)
  const handleChangeCreatable = (newValue: Option[], actionMeta: ActionMeta<Option>) => {
    // Update the Formik state using setFormikState
    setFormikState((prevState) => ({
      ...prevState,
      values: {
        ...prevState.values,
        selectedTagValues: newValue,
      },
    }))
  }

  const handleImageChange = (event) => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setUploadedImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleChangeTagValues = (selectedOptions) => {
    setSelectedTagValues(selectedOptions)
  }

  const handleCreateTagValues = (inputValue) => {
    const newOption = {value: inputValue, label: inputValue}
    setSelectedTagValues([...selectedTagValues, newOption])
  }

  const handleRemoveTag = (option: Option) => {
    setSelectedValues(selectedValues.filter((item) => item.value !== option.value))
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleSelectChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions)
  }

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value)
  }
  const handleProductTemplateChange = (selectedOption) => {
    setProductTemplate(selectedOption.value)
  }

  const handleDateTimeChange = (e) => {
    setScheduledDateTime(e.target.value)
  }
  
  const getStatusColor = () => {
    switch (values.selectedStatus) {
      case 'Published':
        return 'bg-success'
      case 'Draft':
        return 'bg-primary'
      case 'Scheduled':
        return 'bg-warning'
      case 'Inactive':
        return 'bg-danger'
      default:
        return 'bg-success' // Default color for 'published' status
    }
  }

  return (
    <div>
      {/*  <!--begin::Aside column--> */}
      <div className='d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10'>
        {/*  <!--begin::Thumbnail settings--> */}
        <div className='card card-flush py-4'>
          {/*  <!--begin::Card header--> */}
          <div className='card-header'>
            {/*  <!--begin::Card title--> */}
            <div className='card-title'>
              <h2>Thumbnail</h2>
            </div>
            {/*  <!--end::Card title--> */}
          </div>
          {/*  <!--end::Card header--> */}

          {/*  <!--begin::Card body--> */}
          <div className='card-body text-center pt-4'>
            {/* <!--begin::Image input--> */}
            {/* <!--begin::Image input placeholder--> */}
            <style>
              {`
          .image-input-placeholder {
            background-image: url('/metronic8/demo1/assets/media/svg/files/upload-dark.svg');
          }
          [data-bs-theme="dark"] .image-input-placeholder {
            background-image: url('/metronic8/demo1/assets/media/svg/files/blank-image-dark.svg');
          }
        `}
            </style>
            {/* <!--end::Image input placeholder--> */}

            <div
              className='image-input image-input-empty image-input-outline image-input-placeholder mb-3'
              data-kt-image-input='true'
              style={{backgroundImage: `url(${toAbsoluteUrl('/media/svg/files/blank-image.svg')})`}}
            >
              {/* <!--begin::Preview existing avatar--> */}
              <div
                className='image-input-wrapper w-150px h-150px'
                style={{backgroundImage: `url(${uploadedImage})`}}
              ></div>
              {/* <!--end::Preview existing avatar--> */}

              {/* <!--begin::Label--> */}
              <label
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                data-kt-image-input-action='change'
                data-bs-toggle='tooltip'
                aria-label='Change avatar'
                data-bs-original-title='Change avatar'
                data-kt-initialized='1'
              >
                <i className='ki-duotone ki-pencil fs-7'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>
                {/* <!--begin::Inputs--> */}
                <input
                  type='file'
                  name='avatar'
                  accept='.png, .jpg, .jpeg'
                  onChange={handleImageChange}
                />
                <input type='hidden' name='avatar_remove' />
                {/* <!--end::Inputs--> */}
              </label>
              {/* <!--end::Label--> */}

              {/* <!--begin::Cancel--> */}
              <button
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow' 
                onClick={() => setUploadedImage(null)}
                data-kt-image-input-action='cancel'
                data-bs-toggle='tooltip'
                aria-label='Cancel avatar'
                data-bs-original-title='Cancel avatar'
                data-kt-initialized='1'
              >
                <i className='ki-duotone ki-cross fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>{' '}
              </button>
     
              {/* <!--end::Cancel--> */}

              {/* <!--begin::Remove--> */}
              <button
                className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow' 
                onClick={() => setUploadedImage(null)}
                data-kt-image-input-action='remove'
                data-bs-toggle='tooltip'
                aria-label='Remove avatar'
                data-bs-original-title='Remove avatar'
                data-kt-initialized='1'
                style={{ display: uploadedImage ? 'inline-block' : 'none' }}
              >
                <i className='ki-duotone ki-cross fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                </i>{' '}
              </button>
              {/* <!--end::Remove--> */}
            </div>
            {/* <!--end::Image input--> */}

            {/* <!--begin::Description--> */}
            <div className='text-muted fs-7'>
              Set the product thumbnail image. Only *.png, *.jpg and *.jpeg image files are accepted
            </div>
            {/* <!--end::Description--> */}
          </div>
          {/*  <!--end::Card body--> */}
        </div>
        {/* <!--end::Thumbnail settings-->  */}
        {/*  <!--begin::Status--> */}
        <div className='card card-flush py-4'>
          {/* Card header */}
          <div className='card-header'>
            {/* Card title */}
            <div className='card-title'>
              <h2>Status</h2>
              <div className={`rounded-circle ${getStatusColor()} w-15px h-15px  ms-5`} />
            </div>
          </div>

          {/* Card body */}
          <div className='card-body pt-0'>
            {/* Select status */}
            <Select
              value={values.selectedStatus ? {value: values.selectedStatus, label: values.selectedStatus} : null}
              name='selectedStatus'
              options={[
                {value: 'Published', label: 'Published'},
                {value: 'Draft', label: 'Draft'},
                {value: 'Scheduled', label: 'Scheduled'},
                {value: 'Inactive', label: 'Inactive'},
              ]}
              onChange={(selectedOption) => {
                console.log("onchange selecyion",selectedOption)
                // Update the Formik state using setFormikState
                setFormikState((prevState) => ({
                  ...prevState,
                  values: {
                    ...prevState.values,
                    selectedStatus: selectedOption ? selectedOption.value : '',
                  },
                }))
              }}
            />

            {/* Description */}
            <div className='text-muted fs-7'>Set the product status.</div>

            {/* Date and time picker */}
            {values.selectedStatus === 'Scheduled' && (
              <div className='mt-3'>
                <label htmlFor='scheduledDateTime' className='form-label'>
                  Select publishing date and time
                </label>
                <input
                  type='datetime-local'
                  id='scheduledDateTime'
                  name='scheduledDateTime'
                  className='form-control'
                  value={values.scheduledDateTime}
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
        </div>
        {/* <!--end::Status-->  */}

        {/*<!--begin::Category & tags--> */}
        <div className='card card-flush py-4'>
          {/*  <!--begin::Card header--> */}
          <div className='card-header'>
            {/*  <!--begin::Card title--> */}
            <div className='card-title'>
              <h2>Product Details</h2>
            </div>
            {/*  <!--end::Card title--> */}
          </div>
          {/*  <!--end::Card header--> */}

          {/*  <!--begin::Card body--> */}
          <div className='card-body pt-0'>
            {/*  <!--begin::Input group--> */}
            {/*  <!--begin::Label--> */}
            <label className='form-label'>Categories</label>
            {/*  <!--end::Label--> */}

            {/*  <!--begin::Select2--> */}

            <Select
              options={options}
              isMulti
              value={values.selectedOptions}
               onChange={(items) => {
                let x = items.map((item)=>{
                 return item
                })
                 setFormikState((prevState) => ({
                   ...prevState,
                   values: {
                     ...prevState.values,
                     selectedOptions: x , // Allow null value
                   }
                 }));
               }}
              placeholder='Select an option'
            />

            {/*  <!--end::Select2--> */}

            {/*  <!--begin::Description--> */}
            <div className='text-muted fs-7 mb-7'>Add product to a category.</div>
            {/*  <!--end::Description--> */}
            {/*  <!--end::Input group--> */}

            {/*  <!--begin::Button--> */}
            <a
              href='/metronic8/demo1/apps/ecommerce/catalog/add-category.html'
              className='btn btn-light-primary btn-sm mb-10'
            >
              <i className='ki-duotone ki-plus fs-2'></i> Create new category
            </a>
            {/*  <!--end::Button--> */}

            {/*  <!--begin::Input group--> */}
            {/*  <!--begin::Label--> */}
            <label className='form-label d-block'>Tags</label>
            {/*  <!--end::Label--> */}

            {/*  <!--begin::Input--> */}

            <div className='tagify form-control mb-2'>
              <CreatableSelect
                isMulti
                options={options}
                value={values.selectedTagValues}
                onChange={(items,actionMeta)=>{
                  const {action, name} = actionMeta
                  console.log("createable output",items,actionMeta)
                  let x = items.map((item)=>{
                    return item
                   })
                    setFormikState((prevState) => ({
                      ...prevState,
                      values: {
                        ...prevState.values,
                        selectedTagValues: x , // Allow null value
                      }
                    }));

                }}
               

                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: '100%', // Set width to 100%
                  }),
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: 'transparent', // Remove background color
                    border: 'none', // Remove border
                    boxShadow: 'none', // Remove box-shadow
                  }),
                }}
              />
            </div>

            {/*  <!--end::Input--> */}

            {/*  <!--begin::Description--> */}
            <div className='text-muted fs-7'>Add tags to a product.</div>
            {/*  <!--end::Description--> */}
            {/*  <!--end::Input group--> */}
          </div>
          {/*  <!--end::Card body--> */}
        </div>
        {/* <!--end::Category & tags-->  */}
        {/*  <!--begin::Weekly sales--> */}
        <div className='card card-flush py-4'>
          {/*  <!--begin::Card header--> */}
          <div className='card-header'>
            {/*  <!--begin::Card title--> */}
            <div className='card-title'>
              <h2>Weekly Sales</h2>
            </div>
            {/*  <!--end::Card title--> */}
          </div>
          {/*  <!--end::Card header--> */}

          {/*  <!--begin::Card body--> */}
          <div className='card-body pt-0'>
            {values.weeklySales == 0 ? (
                  <h3 className='text-muted'>
                  No data available. Sales data will begin capturing once product has been published.
                </h3>
            ):(

              <h3 className='text-muted'>
              Weekly sales for the product is {values.weeklySales} for the last month.
            </h3>

            )}
        
          </div>
          {/*  <!--end::Card body--> */}
        </div>
        {/* <!--end::Weekly sales-->  */}
        {/*  <!--begin::Template settings--> */}
        <div className='card card-flush py-4'>
          {/*  <!--begin::Card header--> */}
          <div className='card-header'>
            {/*  <!--begin::Card title--> */}
            <div className='card-title'>
              <h2>Product Template</h2>
            </div>
            {/*  <!--end::Card title--> */}
          </div>
          {/*  <!--end::Card header--> */}

          {/*  <!--begin::Card body--> */}
          <div className='card-body pt-0'>
            {/*  <!--begin::Select store template--> */}
            <label htmlFor='kt_ecommerce_add_product_store_template' className='form-label'>
              Select a product template
            </label>
            {/*  <!--end::Select store template--> */}

            {/*  <!--begin::Select2--> */}
            <Select
              value={values.productTemplate ? {value: values.productTemplate, label: values.productTemplate} : null}
              options={[
                {value: 'Electronics', label: 'Electronics'},
                {value: 'Office stationary', label: 'Office stationary'},
                {value: 'Fashion', label: 'Fashion'},
                {value: 'Default', label: 'Default'},
              ]}
              onChange={(selectedOption) => {
                // Update the Formik state using setFormikState
                setFormikState((prevState) => ({
                  ...prevState,
                  values: {
                    ...prevState.values,
                    productTemplate: selectedOption ? selectedOption.value : '',
                  },
                }))
              }}
            />

            {/*  <!--end::Select2--> */}

            {/*  <!--begin::Description--> */}
            <div className='text-muted fs-7'>
              Assign a template from your current theme to define how a single product is displayed.
            </div>
            {/*  <!--end::Description--> */}
          </div>
          {/*  <!--end::Card body--> */}
        </div>
        {/* <!--end::Template settings-->  */}
      </div>
      {/*  <!--end::Aside column--> */}
    </div>
  )
}

export default AsideColumn
