import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { uploadAsync } from "../actions/uploadAction";

                                


// Define the initial state interface
interface UploadCsvState {
  uploading: boolean;
  error: string | null;
  csvData:any,
}

// Define the initial state
const initialState: UploadCsvState = {
  uploading: false,
  error: null,
  csvData:null,
};

// Create the slice
const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {
    // Add any additional reducers here if needed
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(uploadAsync.pending), (state) => {
        state.uploading = true;
        state.error = null; // Reset any previous errors
      })
      .addMatcher(isAnyOf(uploadAsync.fulfilled), (state,action) => {
        state.uploading = false;
        state.error = null;
        state.csvData=action?.payload
      })
      .addMatcher(isAnyOf(uploadAsync.rejected), (state, action) => {
        state.uploading = true;
        state.error = action.error.message || "Upload failed";
      });
  },
});

// Export the actions and reducer
export const uploadActions = uploadSlice.actions;
export default uploadSlice.reducer;
