import {Formik} from 'formik'
import React, {FC} from 'react'
import {Button, Modal} from 'react-bootstrap'
import Select from 'react-select'
import { requestForShopifyOauthUrlAsync } from 'src/redux/actions/authActions'
import { useAppDispatch } from 'src/redux/storeHooks'
import { shopifyAccessScope } from 'src/utils/constants'

type ShopifyUserCredFormProp = {
  closeModal: () => void
  isVisible: boolean
  detailsToSend:  {
    platformId: null | string;
    redirect_uri: Location;
    shop: null | string;
}
}


const ShopifyUserCredForm: FC<ShopifyUserCredFormProp> = ({closeModal, isVisible, detailsToSend }) => {

  const dispatch = useAppDispatch()

  return (
    <Modal backdrop='static' centered show={isVisible}>
      <Formik 
      initialValues={{clientId: '', clientSecret:'', scopes:'' }} 
      onSubmit={(values, helpers) => {
        localStorage.setItem('shopifyOAuthData', JSON.stringify({...values, ...detailsToSend }) )
        dispatch(requestForShopifyOauthUrlAsync({available_platform:detailsToSend.platformId!, client_id:values.clientId, redirect_uri:detailsToSend.redirect_uri.href.split('?')[0].toString(), scope: values.scopes, shop:detailsToSend.shop! }))
        .then((response)=>{
          if(response.payload?.status_code=='200'){
            window.open(response.payload?.data?.url,'blank')
          }
          
        })
        helpers.setSubmitting(false)
        
      }}>
        {({values, isSubmitting, handleChange, handleSubmit, setFormikState }) => {
          return (
            <>
            <form onSubmit={handleSubmit}  >
              <Modal.Header>
                <Modal.Title>{'Add Cred'}</Modal.Title>
                <div className='d-inline-flex'>
                  {/* <Button disabled={isSubmitting} size='sm' className='mx-2' onClick={closeModal}>
                    Close
                  </Button> */}
                  <Button disabled={isSubmitting} size='sm' variant='success' type='submit'>
                    Verify Shopify Account Detail
                  </Button>
                </div>
              </Modal.Header>
              <Modal.Body>
              <div className='mb-8'>
                    <label className='form-label'>Client Id</label>
                    <input
                      type='text'
                      autoComplete='OFF'
                      className='form-control'
                      placeholder={'Enter name'}
                      name='clientId'
                      value={values.clientId}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='mb-8'>
                    <label className='form-label'>Client Secret</label>
                    <input
                      type='text'
                      autoComplete='OFF'
                      className='form-control'
                      placeholder={'Enter name'}
                      name='clientSecret'
                      value={values.clientSecret}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className='mb-8'>
                    <label className='form-label'>Scope</label>
                    <Select
                      isMulti
                      name='scopes'
                      value={values.scopes.length ? values.scopes.split(',').map((it)=>({label:it,value:it})) : [] }
                      options={shopifyAccessScope}
                      onChange={(ev)=>{
                        let valsStr = ev.map((ite)=>(ite.value))
                          console.log('in string format', valsStr.join(',') );
                          
                        setFormikState((pre)=>{
                          return {...pre, values:{...pre.values, scopes:valsStr.join(',') } }
                        })
                      }}
                    />
                  </div>
              </Modal.Body>
            </form>
            </>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ShopifyUserCredForm
