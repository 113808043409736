import { faCog, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks';
import { getUserInvitesListAsync, postInvitedUser } from 'src/redux/actions/userInviteAction'; // Import the action creator for POST API call
import { fetchUserAccountDetailsAsync } from 'src/redux/actions/accountActions';
import { accessSessionTokenKeyName } from 'src/utils/constants';
import { USER_DATA } from 'src/app/_interfaces/auth.interface';
import InvitingUserModal from './InvitingUserModal';
import useAppToast from 'src/hooks/useAppToast';

const CommonColumn: FC<{ rowData: string }> = ({ rowData }) => {
  return (
    <>
      <p>{rowData}</p>
    </>
  );
};

const UserInvites = () => {
  const [currentUserData, setCurrentUserData] = useState<USER_DATA>();
  const {successToast, errorToast} = useAppToast()
  const [paginationData, setPaginationData] = useState({
    page: 1,
    limit: 10,
  
  })
  const { userInviteLoading, userInvitePaginationData, userInviteList } = useAppSelector(
    (state) => state.userInvite
  );
  const { fetchedUserAccountData } = useAppSelector(
    (state) => state.userAccountData
  );
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchUserAccountDetailsAsync())
      .then((response) => {
        console.log('User account data:', response.payload);
        setCurrentUserData(response.payload); // Log the response data
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      }); 
  }, []);



  useEffect(() => {
    if (currentUserData?.id) {
      dispatch(getUserInvitesListAsync({ userId: currentUserData?.id,paramData: {...paginationData}}));
    }
  }, [currentUserData,paginationData])
  

 
  const handleInviteUser = (values: { email: string; name: string }) => {
    const userData = {
      email: values.email,
      name: values.name,
      user_type: 'USER',
    };

    if(currentUserData &&  currentUserData?.id ){
      console.log("invided by-",currentUserData?.id)

      dispatch(postInvitedUser({ userdata: userData, userId: currentUserData?.id }))
      .then((response) => {
        console.log("handle--",response)
        if(response?.payload?.id){
          setShowInviteModal(false); // Close the modal after successful invitation
          successToast("Invitation sent")
        }else {
          errorToast(response?.payload?.data?.error)
        }
        
        dispatch(getUserInvitesListAsync({ userId: currentUserData?.id ,paramData: {...paginationData}}));
      })
      .catch((error) => {
        console.error('Error inviting user:', error);
        // Handle error or display notification to the user
      });
    }

  };

  const columns = [
    {
      name: 'ID',
      cell: (row) => (row ? <CommonColumn rowData={row.id} /> : null),
      sortable: true,
      style: { paddingLeft: '20px' },
      headerStyle: { textAlign: 'left' },
      cellStyle: { textAlign: 'left' },
    },
    {
      name: 'Name',
      cell: (row) => (row ? <CommonColumn rowData={row.name} /> : null),
      sortable: true,
    },
    {
      name: 'Email ID',
      cell: (row) => (row ? <CommonColumn rowData={row.email} /> : null),
      sortable: true,
    },
  ];

  const tableHeight = `${(userInviteList.length + 1) * 52}px`;

  return (
    <>
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <span className="fw-bold text-lg" style={{ fontSize: '16px' }}>
          User Invites
        </span>
        <button
          className="btn btn-primary btn-sm d-inline-flex align-items-center"
          onClick={() => {
            setShowInviteModal(true);
          }}
        >
          <div style={{ fontSize: '14px' }}>Invite</div>
        </button>
      </div>

      <div className="card card-flush">

          <DataTable
            fixedHeader={true}
            data={userInviteList}
            columns={columns}
            pagination
            paginationServer
            paginationTotalRows={userInvitePaginationData.count}
            progressPending={userInviteLoading}
            progressComponent={<Spinner animation="border"  />}
            noDataComponent={<div>No users to show...</div>}
            onChangePage={(pageNumber, totalRows) => {
              setPaginationData((pre) => {
                return {...pre, page: pageNumber}
              })
            }}
            onChangeRowsPerPage={(currentPage) => {
              setPaginationData((pre) => {
                return {...pre, limit: currentPage}
              })
            }}
            customStyles={{
              table: {
                style: {
                  height: '60vh',
                },
              },
              headCells: {
                style: { fontWeight: 'bold', fontSize: '14px' },
              },
              cells: {
                style: {
                  padding: '10px',
                  fontWeight:"bold",
                  borderBottom: '1px solid gainsboro',
                },
              },
              progress: {
                style: {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%', // Set the height of the container to occupy the entire table height
                  marginTop: '100px', // Adjust the margin top to move it down
                },
              },
            }}
          />
        </div>
      

      <InvitingUserModal show={showInviteModal} onClose={() => setShowInviteModal(false)} onInviteUser={handleInviteUser} />
    </>
  );
};

export default UserInvites;
