import React, {useState} from 'react'

const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null)
  const [currentTrustScore, setCurrentTrustScore] = useState(0) //this is default rating for any uncertain case
  React.useEffect(() => {
    console.log(
      'checking creds',
      process.env.REACT_APP_TRUST_PILOT_TEMPLATE_ID,
      process.env.REACT_APP_TRUST_PILOT_BUSINESS_UNIT_ID
    )

    fetch(
      `https://widget.trustpilot.com/trustbox-data/${process.env.REACT_APP_TRUST_PILOT_TEMPLATE_ID}?businessUnitId=${process.env.REACT_APP_TRUST_PILOT_BUSINESS_UNIT_ID}&locale=en-US&includeReviews=false`
    )
      .then((res) => res.json())
      .then((result) => {
        // console.log('result', result)
        if (result?.businessEntity?.trustScore) {
          setCurrentTrustScore(result?.businessEntity?.trustScore)
        }
      })
      .catch((error) => {
        console.log('trustpilot score not loaded error', error)
      })
  }, [])

  return (
    <>
      <div className='trustpilot-widget mb-2'>
        <a className='text-white text-decoration-none fw-bold d-flex flex-column align-items-center ' href='https://www.trustpilot.com/review/mycommercium.com' target='_blank' rel='noopener'>
          <img
            alt={`TrustScore ${currentTrustScore} out of 5`}
            src={`https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-${currentTrustScore}.svg`}
            className='h-30px mb-1'
          />
          See our reviews on Trustpilot
        </a>
      </div>
      {/* <!-- TrustBox widget - Review Collector --> */}
      {/* <div className="trustpilot-widget" data-locale="en-US" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="6618dd7c25b2c4f337b2e474" data-style-height="40px" data-style-width="100%">
        <a href="https://www.trustpilot.com/review/mycommercium.com" target="_blank" rel="noopener">Trustpilot</a>
      </div> */}
      {/* <!-- End TrustBox widget --> */}
    </>
  )
}
export default TrustBox
