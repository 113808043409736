import { createSlice, isAnyOf } from "@reduxjs/toolkit"
import { RetrievePlatformDataType } from "src/interfaces/Platform.interface"
import { deletePlatformDataAsync, retrivePlatformDataAsync } from "../actions/platformActions"

type deletePlatformPayload={

    deletePlatformDataLoading: boolean
    error: string | null;
}

const initialState: deletePlatformPayload ={
    deletePlatformDataLoading:false,
    error: null,
}

const deletePlatformDataSlice =createSlice({
    name: 'deletedPlatformDataSlice',
    initialState,
    extraReducers: (builder) => {
      builder.addMatcher(isAnyOf(deletePlatformDataAsync.pending), (state, action) => {
        state.deletePlatformDataLoading = true
        state.error = null; // Reset error on pending
      })
      builder.addMatcher(isAnyOf(deletePlatformDataAsync.fulfilled), (state, action) => {
        state.deletePlatformDataLoading = false
       
      })
      builder.addMatcher(isAnyOf(deletePlatformDataAsync.rejected), (state, action) => {
        state.deletePlatformDataLoading = false;
        if (action.error && action.error.message) {
          state.error = action.error.message;
        } else {
          state.error = "Failed to delete platform data";
        }
      });
    },
    reducers: {
      resetDeletePlatformState: (state) => {
        state.deletePlatformDataLoading = false;
        state.error = null;
      },
        
    },

});

export const { resetDeletePlatformState } = deletePlatformDataSlice.actions;
export default deletePlatformDataSlice.reducer;