import {faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {Alert, Button, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {toast} from 'react-toastify'
import useAppToast from 'src/hooks/useAppToast'
import {PlatformDataType} from 'src/interfaces/Platform.interface'
import {PRICE_TEMPLATE_ITEM} from 'src/interfaces/Template.interface'
import {getPlatformFieldsAsync} from 'src/redux/actions/productActions'
import {
  savePlatformPriceAsync,
  updatePlatformPriceAsync,
} from 'src/redux/actions/productPricingAction'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
type AddNewPriceTemplateModalPropsType = {
  isVisible: boolean
  closeModal: () => void
  selectedSecondaryPlatform: PlatformDataType | null
  updateItem: PRICE_TEMPLATE_ITEM | null
  getLatestPriceTemplateList: () => void
}
type OPTION_TYPE = 'NATIVE' | 'CUSTOM' | 'DEFAULT'

const renderTooltip = (props) => (
  <Tooltip id='button-tooltip' {...props}>
    Use this Switch when you want all Products to use this Mapping/Rule.
  </Tooltip>
)

const AddNewPriceTemplateModal: FC<AddNewPriceTemplateModalPropsType> = ({
  isVisible,
  closeModal,
  selectedSecondaryPlatform,
  updateItem,
  getLatestPriceTemplateList,
}) => {
  const [showDefault, setShowDefault] = useState<boolean>(false)
  const [name, setName] = useState<string>('')
  const [value, setValue] = useState<any>('')
  const [changeValue, setChangeValue] = useState<string>()
  const [mode, setMode] = useState<string>('PERCENTAGE')
  const {successToast, errorToast} = useAppToast()
  const [attributeMappingOptions, setAttributeMappingOptions] = useState<OPTION_TYPE>('DEFAULT')
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const [fieldDataLoading, setFieldDataLoading] = useState(false)
  const [fieldData, setFieldData] = useState<any[]>([])
  const [fieldDataCustom, setFieldDataCustom] = useState<any[]>([])
  const [priceAdjustmentType,setPriceAdjustmentType]=useState<string>(Number(updateItem?.repricing?.value)>0 ? 'increase':'decrease')

  const dispatch = useAppDispatch()

  
  const updateProduct = async (payload, helpers) => {
    try {
      await dispatch(updatePlatformPriceAsync({...payload})).then((response) => {
        console.log('response on no value', response)
        if (response?.payload?.data?.error) {
          errorToast(response?.payload?.data?.error)
          helpers.setSubmitting(false)
          return
        }
        successToast('Updated Successfully')
        getLatestPriceTemplateList()
        helpers.setSubmitting(false)
        closeModal()
      })
    } catch (error) {
      console.log('error', error)
      helpers.setSubmitting(false)
      return
    }
  }

  const addProduct = async (payload, helpers) => {
    try {
      await dispatch(savePlatformPriceAsync({...payload})).then((response) => {
        if (response?.payload?.data?.error) {
          errorToast(response?.payload?.data?.error)
          helpers.setSubmitting(false)
          return
        }
        successToast('Added Successfully')
        getLatestPriceTemplateList()
        helpers.setSubmitting(false)
        closeModal()
      })
    } catch (error) {
      helpers.setSubmitting(false)
      console.log('error', error)
    }
  }
  const handleMappingOptionChange = (option: any) => {
    setAttributeMappingOptions(option)
    if (option === 'NATIVE' || option === 'CUSTOM') {
      setFieldDataLoading(true)
      const fieldType = option === 'CUSTOM' ? 'CUSTOM' : 'NATIVE'
      dispatch(
        getPlatformFieldsAsync({
          platformId: mainStoreData?.id!,
          otherParams: {field_type: fieldType, includeLocalFields: option === 'NATIVE'},
        })
      ).then((response) => {
        if (fieldType === 'NATIVE') {
          setFieldData(response.payload)
          setFieldDataLoading(false)
        } else {
          setFieldDataCustom(response.payload)
          setFieldDataLoading(false)
        }
        setFieldDataLoading(false)
      })
      .catch((err)=>{
        console.log('err', err)
        setFieldDataLoading(false)

      })
    }
  }

  type INITIAL_VALUES = {
    name: string
    platform_id: number
    repricing: {
      type: 'PERCENTAGE' | 'FLAT'
      value: string|number
    }
    isDefault: boolean

    shipping_price:{
      mapped_field: number | null;
      value: number | null;
    
    }
  }

  const initialValues: INITIAL_VALUES = {
    name: updateItem?.name ? updateItem?.name : '',
    platform_id: selectedSecondaryPlatform?.id!,
    repricing: {
      type: updateItem?.repricing.type ? updateItem?.repricing?.type : 'FLAT',
      value: !isNaN(Number(updateItem?.repricing.value)) ? updateItem?.repricing.value! : '',
    },
    isDefault: updateItem?.isDefault ? updateItem.isDefault : false,
    shipping_price:{
      mapped_field:updateItem?.shipping_price?.mapped_field? updateItem?.shipping_price?.mapped_field :null,
      value:updateItem?.shipping_price?.value? updateItem?.shipping_price?.value:null,
     
    }
  }
  

  return (
    <>
      <Modal size={'lg'} backdrop='static' centered show={isVisible} onHide={closeModal}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, helpers) => {
            console.log('values', values)
            let shadow= {...values}
            let shadowRepricing={...values.repricing}
            if(priceAdjustmentType==='decrease')
              {
              // console.log(Math.abs(Number(values.repricing.value)),"Number(shadow.repricing.value)*(-1)",Number(shadow.repricing.value),)
                shadowRepricing.value= -Math.abs(Number(values.repricing.value))
                shadow.repricing=shadowRepricing

                if(shadowRepricing.value=== 0){
                  shadowRepricing.value= -Math.abs(Number(values.repricing.value))
                  shadow.repricing=shadowRepricing
                }
              }
              console.log(priceAdjustmentType,"shadow",shadowRepricing)
            if (values.repricing.value === '') {
              errorToast(' Price Adjustment Value is missing')
              helpers.setSubmitting(false)
              return
            }

            if (updateItem?.id) {
              console.log("UPDATE values===",values)
              updateProduct({platform_id: updateItem?.id!, payload: {...shadow}}, helpers)
              // helpers.setSubmitting(false)
            } else {
              console.log("values===",values)
              addProduct({...shadow}, helpers)
              // helpers.setSubmitting(false)
            }
          }}
        >
          {({values, handleChange, handleSubmit, setFormikState, isSubmitting}) => {
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Modal.Header>
                    {updateItem?.id ? (
                      <Modal.Title>Update Price Modifier Rule </Modal.Title>
                    ) : (
                      <Modal.Title>Add New Price Modifier Rule </Modal.Title>
                    )}

                    <div className='d-inline-flex'>
                      <Button
                        size='sm'
                        className='mx-2'
                        type='button'
                        onClick={() => {
                          closeModal()
                        }}
                      >
                        Close
                      </Button>
                      <Button size='sm' variant='success' disabled={isSubmitting} type='submit'>
                      {isSubmitting?  "Saving" : " Save Changes"} 
                      </Button>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div className=' d-flex justify-content-end form-check form-switch  mt-2'>
                      <div>
                        <label className='form-label mt-1'>
                          Default&nbsp;
                          <OverlayTrigger placement='left' overlay={renderTooltip}>
                            <i className='bi bi-info-circle'></i>
                          </OverlayTrigger>
                        </label>
                      </div>
                      <input
                        className='ms-4 mb-4 form-check-input h-20px w-40px'
                        type='checkbox'
                        name='isDefault'
                        checked={values.isDefault}
                        onChange={handleChange}
                      />
                    </div>

                    <div className='mb-8'>
                      <label className='form-label'>Rule name</label>
                      <input
                        type='text'
                        autoComplete='OFF'
                        className='form-control'
                        placeholder={'Enter name'}
                        name='name'
                        value={values.name}
                        onChange={handleChange}
                      />
                    </div>

                    <div className=' '>
                      <div className='m-sm-3 m-0 mt-sm-0'>
                        {/* price adjustment section start  */}
                        <div className='row'>
                          <div className='col-sm-3 col-12  align-items-center d-flex '>
                            <p className='form-label text-center mb-0'>Price Adjustment</p>
                          </div>
                          <div className='col-sm-3 col-12 mt-2 mt-sm-0'>
                               <select
                              className='form-select'
                              onChange={(ev) => {
                                const newValue = ev.target.value === 'decrease'
                                  ? -Math.abs(Number(values.repricing.value) || 1)
                                  : Math.abs(Number(values.repricing.value) || 1)
                                setFormikState((prevState) => {
                                  return {
                                    ...prevState,
                                    values: {
                                      ...prevState.values,
                                      repricing: {
                                        ...prevState.values.repricing,
                                        value: newValue,
                                      },
                                    },
                                  }
                                })
                                setPriceAdjustmentType(ev.target.value)
                              }}
                              defaultValue={
                                Number(values.repricing.value) <= 0 && priceAdjustmentType==='decrease'? 'decrease' : 'increase'
                              }
                            >
                              <option value={'increase'}>Increase</option>
                              <option value={'decrease'}>Decrease</option>
                            </select>
                          </div>
                          <div className='col-sm-3 col-12 mt-2 mt-sm-0'>
                            <div className='d-flex align-items-center '>
                              <label className='form-label'>by</label>
                              <input
                                type='number'
                                className='ms-2 form-control '
                                placeholder={'Enter value'}
                                name='repricing.value'
                                value={(Number(values.repricing.value) >0) ? values.repricing.value+'': Number(values.repricing.value)*(-1)+''}

                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className='col-sm-3 col-12 mt-2 mt-sm-0'>
                            <select
                              className='form-select'
                              defaultValue={'FLAT'}
                              name='repricing.type'
                              onChange={handleChange}
                              value={values.repricing.type}
                            >
                              <option value={'FLAT'}>Flat Amount</option>
                              <option value={'PERCENTAGE'}>Percent</option>
                            </select>
                          </div>
                        </div>
                        {/* price adjustment section end  */}
                      </div>
                    </div>

                    {/* <label className='form-label'>Shipping</label>
                    <div className='mb-8 border border-2 rounded p-4 '>
                      <div className='m-sm-3 m-0'>
                        <div className='row'>
                          <div className='col-sm-3 col-12 mt-2 mt-sm-0  align-items-center d-flex justify-content-sm-center'>
                            <p className='form-label text-center mb-0'>Shipping Price Mapping</p>
                          </div>
                          <div className='col-sm-6 col-12 mt-2 mt-sm-0'>
                            <div className='d-flex  align-items-center'>
                              <label className='form-label flex-shrink-0 mt-2'>
                                Mapping Option
                              </label>
                              <select
                                className='form-select ms-3 flex-grow-1'
                                value={attributeMappingOptions}
                                onChange={(e) => handleMappingOptionChange(e.target.value)}
                              >
                                <option value='DEFAULT'>Default</option>
                                <option value='NATIVE'>Native Field</option>
                                <option value='CUSTOM'>Custom Field</option>
                              </select>
                            </div>
                          </div>
                          <div className='col-sm-3 col-12 mt-2 mt-sm-0'>
                            {attributeMappingOptions === 'DEFAULT' ? (
                              <div className=''>
               
                                <input
                                  className='form-control'
                                  type='number'
                                  name='shipping_price.value'
                                  placeholder='Enter value'
                                  // step='any'
                                  value={
                                    values?.shipping_price?.value? values?.shipping_price?.value: ''
                                  }
                                  onChange={handleChange}
                                />
                                  
                        
                              </div>
                            ) : (
                              <div className=''>
                                <select
                                  className='form-select '
                                  // name={`template_data[${index}].mapping_field`}
                                  value={values?.shipping_price?.mapped_field ? values?.shipping_price?.mapped_field: ""}
                                  name='shipping_price.mapped_field'
                                  onChange={handleChange}
                                  disabled={fieldDataLoading}
                                >
                                  
                                  <option> Select Field</option>
                                  {attributeMappingOptions === 'NATIVE'
                                    ? fieldData?.map((field) => (
                                        <option key={field.id} value={field.id}>
                                          {field.display_name}
                                        </option>
                                      ))
                                    : fieldDataCustom?.map((field) => (
                                        <option key={field.id} value={field.id}>
                                          {field.display_name}
                                        </option>
                                      ))}
                                </select>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </Modal.Body>
                </form>
              </>
            )
          }}
        </Formik>
      </Modal>
    </>
  )
}

export default AddNewPriceTemplateModal
