import React, {FC, Fragment} from 'react'
import AsideColumn from './AsideColumn'
import MainColumn from './MainColumn'
import AsideColumnCopy from './AsideColumnCopy'
import {Form, Formik} from 'formik'
import MainColumnCopy from './MainColumnCopy'
import { InitialValuesForCreateProduct } from 'src/interfaces/createProductInterface'

const initialValues:InitialValuesForCreateProduct = {
  name :'',
  description:"",
  basePrice:'',
  image_set:[],
  options:[],
  varients_data:[],
  selectedStatus: '',
  scheduledDateTime: '',
  selectedOptions: [],
  selectedValues: [],
  inputValue: '',
  productTemplate: '',
  selectedTagValues: [],
  product_name:'',
  barcode: '',
  shelfQuantity: '',
  warehouseQuantity: '',
  allowBackorders: false,
  selectedImages:[],
  weeklySales:0,
  
  genralDescription: '',
  pricing: {
    basePrice:''
  },
  discountType: '1', // Set default discount type here
  discount_percentage:'',
  discount_fixed_price: '',
  tax: '0', // Set default tax option here
  vat_amount: '',
  sku: '',
  advanceDescription: '',
  shipping: {
    weight: 0,
    dimensions: {
      width: 0,
      height: 0,
      length: 0,
    },
  },
  meta_title: '',
  add_product_meta_keywords: '',
}

const CreateProduct: FC = () => {
  const handleSubmit = (values, actions) => {
    // Handle form submission here
    console.log(values)
    actions.setSubmitting(false) // Make sure to set submitting state to false after submission
  }

  return (
    <Fragment>
      <div className='d-flex flex-column flex-column-fluid '>
        <div id='kt_app_toolbar' className='app-toolbar  py-3 py-lg-6 '>
          <div
            id='kt_app_toolbar_container'
            className='app-container  container-xxl d-flex flex-stack '
          >
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 '>
              <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
                Product Form
              </h1>

              <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                <li className='breadcrumb-item text-muted'>
                  <a href='/metronic8/demo1/index.html' className='text-muted text-hover-primary'>
                    Home{' '}
                  </a>
                </li>

                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-500 w-5px h-2px'></span>
                </li>

                <li className='breadcrumb-item text-muted'>eCommerce </li>

                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-500 w-5px h-2px'></span>
                </li>

                <li className='breadcrumb-item text-muted'>Catalog </li>
              </ul>
            </div>

            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              <a
                href='#'
                className='btn btn-sm fw-bold btn-primary'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
              >
                Create{' '}
              </a>
            </div>
          </div>
        </div>

        <div id='kt_app_content' className='app-content  flex-column-fluid'>
          {/* <!--begin::Content container--> */}
          <div id='kt_app_content_container' className='app-container  container-xxl '>
            {/*  <!--begin::Form--> */}
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
              {({isSubmitting, handleSubmit, handleChange, values, setFormikState}) => {
                console.log("values",values)
                return (
                  <form
                    id='kt_ecommerce_add_product_form'
                    className='form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework'
                    data-kt-redirect='/metronic8/demo1/apps/ecommerce/catalog/products.html'
                    onSubmit={handleSubmit}
                  >
                    <div className='row'>
                      {/* <input  className='form-control' name='inputValue' value={values.inputValue} onChange={handleChange}/> */}
                      <div className='col-md-4 order-md-1 order-1'>
                        <AsideColumn
                          values={values}
                          handleChange={handleChange}
                          setFormikState={setFormikState}
                        />
                      </div>
                      <div className='col-md-8 order-md-2 order-2'>
                        <MainColumn
                          values={values}
                          handleChange={handleChange}
                          setFormikState={setFormikState}
                        />
                      </div>
                    </div>
                  </form>
                )
              }}
            </Formik>
            {/*  <!--end::Form--> */}
          </div>
          {/*  <!--end::Content container--> */}
        </div>
      </div>
    </Fragment>
  )
}

export default CreateProduct
