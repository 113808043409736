// StatusButton.tsx
import React, { FC } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

interface StatusButtonProps {
  syncStatus: string;
  syncMessage?: string;

}

const StatusButton: FC<StatusButtonProps> = ({ syncStatus, syncMessage}) => {
  const renderTooltip = () => (
    <Tooltip id="status-tooltip">{syncMessage || 'No sync message available'}</Tooltip>
  );

  let buttonContent;

  switch (syncStatus) {
    case 'ERROR':
      buttonContent = 'Failed';
      break;
    case 'PENDING':
      buttonContent = 'Pending';
      break;
    case 'SYNCED':
      buttonContent = 'Synced';
      break;
    case 'SUCCESS':
      buttonContent = 'Synced';
      break;
    case 'ERROR_UNLINKED':
      buttonContent = 'Failed';
      break;
    case 'SYNCED_PARTIALLY':
      buttonContent = 'Partially Synced';
      break;
    case 'SYNCED_WITH_ERROR' :
      buttonContent = 'Sync with error';
      break;
    case 'PENDING_APPROVAL' :
      buttonContent = 'Pending Approval'
      break;
    case 'PROCESSING' :
        buttonContent = 'Processing'
        break;
    default:
      buttonContent = 'Pending'; // Default content for unknown statuses
      break;
  }


  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip()}
    >
      <Button
        style={{
          backgroundColor:
            syncStatus === 'SYNCED_PARTIALLY' ? 'lightsteelblue' :
            syncStatus === 'SYNCED_WITH_ERROR' ? 'red' :
            syncStatus === 'PENDING_APPROVAL' ? '#F9F9A0' :
            syncStatus === 'ERROR' ? 'red' :
            syncStatus === 'PENDING' ? '#FFD700' :
            syncStatus === 'SYNCED' ? 'green' :
            syncStatus === 'PROCESSING' ? '#003E80' :
            syncStatus === 'SUCCESS' ? 'green' :
            syncStatus === 'ERROR_UNLINKED' ? 'red' :
            '#FFD700', // Default color for unknown statuses
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
          width:"130px"
        }}
      >
         {buttonContent}
      </Button>
      
    </OverlayTrigger>
  );
};

export default StatusButton;
