import {createAsyncThunk} from '@reduxjs/toolkit'
import AxiosClient from '../AxiosClient'

export const getAllProductsFromChannelAsync = createAsyncThunk(
  'product/getAllProductsFromChannelAsync',
  async function (params: {id: string; paginationdata: {page: number; limit: number}}, toolkit) {
    return await AxiosClient(
      'GET',
      `/api/platform-product-list/${params.id}`,
      [],
      toolkit,
      params.paginationdata
    )
  }
)
export const getAllProductsVarientsFromChannelAsync = createAsyncThunk(
  'product/getAllProductsVarientsFromChannelAsync',
  async function (params: {id: string; paginationdata: {page: number; limit: number}}, toolkit) {
    return await AxiosClient(
      'GET',
      `/api/platform-product-variants/${params.id}`,
      [],
      toolkit,
      params.paginationdata
    )
  }
)
export const getAllProductsVarientsFromMainChannelAsync = createAsyncThunk(
  'product/getAllProductsVarientsFromMainChannelAsync',
  async function (
    params: {id: string; paginationdata: {page: number; limit: number; search_string?: string}},
    toolkit
  ) {
    return await AxiosClient(
      'GET',
      `/api/platform-product-variants/${params.id}`,
      [],
      toolkit,
      params.paginationdata
    )
  }
)

export const getPlatformProductDetailAsync = createAsyncThunk(
  'product/getPlatformProductDetailAsync',
  async function (params: {productId: string}, toolkit) {
    return await AxiosClient('GET', `/api/platform-product/${params.productId}`, [], toolkit, [])
  }
)

export const getPlatformProductVarientDetailAsync = createAsyncThunk(
  'product/getPlatformProductVarientDetailAsync',
  async function (params: {productVarientId: string}, toolkit) {
    return await AxiosClient('GET', `/api/platform-product-by-variant/${params.productVarientId}`, [], toolkit, [])
  }
)


export const getPlatformProductAdjustmentAsync = createAsyncThunk(
  'product/getPlatformProductAdjustmentAsync',
  async function (params: {platformId: string; otherParams: any}, toolkit) {
    return await AxiosClient(
      'GET',
      `/api/get-inventory-adjustments/${params.platformId}`,
      [],
      toolkit,
      params.otherParams
    )
  }
)
export const getPlatformProductAdjustmentSecondaryAsync = createAsyncThunk(
  'product/getPlatformProductAdjustmentSecondaryAsync',
  async function (params: {platformId?: string; otherParams: any}, toolkit) {
    return await AxiosClient(
      'GET',
      `/api/get-inventory-adjustments-to-platform/${params.platformId}?limit=${params.otherParams.limit}&variant_id=${params.otherParams.variant_id}&product_id=${params.otherParams.product_id}&sort_by=api_created_at&sort_order=DESC`,

      [],
      toolkit,
      params.otherParams
    )
  }
)

export const linkProductVarientAsync = createAsyncThunk(
  'platform/connectPlatform',
  async function (
    payload: {
      variant_id: number
      parent_variant_id: number
    },
    toolkit
  ) {
    return await AxiosClient('POST', '/api/link-product-variant', payload, toolkit, [])
  }
)

// export const getProductsListAsync = createAsyncThunk(
//   'orders/getProductsListAsync',
//   async function (pageparams:{ page:number , limit?:number }, toolkit) {
//     return await AxiosClient('GET', `/api/platform-orders-list`, [], toolkit, pageparams,  )
//   }
// )

export const getPlatformFieldsAsync = createAsyncThunk(
  'platform/getPlatformFieldsAsync',
  async function (params: {platformId: number; otherParams: any}, toolkit) {
    return await AxiosClient(
      'GET',
      `/api/platform-fields/${params.platformId}`,
      [],
      toolkit,
      params.otherParams
    )
  }
)

export const getPlatformFieldsForMainStoreAsync = createAsyncThunk(
  'platform/getPlatformFieldsForMainStoreAsync',
  async function (params: {platformId: number; otherParams: any}, toolkit) {
    return await AxiosClient(
      'GET',
      `/api/platform-fields/${params.platformId}`,
      [],
      toolkit,
      params.otherParams
    )
  }
)


export const getPlatformFieldsForMainStoreForLocationAsync = createAsyncThunk(
  'platform/getPlatformFieldsForMainStoreForLocationAsync',
  async function (params: {platformId: number; otherParams: any}, toolkit) {
    return await AxiosClient(
      'GET',
      `/api/platform-fields/${params.platformId}`,
      [],
      toolkit,
      params.otherParams
    )
  }
)

export const saveFieldMappingAsync = createAsyncThunk(
  'platform/saveFieldMappingAsync',
  async function (payload: any, toolkit) {
    return await AxiosClient('POST', `/api/field-mapping`, payload, toolkit, [])
  }
)

export const getFieldMappingAsync = createAsyncThunk(
  'platform/getFieldMappingAsync',
  async function (params: any, toolkit) {
    return await AxiosClient('GET', `/api/field-mapping`, [], toolkit, params)
  }
)

export const deleteFieldMappingAsync = createAsyncThunk(
  'platform/deleteFieldMappingAsync',
  async function (params: {mappingDataId: number}, toolkit) {
    return await AxiosClient(
      'DELETE',
      `/api/field-mapping/${params.mappingDataId}`,
      [],
      toolkit,
      []
    )
  }
)
export const productVarientResyncAsync = createAsyncThunk(
  'product/productVarientResyncAsync',
  async function (payload: {failed_variant_ids: number[]}, toolkit) {
    return await AxiosClient('POST', `/api/resync-failed-variants`, payload, toolkit, [])
  }
)


export const getPlatformWiseProductCsvsAsync = createAsyncThunk(
  'platform/getPlatformWiseProductCsvsAsync',
  async function (params: {platform_id: number}, toolkit) {
    return await AxiosClient('GET', `/platform-product-csv`, [], toolkit, params)
  }
)


export const createProductCsvsAsync = createAsyncThunk(
  'platform/createProductCsvsAsync',
  async function (params: {platform_id: number|null}, toolkit) {
    return await AxiosClient('GET', `/create-product-csv?platform_id=${params.platform_id}`, [], toolkit, [])
  }
)

