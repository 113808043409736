import {FC} from 'react'
import InfoTooltip from './InfoTooltip'
import { useField } from 'formik'

const CustomInput: FC<{
  inputProps: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
  label: string
  message: string
  inputWrapperClasses?: string
  containerClasses?: string
  labelClass?: string
}> = ({
  inputProps,
  label = '',
  message = '',
  inputWrapperClasses = '',
  containerClasses = 'col-12 col-sm-6 mt-4',
  labelClass = 'form-label',
}) => {

  // const [field] = useField({name:inputProps.name!, type:inputProps.type })

  return (
    <div className={containerClasses}>
      <label className={labelClass} htmlFor={inputProps.id}>
        {label} &nbsp;&nbsp;<InfoTooltip message={message} />
      </label>
      <div className={inputWrapperClasses}>
        <input {...inputProps}  />
      </div>
    </div>
  )
}

export default CustomInput
