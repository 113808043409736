// AddPrimaryStoreModal.js

import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import { KTSVG } from 'src/marketConnect/helpers'


const AddPrimaryStoreModal = ({show, onClose}) => {
  const navigate = useNavigate()

  const handleAddPrimaryStore = () => {
    onClose()
    navigate('/platforms')
  }

 
  return (
    
      <Modal show={show} onHide={onClose}>
      <div className='modal-content'>
          <div className='modal-header pb-2 justify-content-end border-0'>
              <div
                  className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                  onClick={onClose}
              >
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr061.svg'
                      className='svg-icon svg-icon-2x'
                  />
              </div>
          </div>

          <div className='modal-body py-0 text-center'>
              <div
                  className='m-auto text-center d-flex align-items-center justify-content-center border border-warning border-4 rounded-circle p-4'
                  style={{ width: '90px', height: '90px' }}
              >
                   <FontAwesomeIcon icon={faCircleExclamation}  style={{ fontSize: '54px', color: '#ffff99' }} />

              </div>

              <div className='py-4 mt-5'>
                  <h2>No Primary Store Connected!</h2>
                  <p className='mt-4 fw-bold text-muted fs-6'>
                      Connect New Store 
                  </p>
              </div>
          </div>
          <div className='modal-footer mb-5 border-0 justify-content-center'>
              <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  onClick={onClose}
              >
                  Cancel
              </button>
              <button
                  type='button'
                  className='btn btn-light-danger'
                  data-bs-dismiss='modal'
                  onClick={
                    handleAddPrimaryStore
                    
                  }
              >
                  <div className='px-4'>Connect Store</div>
              </button>
          </div>
      </div>
  </Modal>
  )
}

export default AddPrimaryStoreModal
