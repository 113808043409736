// platformSlice.js

import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {connectPlatformAsync} from '../actions/platformActions'
import {PlatformDataType, connectedPlatformPayload} from 'src/interfaces/Platform.interface'
import { setPlatformSettingsData } from './createPlatformSettingsSlice'

type connectedPayload = {
  connectedPlatformLoading: boolean
  connectedPlatformData: connectedPlatformPayload | null
  platformSettingModalData:null|PlatformDataType
}

const initialState: connectedPayload = {
  connectedPlatformLoading: false,
  connectedPlatformData: null,
  platformSettingModalData:null
}

const connectedPlatformSlice = createSlice({
  name: 'connectedPlatformSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(connectPlatformAsync.pending), (state, action) => {
      state.connectedPlatformLoading = true
    })
    builder.addMatcher(isAnyOf(connectPlatformAsync.fulfilled), (state, action) => {
      state.connectedPlatformLoading = false

      state.connectedPlatformData = action?.payload?.data
    })
    builder.addMatcher(isAnyOf(connectPlatformAsync.rejected), (state) => {
      state.connectedPlatformLoading = true
    })
  },
  reducers: {
    setconnectedPlatformData: () => initialState,

    setPlatformSettingModalData : (state, action : { payload: PlatformDataType } ) =>{
        state.platformSettingModalData = action.payload
    }
  },
})

export const {setconnectedPlatformData, setPlatformSettingModalData } = connectedPlatformSlice.actions
export default connectedPlatformSlice.reducer
