import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import { getAllProductsVarientsFromMainChannelAsync} from '../actions/productActions'

const initialState = {
loadingProductVarientFromMainChannel:false,
productVarientFromMainChannel:[],
ProductVarientCountFromMainChannel:0,
previous:null,
next:null
}

const productVarientFromMainSlice = createSlice({
  name: 'productVarientFromMainSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getAllProductsVarientsFromMainChannelAsync.pending), (state, action) => {
      state.loadingProductVarientFromMainChannel = true
    })
    builder.addMatcher(isAnyOf(getAllProductsVarientsFromMainChannelAsync.rejected), (state) => {
        state.loadingProductVarientFromMainChannel = true
    })
    builder.addMatcher(isAnyOf(getAllProductsVarientsFromMainChannelAsync.fulfilled), (state, action) => {
      console.log('getAllProductsVarientsFromMainChannelAsync is in fulfilled state ', action.payload )
      if(action.payload?.results){
        state.productVarientFromMainChannel = action?.payload?.results
        state.ProductVarientCountFromMainChannel = action?.payload?.count
        state.previous = action.payload?.previous
        state.next = action.payload?.next
      }
        state.loadingProductVarientFromMainChannel = false
    })
  },
  reducers: {},
})

export const {} = productVarientFromMainSlice.actions
export default productVarientFromMainSlice.reducer
