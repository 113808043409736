// import React, {useEffect, useRef, useState} from 'react'
// import Button from 'react-bootstrap/Button'
// import Overlay from 'react-bootstrap/Overlay'
// import {faArrowRight, faLink} from '@fortawesome/free-solid-svg-icons'
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
// import Modal from 'react-bootstrap/Modal'
// import {
//   getAllProductsVarientsFromMainChannelAsync,
//   linkProductVarientAsync,
// } from 'src/redux/actions/productActions'
// import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
// import {toast} from 'react-toastify'

// const LinkButton = ({rowIndex, parent, onCloseTooltip, onOpenModal, productVarients}) => {
//   const [showOverlay, setShowOverlay] = useState(false) // State to track overlay visibility
//   const [showModal, setShowModal] = useState(false) // State to track modal visibility
//   const [searchTerm, setSearchTerm] = useState('')
//   const [mainStore, setMainStore] = useState(false)
//   const [activeRow, setActiveRow] = useState(null) // State to track the active row
//   const [linkingCompleted, setLinkingCompleted] = useState(false)
//   const [paginationData, setPaginationData] = useState({page: 1, limit: 10}) // Initial pagination data
//   const itemsPerPage = 10 // Adjust as needed // State to track linking completion
//   const fetchedConnectedPlatformData = useAppSelector(
//     (state) => state.fetchConnectedPlatform.fetchedConnectedPlatformData
//   )
//   const productVarientsFromMainChannel = useAppSelector(
//     (state) => state.productVarientFromMain.productVarientFromMainChannel
//   )

//   const [matchedParentProduct, setMatchedParentProduct] = useState<any | null>(null)
//   const dispatch = useAppDispatch()

//   const target = useRef(null)
//   const [dataFetched, setDataFetched] = useState(false);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         if (!dataFetched && fetchedConnectedPlatformData && fetchedConnectedPlatformData.length > 0) {
//           const mainStorePlatform = fetchedConnectedPlatformData.find(
//             (platform) => platform.isMainStore
//           );
//           if (mainStorePlatform) {
//             const mainStorePlatformId = mainStorePlatform.id;
//             await dispatch(
//               getAllProductsVarientsFromMainChannelAsync({
//                 id: String(mainStorePlatformId),
//                 paginationdata: paginationData,
//                 search_string: searchTerm.trim() !== '' ? searchTerm.trim() : undefined,
//               })
//             );

//             setDataFetched(true);
//           }
//         }
//       } catch (err) {
//         console.error('Error fetching platform data:', err);
//       }
//     };

//     fetchData();
//   }, [searchTerm, fetchedConnectedPlatformData,dataFetched]);

//   // useEffect(() => {
//   //   console.log('productVarientsFromMainChannel:', productVarientsFromMainChannel)
//   // }, [productVarientsFromMainChannel])

//   const handlePagination = (pageNumber: number) => {
//     setPaginationData({...paginationData, page: pageNumber})
//   }

//   // Calculate total number of pages
//   const totalPages = productVarientsFromMainChannel.length > 0
//   ? Math.ceil(productVarientsFromMainChannel.length / paginationData.limit)
//   : 1;

//   const handleClose = () => {
//     setShowOverlay(false)
//     setShowModal(false)
//     setMatchedParentProduct(null) // Reset matched product details when modal is closed
//   }

//   // const handleTooltipClick = (event) => {
//   //   event.stopPropagation(); // Prevent event bubbling and unwanted tooltip opening

//   //   if (rowIndex === activeRow) {
//   //     // If clicked on the same row, toggle overlay
//   //     setShowModal(true);
//   //     setShowOverlay(!showOverlay);
//   //   } else {
//   //     // Close the previously opened overlay button
//   //     setShowOverlay(false);
//   //     // Open the tooltip for the clicked row

//   //     setActiveRow(rowIndex);
//   //   }
//   // };

//   const handleOverlayButtonClick = (event) => {
//     setShowModal(true) // Open the modal
//   }

//   const handleSearchChange = (event) => {
//     setSearchTerm(event.target.value)
//     setMatchedParentProduct(null) // Reset matched product details when search term changes
//     setPaginationData({...paginationData, page: 1}) // Reset to first page when search term changes
//   }

//   // Get the clicked productVariant if the rowIndex is valid
//   const clickedProductVariant =
//     rowIndex >= 0 && rowIndex < productVarients?.length ? productVarients[rowIndex] : null

//   // Check if clickedProduct is not undefined before destructure its properties

//   const {
//     name,
//     sku,
//     platform_product: {image_set: [productImage] = []} = {},
//   } = clickedProductVariant || {}

//   // Filter products based on the search term

//   const filteredProducts = productVarientsFromMainChannel.filter(
//     (product: any) =>
//       product?.platform_product.name.toLowerCase().includes(searchTerm.toLowerCase()) || product?.sku === searchTerm
//   )
//   const handleLinkProductVariant = () => {
//     if (matchedParentProduct) {
//       dispatch(
//         linkProductVarientAsync({
//           variant_id: clickedProductVariant.id,
//           parent_variant_id: matchedParentProduct?.platform_product?.id,
//         })
//       )
//       toast.success('Variant linked successfully') // Show success toast
//       handleClose() // Close the modal
//       setLinkingCompleted(true) // Set linking completion state to true
//     } else {
//       console.log('Please select a product before linking.')
//     }
//   }

//   return (
//     <div>
//       <Button variant={parent === null ? '' : ''} ref={target} onClick={handleOverlayButtonClick}>
//         <FontAwesomeIcon icon={faLink} />
//         <span
//           style={{
//             color: linkingCompleted ? 'blue' : parent === null ? 'red' : 'blue',
//             marginLeft: '5px',
//             fontSize: '14px',
//             marginBottom: '8px',
//           }}
//         >
//           ●
//         </span>
//       </Button>
//       <Overlay target={target.current} show={showOverlay} placement='bottom'>
//         {({placement, arrowProps, show: _show, popper, hasDoneInitialMeasure, ...props}) => (
//           <div
//             {...props}
//             style={{
//               position: 'absolute',
//               padding: '2px 10px',
//               color: 'white',
//               borderRadius: 3,
//               display: 'flex',
//               flexDirection: 'column',
//               // Adjust the size as needed
//               ...props.style,
//             }}
//           >
//             <Button
//               variant='primary'
//               onClick={handleOverlayButtonClick}
//               style={{fontSize: '12px', padding: '5px'}}
//             >
//               Link to the Product
//             </Button>
//           </div>
//         )}
//       </Overlay>
//       <Modal show={showModal} onHide={handleClose}>
//         <Modal.Header closeButton>
//           <Modal.Title>Find Matching Product</Modal.Title>
//         </Modal.Header>
//         <Modal.Body style={{overflowY: 'auto', minHeight: '300px'}}>
//           <div>
//             <h5>
//               Search for an existing product to match to this listing. Enter Product Name or SKU
//               Store.
//             </h5>
//           </div>

//           <div>
//             <div className='d-flex align-items-center'>
//               <span className='symbol-label m-3' style={{backgroundImage: 'none'}}>
//                 <img
//                   src={productImage ? productImage.image_url : 'https://appconnect-images.s3.us-east-2.amazonaws.com/No_Image_Available.jpg'}
//                   alt='Product Image'
//                   style={{width: '50px', height: '50px'}}
//                 />
//               </span>
//               <div>
//                 <div>
//                   <h5>Name: {`${name}`}</h5>
//                   <h5>Variant_Sku: {`${sku}`}</h5>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <input
//             type='text'
//             placeholder='Search the product from ProductName or Sku'
//             value={searchTerm}
//             onChange={handleSearchChange}
//             style={{marginBottom: '10px', width: '100%'}}
//           />
//           {filteredProducts.map((product: any, index) => (
//             <div key={index} className='product-item' style={{cursor: 'pointer'}}>
//               <div className='border-bottom my-2 border-2'></div>

//               <div className='d-flex align-items-center justify-content-between'>
//                 <div className='m-2 me-4'>
//                   <span className='symbol-label' style={{backgroundImage: 'none'}}>
//                     {product.platform_product && product.platform_product.image_set.length > 0 ? (
//                       <img
//                         src={product.platform_product.image_set[0].image_url}
//                         alt={`Product Image ${index}`}
//                         style={{width: '50px', height: '50px'}}
//                       />
//                     ) : (
//                       <img
//                         src='https://appconnect-images.s3.us-east-2.amazonaws.com/No_Image_Available.jpg'
//                         alt='No Image Available'
//                         style={{width: '50px', height: '50px'}}
//                       />
//                     )}
//                   </span>
//                 </div>
//                 <div className='me-4 text-start'>
//                   <div>
//                     <h4>{product?.platform_product?.name}</h4>
//                     {/* <h4>parent_variant_id:{product.id}</h4> */}
//                     {/* <p>Description: {product.platform_product.description}</p> */}

//                     {/* Other product details... */}
//                   </div>
//                 </div>
//                 <button
//                   className={`m-4 text-end btn btn-primary btn-sm d-flex align-items-center justify-content-center `}
//                   style={{fontSize: '10px'}}
//                   onClick={() => {
//                     setMatchedParentProduct(
//                     product
//                     )
//                   }}
//                 >
//                   {matchedParentProduct && matchedParentProduct.id === product?.platform_product?.id
//                     ? 'Selected'
//                     : 'Select'}
//                   {matchedParentProduct && matchedParentProduct.id === product?.platform_product?.id && (
//                     <FontAwesomeIcon icon={faArrowRight} className='ms-2' />
//                   )}
//                 </button>
//               </div>
//             </div>
//           ))}
//         </Modal.Body>

//         <Modal.Footer>

//           <>
//           <Button
//                 onClick={() => handlePagination(paginationData.page - 1)}
//                 disabled={paginationData.page === 0}
//               >
//                 Previous
//               </Button>
//               <Button
//                 onClick={() => handlePagination(paginationData.page + 1)}
//                 disabled={paginationData.page === totalPages - 1}
//               >
//                 Next
//               </Button>
//           </>

//           <Button variant='secondary' onClick={handleClose}>
//             Close
//           </Button>
//           <Button variant='primary' onClick={handleLinkProductVariant}>
//             Save Changes
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   )
// }

// export default LinkButton

import React, {useEffect, useRef, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Overlay from 'react-bootstrap/Overlay'
import {faArrowRight, faLink} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal'
import {
  getAllProductsVarientsFromMainChannelAsync,
  linkProductVarientAsync,
} from 'src/redux/actions/productActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {toast} from 'react-toastify'
import { Link } from 'react-router-dom'

const LinkButton = ({
  rowData,
  rowIndex,
  onClick,
  parent,
  onCloseTooltip,
  productVarients,
  linkingCompleted,
}) => {
  const [showOverlay, setShowOverlay] = useState(false) // State to track overlay visibility
  const [showModal, setShowModal] = useState(false) // State to track modal visibility
  const [searchTerm, setSearchTerm] = useState('')
  const [mainStore, setMainStore] = useState(false)
  const [activeRow, setActiveRow] = useState(null) // State to track the active row

  const [paginationData, setPaginationData] = useState({page: 1, limit: 10}) // Initial pagination data
  const itemsPerPage = 10 // Adjust as needed
  const fetchedConnectedPlatformData = useAppSelector(
    (state) => state.fetchConnectedPlatform.fetchedConnectedPlatformData
  )
  const productVarientsFromMainChannel = useAppSelector(
    (state) => state.productVarientFromMain.productVarientFromMainChannel
  )

  const [matchedParentProduct, setMatchedParentProduct] = useState<any | null>(null)
  const dispatch = useAppDispatch()

  const target = useRef(null)
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const [dataFetched, setDataFetched] = useState(false)

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (
  //         !dataFetched &&
  //         fetchedConnectedPlatformData &&
  //         fetchedConnectedPlatformData.length > 0
  //       ) {
  //         const mainStorePlatform = fetchedConnectedPlatformData.find(
  //           (platform) => platform.isMainStore
  //         )
  //         if (mainStorePlatform) {
  //           const mainStorePlatformId = mainStorePlatform.id
  //           const response = await dispatch(
  //             getAllProductsVarientsFromMainChannelAsync({
  //               id: String(mainStorePlatformId),
  //               paginationdata: paginationData,
  //               search_string: searchTerm.trim() !== '' ? searchTerm.trim() : undefined,
  //             })
  //           )
  //           setPaginationData({
  //             ...paginationData,
  //           })
  //           setDataFetched(true)
  //         }
  //       }
  //     } catch (err) {
  //       console.error('Error fetching platform data:', err)
  //     }
  //   }

  //   fetchData()
  // }, [paginationData,searchTerm])

  const totalPages = Math.ceil(productVarientsFromMainChannel.length / paginationData.limit)

  const handlePagination = (pageNumber) => {
    setPaginationData((pre) => {
      return {...pre, page: pageNumber}
    })
  }

  const handleClose = () => {
    setShowOverlay(false)
    setShowModal(false)
    setMatchedParentProduct(null) // Reset matched product details when modal is closed
  }
  const handleIconClick = () => {
    setShowOverlay(!showOverlay)
  }

  const handleOverlayButtonClick = () => {
    setShowModal(true) // Open the modal
    setShowOverlay(false)

    // setShowOverlay(true)

    // Pass rowIndex to the onClick handler
    onCloseTooltip()
    // Pass rowIndex to the onClick handler
    onClick(rowIndex)
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value)
    setMatchedParentProduct(null) // Reset matched product details when search term changes
    setPaginationData({...paginationData, page: 1}) // Reset to first page when search term changes
  }

  const clickedProductVariant =
    rowIndex >= 0 && rowIndex < productVarients?.length ? productVarients[rowIndex] : null

  const {
    name,
    sku,
    platform_product: {image_set: [productImage] = []} = {},
  } = clickedProductVariant || {}

  const filteredProducts = productVarientsFromMainChannel.filter(
    (product: any) =>
      product?.platform_product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product?.sku === searchTerm
  )

  const handleLinkProductVariant = () => {
    if (matchedParentProduct) {
      dispatch(
        linkProductVarientAsync({
          variant_id: clickedProductVariant.id,
          parent_variant_id: matchedParentProduct?.platform_product?.id,
        })
      )
      toast.success('Variant linked successfully') // Show success toast
      handleClose() // Close the modal
      // setLinkingCompleted(true) // Set linking completion state to true
    } else {
      console.log('Please select a product before linking.')
    }
  }
  // console.log("pafent :",parent)
  return (
    <div>
      <Button variant={parent === null ? '' : ''} ref={target} onClick={handleIconClick}
          // onMouseEnter={() => setShowOverlay(true)}
          // onMouseLeave={() => setShowOverlay(false)}
      >
        <FontAwesomeIcon icon={faLink} />
        <span
          style={{
            color: linkingCompleted ? 'blue' : parent === null ? 'red' : 'blue',
            marginLeft: '5px',
            fontSize: '14px',
            marginBottom: '8px',
          }}
        >
          ●
        </span>
      </Button>
      <Overlay target={target.current} show={showOverlay} placement='bottom'>
        {({placement, arrowProps, show: _show, popper, hasDoneInitialMeasure, ...props}) => (
          <div
            {...props}
            style={{
              position: 'absolute',
              padding: ' 1px',
              color: 'white',
              borderRadius: 3,
              display: 'flex',
              flexDirection: 'column',
              // Adjust the size as needed
              ...props.style,
            }}
          >
            {parent === null ? (
              <Button
                variant='primary'
                onClick={handleOverlayButtonClick}
                style={{fontSize: '12px', padding: '5px'}}
              >
                Link to the Product
              </Button>
            ) : (

              <div className='d-flex flex-column '>
                 <Button
                variant='primary'
                onClick={handleOverlayButtonClick}
                style={{fontSize: '12px', padding: '5px'}}
                className='mb-2'
              >
                Link a Different Product
              </Button>
              <Link to={`/product-linked-variant-detail/${rowData?.id}/platform/${mainStoreData?.id}/product/${parent}`}>
                <Button variant='primary' style={{fontSize: '12px', padding: '5px'}}>
                View the Linked Product
              </Button></Link>
              </div>
          

              
            
            )}
          </div>
        )}
      </Overlay>
    </div>
  )
}

export default LinkButton
