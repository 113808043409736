import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {getSettingsOfSelectedPlatformAsync} from '../actions/platformSettingActions'
import { INITIAL_STATE_FOR_PLATFORM_SETTING_SLICE } from 'src/interfaces/Platform.interface'



const initialState:INITIAL_STATE_FOR_PLATFORM_SETTING_SLICE = {
  loadPlatformSetting: false,
  settings: null,
}

const platformSettingSlice = createSlice({
  name: 'platformSettingSlice',
  initialState: initialState,
  extraReducers: (builder) => {
    //get platform settings
    builder.addMatcher(isAnyOf(getSettingsOfSelectedPlatformAsync.pending), (state, action) => {
      state.loadPlatformSetting = true
    })
    builder.addMatcher(isAnyOf(getSettingsOfSelectedPlatformAsync.rejected), (state, action) => {
      state.loadPlatformSetting = false
    })
    builder.addMatcher(isAnyOf(getSettingsOfSelectedPlatformAsync.fulfilled), (state, action) => {
      state.settings = action.payload?.data
      state.loadPlatformSetting = false
    })
    //------------------------
  },
  reducers: {},
})

export const {} = platformSettingSlice.actions
export default platformSettingSlice.reducer