/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from 'src/marketConnect/helpers'
import {PasswordMeterComponent} from 'src/marketConnect/assets/ts/components'
import {useAuth} from '../core/Auth'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'
import {loginAsync, registrationAsync} from 'src/redux/actions/authActions'
import useAppToast from 'src/hooks/useAppToast'
import {setLoggingData} from 'src/redux/slices/authSlice'
import {accessSessionTokenKeyName, refreshSessionTokenKeyName} from 'src/utils/constants'
import ReCAPTCHA from "react-google-recaptcha";

const initialValues = {
  name: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: true,
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Full name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),

  password: Yup.string()
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$/,
    'Use 8 or more characters with at least one uppercase letter, one lowercase letter, one special character, and one number'
  )
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{8,}$/,
    'Use 8 or more characters with at least one uppercase letter, one lowercase letter, one special character, and one number'
  )
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [disableForCaptcha, setDisableForCaptcha] = useState(true)
  const {saveAuth, setCurrentUser} = useAuth()
  const {successToast, errorToast} = useAppToast()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {} = useAppSelector((state) => state)
  const { search } = useLocation(); // Hook to access the URL query parameters
  const queryParams = new URLSearchParams(search);
  const nameFromURL = queryParams.get('name');
  const emailFromURL = queryParams.get('email');
  const invitedByFromURL = queryParams.get('invited_by');
  const source = queryParams.get('SOURCE') || queryParams.get('source')
  const sourcesm = queryParams.get('source')


  console.log('source ---->', source)
  console.log("Name from URL:", nameFromURL);
  console.log("Email from URL:", emailFromURL);
  console.log("Invited by from URL:", invitedByFromURL);

  // Conditionally set the initialValues object based on the presence of URL query parameters
  const initialValues = {
    name: nameFromURL || '',
    email: emailFromURL || '',
    password: '',
    changepassword: '',
    acceptTerms: true,
    invited_by:invitedByFromURL||''
  };

  const registrationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Full name is required'),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[a-zA-Z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
        'Use 8 or more characters with at least one uppercase letter, one lowercase letter, one special character, and one number'
      )
      .max(50, 'Maximum 50 symbols')
      .required('Password is required'),
    changepassword: Yup.string()
      .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match")
      .required('Password confirmation is required'),
    acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        if(invitedByFromURL){
          const response = await dispatch(
            registrationAsync({
              name: nameFromURL,
              email: emailFromURL,
              password: values.password,
              user_type: 'USER',
              is_superuser: false,
              is_staff: false,
              is_active: true,
              invited_by:  Number(invitedByFromURL),
              source: source ? source : null
            })
          )
          if (response?.payload?.status_code === 201) {
            console.log("response sent in user inveted registration",response?.payload)
            successToast(response.payload.massage)
            setLoading(false)
            setTimeout(() => {
              dispatch(
                loginAsync({
                  email: values.email,
                  password: values.password,
                })
              ).then((loginResponse) => {
                if (loginResponse.payload?.access) {
                  localStorage.setItem(accessSessionTokenKeyName, loginResponse?.payload?.access)
                  localStorage.setItem(refreshSessionTokenKeyName, loginResponse?.payload?.refresh)
                  dispatch(
                    setLoggingData({
                      isLoggedIn: true,
                      userData: loginResponse.payload.data,
                      status: loginResponse.payload.status,
                    })
                  )
                  navigate('/')
                } else {
                  errorToast('Login failed')
                }
              })
            }, 1500)
          } else if (response?.payload?.status_code === 400) {
            errorToast(response.payload.data.massage)
            setLoading(false)
          }
          console.log('Response from registrationAsync:', response)

        }else{
          const response = await dispatch(
            registrationAsync({
              name: values.name,
              email: values.email,
              password: values.password,
              user_type: 'USER',
              is_superuser: false,
              is_staff: false,
              is_active: true,
              invited_by:null,
              source: source ? source : null
              // parent: invitedByFromURL ? Number(invitedByFromURL) : null,
            })
          )
          if (response?.payload?.status_code === 201) {
            successToast(response.payload.massage)
            setLoading(false)
            setTimeout(() => {
              dispatch(
                loginAsync({
                  email: values.email,
                  password: values.password,
                })
              ).then((loginResponse) => {
                if (loginResponse.payload?.access) {
                  localStorage.setItem(accessSessionTokenKeyName, loginResponse?.payload?.access)
                  localStorage.setItem(refreshSessionTokenKeyName, loginResponse?.payload?.refresh)
                  dispatch(
                    setLoggingData({
                      isLoggedIn: true,
                      userData: loginResponse.payload.data,
                      status: loginResponse.payload.status,
                    })
                  )
                  if( source && ['WOOCOMMERCE','woocommerce' ].includes(source) || sourcesm &&  ['WOOCOMMERCE','woocommerce' ].includes(sourcesm)   ){
                    navigate('/pricing')
                  }else{
                    navigate('/')
                  }
                } else {
                  errorToast('Login failed')
                }
              })
            }, 1500)
          } else if (response?.payload?.status === 400) {
            errorToast(response.payload.data.massage)
            setLoading(false)
          }
          console.log('Response from registrationAsync:', response)
        }
        

    
      } catch (error) {
        console.error('Error during registrationAsync:', error)
        setLoading(false)
      }
    },
  })
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])


  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-7'>
        {/* begin::Title */}
        <h2 className='text-dark fw-bolder'>Sign Up</h2>
        {/* end::Title */}

        {/* <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div> */}
      </div>
      {/* end::Heading */}

      {/* begin::Login options */}
      <div className='row g-3'>
        {/* begin::Col */}
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-md-6'>
          {/* begin::Google link */}
          {/* s */}
          {/* end::Google link */}
        </div>
        {/* end::Col */}
      </div>
      {/* end::Login options */}

      {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )} */}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6'>User Full Name</label>
        <input
          placeholder='Full name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.name && formik.errors.name,
            },
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-4' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Password</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters, numbers & symbols.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex align-items-center justify-content-center'>
        <p style={{ fontSize: '12.5px' }}>By signing up, you agree to our <span className='text-primary fw-bold'><a href="https://constacloud.com/tou.html" target='_blank'>Terms and Conditions</a></span> and <span className='text-primary fw-bold'><a href="https://constacloud.com/privacy-policy.html" target='_blank'>Privacy Policy</a></span></p>
      </div>
      {/* end::Form group */}
      <div className='captcha-box my-2 overflow-hidden' >
      <ReCAPTCHA
        // ref={(r) => this.recaptcha = r}
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY!}
        onChange={(val)=>{
          setDisableForCaptcha(false)
        }}
      />

      </div>

      {/* begin::Form group */}
      {/* <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the{' '}
            <a
              href='https://constacloud.com/tou.html'
              target='_blank'
              className='ms-1 link-primary'
            >
              Terms
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div> */}
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-sm btn-primary w-100 mb-3 mt-2'
          disabled={formik.isSubmitting || !formik.isValid || disableForCaptcha}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-sm btn-light-primary w-100 mb-2'
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
