import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import { getOrderDetailsAsync } from '../actions/orderActions'
import { OrderByFn } from 'react-table';
import { ORDER_ITEM } from 'src/app/_interfaces/order.interface';
import { OrderDetailsType } from 'src/app/_interfaces/orderDetail.interface';


interface OrderDetailState {
  loadingOrderDetails: boolean;
  orderDetails: OrderDetailsType|null; // Adjust the type as per your data structure
}

const initialState:OrderDetailState = {
loadingOrderDetails:false,
orderDetails:null
}

const orderDetailSlice = createSlice({
  name: 'orderDetailSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getOrderDetailsAsync.pending), (state, action) => {
      state.loadingOrderDetails = true
    })
    builder.addMatcher(isAnyOf(getOrderDetailsAsync.rejected), (state) => {
        state.loadingOrderDetails = false
    })
    builder.addMatcher(isAnyOf(getOrderDetailsAsync.fulfilled), (state, action) => {
        state.orderDetails = action?.payload
        state.loadingOrderDetails = false
    })
  },
  reducers: {
    getOrderDetails: (state, action: {payload: OrderDetailState; type: string}) => {
      state.loadingOrderDetails = action.payload?.loadingOrderDetails
      state.orderDetails = action?.payload.orderDetails
    },

  },
})

export const {getOrderDetails} = orderDetailSlice.actions
export default orderDetailSlice.reducer
