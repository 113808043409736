import {createSlice, isAnyOf} from '@reduxjs/toolkit'
import {loginAsync, registrationAsync, requestPasswordForgetAsync, userOauthLoginAsync, verifyForgetPasswordAsync, verifyRegistrationAsync} from '../actions/authActions'
import {USER_DATA} from 'src/app/_interfaces/auth.interface'

type LOGIN_PAYLOAD = {
  isLoggedIn: boolean
  userData: USER_DATA | null
  status?: number
}

const initialState: {
  authLoading: boolean
  isLoggedIn: boolean
  userDetail: null | USER_DATA
  status?: number
  forgetPasswordToken:string|null
  forgetPasswordTokenLoading:boolean
  shopifyConnectFirstTimeMessageShow:boolean

} = {
  authLoading: false,
  isLoggedIn: false,
  userDetail: null,
  status: undefined,
  forgetPasswordToken:'',
  forgetPasswordTokenLoading:false,
  shopifyConnectFirstTimeMessageShow:false

}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  extraReducers: (builder) => {
    // registration end
    builder.addMatcher(isAnyOf(registrationAsync.pending), (state) => {
      console.log('registrationAsync is in pending state ')
    })
    builder.addMatcher(isAnyOf(registrationAsync.rejected), (state) => {
      console.log('registrationAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(registrationAsync.fulfilled), (state, action) => {
      console.log('register fullfiled')
      state.authLoading = false
    })
    // registration end
    // login start
    builder.addMatcher(isAnyOf(loginAsync.pending, userOauthLoginAsync.pending ), (state) => {
      console.log('loginAsync is in pending state ')
    })
    builder.addMatcher(isAnyOf(loginAsync.rejected, userOauthLoginAsync.rejected ), (state) => {
      console.log('loginAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(loginAsync.fulfilled), (state, action) => {
      // state.userDetail = action.payload.data
      if (action?.payload?.access) {
        state.isLoggedIn = true
      }

      state.status = action?.payload?.payload?.status
    })

    builder.addMatcher(isAnyOf(userOauthLoginAsync.fulfilled), (state, action) => {
      // state.userDetail = action.payload.data
      if (action?.payload?.access) {
        state.isLoggedIn = true
      }
      state.status = action?.payload?.payload?.status
    })

    //forgot passward
    builder.addMatcher(isAnyOf(requestPasswordForgetAsync.pending), (state) => {
      console.log('requestPasswordForgetAsync is in pending state ')
    })
    builder.addMatcher(isAnyOf(requestPasswordForgetAsync.rejected), (state) => {
      console.log('requestPasswordForgetAsync is in rejected state ')
    })
    builder.addMatcher(isAnyOf(requestPasswordForgetAsync.fulfilled), (state, action) => {
      console.log('requestPasswordForgetAsync in fulfilled')
      state.authLoading = false
    })

    //verifyuserforpassword
    builder.addMatcher(isAnyOf(verifyForgetPasswordAsync.pending), (state) => {
       state.forgetPasswordTokenLoading=true
    })
    builder.addMatcher(isAnyOf(verifyForgetPasswordAsync.rejected), (state) => {
    
      state.forgetPasswordTokenLoading=true
    })
    builder.addMatcher(isAnyOf(verifyForgetPasswordAsync.fulfilled), (state, action) => {
      state.forgetPasswordTokenLoading=false
      
    })



  },
  reducers: {
    // use this reducer for login logout
    setLoggingData: (state, action: {payload: LOGIN_PAYLOAD; type: string}) => {
      state.isLoggedIn = action.payload?.isLoggedIn
      state.userDetail = action.payload.userData
    },
    setForgetPasswordToken:(state, action: {payload: {token:string}; type: string}) => {

      state.forgetPasswordToken = action?.payload?.token
    },

    setShopifyConnectFirstTimeMessageShow: (state, action:{payload:boolean} )=>{
        state.shopifyConnectFirstTimeMessageShow = action.payload
    },
  },
})

export const {setLoggingData,setForgetPasswordToken, setShopifyConnectFirstTimeMessageShow} = authSlice.actions

export default authSlice.reducer
