import React from 'react'

const ExportModal = () => {
  return (
    <div>
           <div
              className='modal fade'
              id='kt_customers_export_modal'
              tabIndex={-1}
              aria-hidden='true'
            >
              {/*<!--begin::Modal dialog-->  */}
              <div className='modal-dialog modal-dialog-centered mw-650px'>
                {/*<!--begin::Modal content-->  */}
                <div className='modal-content'>
                  {/*<!--begin::Modal header-->  */}
                  <div className='modal-header'>
                    {/*<!--begin::Modal title-->  */}
                    <h2 className='fw-bold'>Export Customers</h2>
                    {/*<!--end::Modal title-->  */}

                    {/*<!--begin::Close-->  */}
                    <div
                      id='kt_customers_export_close'
                      className='btn btn-icon btn-sm btn-active-icon-primary'
                    >
                      <i className='ki-duotone ki-cross fs-1'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>{' '}
                    </div>
                    {/*<!--end::Close-->  */}
                  </div>
                  {/*<!--end::Modal header-->  */}

                  {/*<!--begin::Modal body-->  */}
                  <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
                    {/*<!--begin::Form-->  */}
                    <form
                      id='kt_customers_export_form'
                      className='form fv-plugins-bootstrap5 fv-plugins-framework'
                      action='#'
                    >
                      {/*<!--begin::Input group-->  */}
                      <div className='fv-row mb-10'>
                        {/*<!--begin::Label-->  */}
                        <label className='fs-5 fw-semibold form-label mb-5'>
                          Select Export Format:
                        </label>
                        {/*<!--end::Label-->  */}

                        {/*<!--begin::Input-->  */}
                        <select
                          name='country'
                          data-control='select2'
                          data-placeholder='Select a format'
                          data-hide-search='true'
                          className='form-select form-select-solid select2-hidden-accessible'
                          data-select2-id='select2-data-15-c34h'
                          tabIndex={-1}
                          aria-hidden='true'
                          data-kt-initialized={1}
                        >
                          <option value='excell' data-select2-id='select2-data-17-ylm8'>
                            Excel
                          </option>
                          <option value='pdf'>PDF</option>
                          <option value='cvs'>CVS</option>
                          <option value='zip'>ZIP</option>
                        </select>
                        <span
                          className='select2 select2-container select2-container--bootstrap5'
                          dir='ltr'
                          data-select2-id='select2-data-16-p31v'
                          style={{width: '100%;'}}
                        >
                          <span className='selection'>
                            <span
                              className='select2-selection select2-selection--single form-select form-select-solid'
                              role='combobox'
                              aria-haspopup='true'
                              aria-expanded='false'
                              tabIndex={0}
                              aria-disabled='false'
                              aria-labelledby='select2-country-j9-container'
                              aria-controls='select2-country-j9-container'
                            >
                              <span
                                className='select2-selection__rendered'
                                id='select2-country-j9-container'
                                role='textbox'
                                aria-readOnly='true'
                                title='Excel'
                              >
                                Excel
                              </span>
                              <span className='select2-selection__arrow' role='presentation'>
                                <b role='presentation'></b>
                              </span>
                            </span>
                          </span>
                          <span className='dropdown-wrapper' aria-hidden='true'></span>
                        </span>
                        {/*<!--end::Input-->  */}
                      </div>
                      {/*<!--end::Input group-->  */}

                      {/*<!--begin::Input group-->  */}
                      <div className='fv-row mb-10 fv-plugins-icon-container'>
                        {/*<!--begin::Label-->  */}
                        <label className='fs-5 fw-semibold form-label mb-5'>
                          Select Date Range:
                        </label>
                        {/*<!--end::Label-->  */}

                        {/*<!--begin::Input-->  */}
                        <input
                          className='form-control form-control-solid flatpickr-input'
                          placeholder='Pick a date'
                          name='date'
                          type='hidden'
                        />
                        <input
                          className='form-control form-control-solid form-control input'
                          placeholder='Pick a date'
                          tabIndex={0}
                          type='text'
                        />
                        {/*<!--end::Input-->  */}
                        <div className='fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback'></div>
                      </div>
                      {/*<!--end::Input group-->  */}

                      {/*<!--begin::Row-->  */}
                      <div className='row fv-row mb-15'>
                        {/*<!--begin::Label-->  */}
                        <label className='fs-5 fw-semibold form-label mb-5'>Payment Type:</label>
                        {/*<!--end::Label-->  */}

                        {/*<!--begin::Radio group-->  */}
                        <div className='d-flex flex-column'>
                          {/*<!--begin::Radio button-->  */}
                          <label className='form-check form-check-custom form-check-sm form-check-solid mb-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value={1}
                              name='payment_type'
                            />
                            <span className='form-check-label text-gray-600 fw-semibold'>All</span>
                          </label>
                          {/*<!--end::Radio button-->  */}

                          {/*<!--begin::Radio button-->  */}
                          <label className='form-check form-check-custom form-check-sm form-check-solid mb-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value='2'
                              name='payment_type'
                            />
                            <span className='form-check-label text-gray-600 fw-semibold'>Visa</span>
                          </label>
                          {/*<!--end::Radio button-->  */}

                          {/*<!--begin::Radio button-->  */}
                          <label className='form-check form-check-custom form-check-sm form-check-solid mb-3'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value='3'
                              name='payment_type'
                            />
                            <span className='form-check-label text-gray-600 fw-semibold'>
                              Mastercard
                            </span>
                          </label>
                          {/*<!--end::Radio button-->  */}

                          {/*<!--begin::Radio button-->  */}
                          <label className='form-check form-check-custom form-check-sm form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              value='4'
                              name='payment_type'
                            />
                            <span className='form-check-label text-gray-600 fw-semibold'>
                              American Express
                            </span>
                          </label>
                          {/*<!--end::Radio button-->  */}
                        </div>
                        {/*<!--end::Input group-->  */}
                      </div>
                      {/*<!--end::Row-->  */}

                      {/*<!--begin::Actions-->  */}
                      <div className='text-center'>
                        <button
                          type='reset'
                          id='kt_customers_export_cancel'
                          className='btn btn-light me-3'
                        >
                          Discard
                        </button>

                        <button
                          type='submit'
                          id='kt_customers_export_submit'
                          className='btn btn-primary'
                        >
                          <span className='indicator-label'>Submit</span>
                          <span className='indicator-progress'>
                            Please wait...{' '}
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        </button>
                      </div>
                      {/*<!--end::Actions-->  */}
                    </form>
                    {/*<!--end::Form-->  */}
                  </div>
                  {/*<!--end::Modal body-->  */}
                </div>
                {/*<!--end::Modal content-->  */}
              </div>
              {/*<!--end::Modal dialog-->  */}
            </div>
    </div>
  )
}

export default ExportModal