import {Formik} from 'formik'
import React, {FC, useState} from 'react'
import {Modal} from 'react-bootstrap'
import AppCustomEditor from 'src/utils/CustomEditor'
import Select from 'react-select'
import {Link, useRoutes} from 'react-router-dom'
import {VENDOR_USER} from 'src/app/_interfaces/customer.interface'
import {useAppSelector} from 'src/redux/storeHooks'

type AddUpdateVendorDataProps = {}

const initialValues = {
  name: '',
  email: '',
  company: '',
  description: '',
  shipping_info: {
    addressLine1: '',
    addressLine2: '',
    town: '',
    state: '',
    pincode: '',
    originCountry: '',
    useAsBillingAddress: false,
  },
  billing_info: {
    tinNumber: '',
    accountNumber: '',
    paymentTerms: '',
    paymentDescription: '',
  },
}

const AddUpdateVendorData: FC<AddUpdateVendorDataProps> = () => {
  const {selectedVendorData} = useAppSelector((state) => state.customer)

  return (
    <div className='card'>
      <Formik
        initialValues={selectedVendorData ? selectedVendorData : initialValues}
        onSubmit={() => {}}
      >
        {({values, handleSubmit, handleChange, setFormikState}) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className='card-header'>
                <div className='card-title'>Add Vendor</div>
                <div className='card-toolbar'>
                  <button type='submit' className='btn btn-sm btn-success fw-bold ms-2'>
                    Save
                  </button>
                </div>
              </div>
              <Modal.Body className='p-8'>
                <div className='row'>
                  <div className='mb-4 col-6'>
                    <label htmlFor='exampleFormControlInput1' className='required form-label'>
                      Name
                    </label>
                    <input
                      className='form-control form-control-lg'
                      placeholder='Name'
                      name='name'
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='mb-4 col-6'>
                    <label htmlFor='exampleFormControlInput1' className='required form-label'>
                      Email
                    </label>
                    <input
                      className='form-control form-control-lg'
                      placeholder='Email'
                      name='email'
                      value={values.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='mb-4 col-sm-6 '>
                  <label htmlFor='exampleFormControlInput1' className='required form-label'>
                    Company
                  </label>
                  <input
                    className='form-control form-control-lg'
                    placeholder='Company'
                    name='company'
                    value={values.company}
                    onChange={handleChange}
                  />
                </div>
                <div className='mb-4 col-12'>
                  <label htmlFor='exampleFormControlInput1' className='required form-label'>
                    Description
                  </label>
                  <AppCustomEditor
                    onChangeData={(st) => {
                      setFormikState((pre) => {
                        return {...pre, values: {...pre.values, description: st}}
                      })
                    }}
                    value={values.description}
                    placeholder='Add Description Here...'
                  />
                </div>

                <div className='accordion' id='kt_accordion_1'>
                  <div className='accordion-item'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_1'>
                      <button
                        className='accordion-button fs-5 fw-bold text-secondary collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_1'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_1'
                      >
                        Shipment Info
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_1'
                      className='accordion-collapse collapse show'
                      aria-labelledby='kt_accordion_1_header_1'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <div className='row'>
                          <div className='mb-4 col-sm-6'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Address Line 1
                            </label>
                            <input
                              className='form-control form-control-lg'
                              placeholder='Address Line 1'
                              name='shipping_info.addressLine1'
                              value={values.shipping_info.addressLine1}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='mb-4 col-sm-6'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Address Line 2
                            </label>
                            <input
                              className='form-control form-control-lg'
                              placeholder='Address Line 2'
                              name='shipping_info.addressLine2'
                              value={values.shipping_info.addressLine2}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='mb-4 col-sm-4'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Town
                            </label>
                            <input
                              className='form-control form-control-lg'
                              placeholder='Town'
                              name='shipping_info.town'
                              value={values.shipping_info.town}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='mb-4 col-sm-4'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              State
                            </label>
                            <input
                              className='form-control form-control-lg'
                              placeholder='State'
                              name='shipping_info.state'
                              value={values.shipping_info.state}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='mb-4 col-sm-4'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Postal Code
                            </label>
                            <input
                              className='form-control form-control-lg'
                              placeholder='Postal Code'
                              name='shipping_info.pincode'
                              value={values.shipping_info.pincode}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='mb-4 col-sm-6'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Country of Origin
                            </label>
                            <Select placeholder='Country of Origin' />
                          </div>
                          <div className='col-sm-6'>
                            <div className='d-flex form-check form-switch form-check-lg p-0 align-items-center h-100'>
                              <label
                                className='form-label required mb-0'
                                htmlFor='flexSwitchDefault'
                              >
                                Use as a billing address?
                              </label>
                              <input
                                className='ms-4 form-check-input h-30px w-50px'
                                type='checkbox'
                                value=''
                                id='flexSwitchDefault'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item mt-4 border-top'>
                    <h2 className='accordion-header' id='kt_accordion_1_header_2'>
                      <button
                        className='accordion-button fs-5 fw-bold text-secondary collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_accordion_1_body_2'
                        aria-expanded='false'
                        aria-controls='kt_accordion_1_body_2'
                      >
                        Billing Info
                      </button>
                    </h2>
                    <div
                      id='kt_accordion_1_body_2'
                      className='accordion-collapse collapse'
                      aria-labelledby='kt_accordion_1_header_2'
                      data-bs-parent='#kt_accordion_1'
                    >
                      <div className='accordion-body'>
                        <div className='my-4 col-12'>
                          <label htmlFor='exampleFormControlInput1' className='required form-label'>
                            Tax Identification Number
                          </label>
                          <input
                            className='form-control form-control-lg'
                            placeholder='Tax Identification Number'
                            name='billing_info.tinNumber'
                            value={values.billing_info.tinNumber}
                            onChange={handleChange}
                          />
                        </div>
                        <div className='border p-4 rounded'>
                          <h4 className='mb-4'>Payment Info</h4>
                          <div className='mb-4 col-12'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Bank Account Number
                            </label>
                            <input
                              className='form-control form-control-lg'
                              placeholder='Bank Account Number'
                              name='billing_info.accountNumber'
                              value={values.billing_info.accountNumber}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='mb-4 col-12'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Payment Terms
                            </label>
                            <input
                              className='form-control form-control-lg'
                              placeholder='Payment Terms'
                              name='billing_info.paymentTerms'
                              value={values.billing_info.paymentTerms}
                              onChange={handleChange}
                            />
                          </div>
                          <div className='mb-4 col-12'>
                            <label
                              htmlFor='exampleFormControlInput1'
                              className='required form-label'
                            >
                              Payment Description
                            </label>
                            <AppCustomEditor
                              onChangeData={(st) => {
                                setFormikState((pre) => {
                                  return {
                                    ...pre,
                                    values: {
                                      ...pre.values,
                                      billing_info: {
                                        ...pre.values.billing_info,
                                        paymentDescription: st,
                                      },
                                    },
                                  }
                                })
                              }}
                              value={values.billing_info.paymentDescription}
                              placeholder='Payment Description...'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default AddUpdateVendorData
