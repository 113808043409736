import React, {FC, useState} from 'react'
import OrderReportChart from './OrderReportChart'
import {useAppSelector} from 'src/redux/storeHooks'
import {faBullhorn, faNewspaper} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Article from './Article'
import { KTSVG } from 'src/marketConnect/helpers'

const Dashboard: FC = () => {
  const {mainStoreData} = useAppSelector((state) => state.fetchConnectedPlatform)
  const news = [
    {
      date: '1st Jan',
      title: 'News Title 1',
      description:
        ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit',
      link: 'https://example.com/news1',
    },
    {
      date: '2nd Jan',
      title: 'News Title 2',
      description:
        ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit eum possimus aliquam repellendus eaque iste modi aperiam dolores cum incidunt, ex, laudantium ea voluptatem doloribus neque temporibus maiores, facere nihil.',
      link: 'https://example.com/news2',
    },
    // {
    //   date: '3rd Jan',
    //   title: 'News Title 3',
    //   description:
    //     ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit eum possimus aliquam repellendus eaque iste modi aperiam dolores cum incidunt,',
    //   link: 'https://example.com/news3',
    // },
    // {
    //   date: '4th Jan',
    //   title: 'News Title 4',
    //   description:
    //     ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit eum possimus aliquam repellendus ',
    //   link: 'https://example.com/news4',
    // },
    // {
    //   date: '5th Jan',
    //   title: 'News Title 5',
    //   description:
    //     ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. ',
    //   link: 'https://example.com/news5',
    // },
    // {
    //   date: '6th Jan',
    //   title: 'News Title 6',
    //   description:
    //     ' Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit eum possimus aliquam repellendus eaque iste modi aperiam dolores cum incidunt, ex, laudantium ea voluptatem doloribus neque temporibus maiores, facere nihil.',
    //   link: 'https://example.com/news6',
    // },
  ]

  const itemsPerPage = 3
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(news.length / itemsPerPage)

  const handleClickNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleClickPrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const currentNews = news.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)

  return (
    <div>
      <div className='row'>
        <div className='col-md-8 col-12'>
          {/* stat card section  */}
          <div className='row g-4'>
            <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
              <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary h-100'>
              <div className='d-flex flex-column justify-content-between ' >
                <div className='d-inline-flex justify-content-between' >
                  <div>
                    <p className='h5 mb-0 text-secondary'>Total SKU's </p>
                  </div>
                  <div>
                  <KTSVG
                      path='media/icons/duotune/ecommerce/ecm009.svg'
                      className='svg-icon-primary svg-icon-2hx ml-3 svg-icon-primary'
                    />
                  </div>
                </div>
                <div className='mt-3' >
                    <p className='fs-5 fw-bold mb-0 text-end text-primary'>9999</p>
                </div>
              </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
              <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary h-100'>
              <div className='d-flex flex-column justify-content-between ' >
                <div className='d-inline-flex justify-content-between' >
                  <div>
                    <p className='h5 mb-0 text-secondary'>Primary Channel</p>
                  </div>
                  <div>
                    <div className='d-flex align-items-center'>
                      <img
                        src={mainStoreData?.platform_info?.logo_ref}
                        className='h-30px w-30px ms-md-2'
                        alt='Primary Platform Logo'
                      />
                    </div>
                  </div>
                </div>
                <div className='mt-2' >
                </div>
              </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
              <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary h-100'>
              <div className='d-flex flex-column justify-content-between ' >
                <div className='d-inline-flex justify-content-between' >
                  <div>
                    <p className='h5 mb-0 text-secondary'>Synced SKU's</p>
                  </div>
                  <div>
                  <KTSVG
                        path='media/icons/duotune/coding/cod007.svg'
                        className='svg-icon-primary svg-icon-2hx ml-3 svg-icon-success'
                      />
                  </div>
                </div>
                <div className="mt-3">
                <p className='fs-5 fw-bold mb-0 text-primary text-end'>9999</p>
                </div>
              </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6 col-12 col-sm-6 '>
              <div className='card p-4 border-0 shadow-sm bg-hover-light-secondary h-100'>
                <div className='d-flex flex-column justify-content-between ' >
                  <div className='d-inline-flex justify-content-between' >
                      <div>
                        <p className='h5 mb-0 text-secondary'>Order Sync Errors</p>
                      </div>
                      <div>
                      <i className="bi bi-exclamation-triangle-fill fs-3 text-danger"></i>
                      </div>    
                  </div>
                  <div className="mt-2">
                    <p className='fs-5 fw-bold mb-0 text-primary text-end'>9999</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* stat card section  */}
          <div className='chart-section mt-4'>
            <OrderReportChart className='' />
          </div>
        </div>
        <div className='col-md-4 col-12'>
          <div className='card h-50 shadow-sm border-0 mb-2'>
            <div className='card-header border-0 bg-white ribbon ribbon-start ribbon-clip' style={{minHeight:'50px'}} >
              <div className='ribbon-label'>
                <p className='fs-5 fw-bold mb-0'>News & Updates</p>
                <span className='ribbon-inner bg-primary'></span>
              </div>
            </div>
            <div className='card-body p-0 overflow-y-scroll'>
              <ul style={{listStyle: 'none'}} className='p-4 mb-0'>
                {currentNews.map((item, index) => (
                  <li key={'newsitem' + index} className='fs-5 '>
                    <Article item={item} />
                    <div className='separator my-1'></div>
                  </li>
                ))}
              </ul>
              <nav>
                <ul className="pagination p-2 justify-content-end" >
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
                </nav>
            </div>
           
          </div>
          <div className='card h-50 shadow-sm border-0'>
            <div className='card-header border-0 bg-white ribbon ribbon-start ribbon-clip' style={{minHeight:'50px'}} >
              <div className='ribbon-label'>
                {' '}
                <p className='fs-5 fw-bold mb-0'>Setup Guide</p>
                <span className='ribbon-inner bg-primary'></span>
              </div>
            </div>
            <div className='card-body px-2'>
              <ol>
                {[1, 2, 3, 4,].map((item) => {
                  return (
                    <li key={'newsitem' + item} className='fs-5 my-2 mb-0'>
                      This is a sample update / news
                    </li>
                  )
                })}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
