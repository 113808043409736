import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../marketConnect/layout/MasterLayout'
import ManageCustomers from '../pages/admin-panel/ManageCustomers'
import Report from '../pages/admin-panel/Report'

const SuperAdminRoutes = () => {
  return (
    <Routes>
      <Route>
        <Route index element={<Navigate to={'/dashboard'} />} />
        {/* <Route path='/admin/*' element={<Navigate to={'/customers'} />} /> */}
        <Route path='/dashboard' element={<ManageCustomers />} />
        <Route path='/reports' element={<Report />} />
        <Route path='/reports/:userId' element={<Report />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}


export {SuperAdminRoutes}
