import {faArrowRightArrowLeft, faDeleteLeft, faPlus} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {Field, Formik} from 'formik'
import React, {FC, useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import {PlatformSettingDataTypesValues} from 'src/app/_interfaces/setting.interface'
import useAppToast from 'src/hooks/useAppToast'
import {
  FIELD_MAPPING_DATA,
  FIELD_MAPPING_TYPE,
  PLATFORM_FIELD,
  SECTION_WISE_SAVED_FIELD_MAPPING,
  ShippingMethodMapping,
  platformfielddata_set,
} from 'src/interfaces/Platform.interface'
import {
  deleteFieldMappingAsync,
  getPlatformFieldsAsync,
} from 'src/redux/actions/productActions'
import {getPlatformParentFieldDataCategoriesAsync} from 'src/redux/actions/templateAndPricingActions'
import {useAppDispatch} from 'src/redux/storeHooks'
import Loader from 'src/utils/Loader'
import { getSavedFieldMappingsDataAsyncByMappingId } from 'src/redux/actions/platformSettingActions'
import clsx from 'clsx'

const MappingInput: FC<{
  sourceSide?:number
  destinationSide?:number
  selectedPlatformId: number
  isMappingVisible: boolean
  handleChange: (e: React.ChangeEvent<any>) => void
  field_mapping: FIELD_MAPPING_TYPE
  savedMapping : SECTION_WISE_SAVED_FIELD_MAPPING|null|undefined
  settingName:string
  settingId:number|null
  setFieldMappings: React.Dispatch<React.SetStateAction<FIELD_MAPPING_DATA[]>>
  fieldMappings: FIELD_MAPPING_DATA[]
  
}> = ({ field_mapping, isMappingVisible,sourceSide,destinationSide, savedMapping, settingId,settingName, setFieldMappings, fieldMappings}) => {

  
  const [platformFields, setPlatformFields] = useState<{
    sourcePlatformField: null | PLATFORM_FIELD
    destinationPlatformField: null | PLATFORM_FIELD
  }>({
    sourcePlatformField: null,
    destinationPlatformField: null,
  })
  const [shipmentMappingFieldData, setShipmentMappingFieldData] = useState<{
    destinationPlatformFieldData: platformfielddata_set[] | []
    sourcePlatformFieldData: platformfielddata_set[] | []
  }>({sourcePlatformFieldData: [], destinationPlatformFieldData: []})

  const [shippingMethodMappings, setShippingMethodMappings] = useState<ShippingMethodMapping[]>([
    {
      id: null,
      source_platform_data_id: '',
      destination_platform_data_id: '',
      destination_platform_data_str: '',
      source_platform_data_str: '',
    },
  ])
  

  const [loadingMappingFields, setLoadingMappingFields] = useState(true)
  const [loadingShippingMappingFieldData, setLoadingShippingMappingFieldData] = useState(true)
  const dispatch = useAppDispatch()
  const { successToast } = useAppToast()

  //for getting saved values
  useEffect(() => {
      let mappings:ShippingMethodMapping[]|[] = []
      if(savedMapping && platformFields.destinationPlatformField?.id ){
        
        setLoadingMappingFields(true)
        dispatch(getSavedFieldMappingsDataAsyncByMappingId({mappingId:savedMapping.id}))
        .then((response)=>{
          
          if(response?.payload?.fieldmappingdata_set?.length){
             mappings = response?.payload.fieldmappingdata_set.map((item) => ({
              id: item?.id,
              destination_platform_data_id: item?.destination_platform_data?.id + '' ?? '',
              source_platform_data_id: item?.source_platform_data ? item?.source_platform_data?.id + '' : null,
              destination_platform_data_str: item.destination_platform_data_str ?? '',
              source_platform_data_str: item.source_platform_data_str ?? '',
            }))
          }
        })
        .catch((error)=>{
          console.log('unable to get field mappings', error);
        })
        .finally(()=>{
          if(platformFields.destinationPlatformField?.id && platformFields.sourcePlatformField?.id ){
            setShippingMethodMappings(mappings)
            setFieldMappings((pre)=>{
              return [...pre,
                {
                  groupCode:settingName,
                  fieldCode: field_mapping.field_code,
                  savedSettingId:settingId,
                  savedMappingId:savedMapping?.id!,
                  destinationFieldId:platformFields.destinationPlatformField?.id!,
                  sourceFieldId:platformFields.sourcePlatformField?.id!,
                  fieldMappingDataSet:mappings
                }
              ]
            })

          }
          if( platformFields.destinationPlatformField?.id && platformFields.sourcePlatformField === null){
            setShippingMethodMappings([mappings[0]])
            setFieldMappings((pre)=>{
              return [...pre,
                {
                  groupCode:settingName,
                  fieldCode: field_mapping.field_code,
                  savedSettingId:settingId,
                  savedMappingId:savedMapping?.id!,
                  destinationFieldId:platformFields.destinationPlatformField?.id!,
                  sourceFieldId:null,
                  fieldMappingDataSet:[mappings[0]]
              
                }
              ]
            })
          }
          
          setLoadingMappingFields(false)
        })
      }else{
        setLoadingMappingFields(false)

      }
  }, [ destinationSide, platformFields, savedMapping])

  //for getting platform fields and fields data
  useEffect(() => {

    if (sourceSide && destinationSide) {
      setLoadingShippingMappingFieldData(true)
      Promise.all([
        dispatch(
          getPlatformFieldsAsync({      //call for get platform field for source side
            platformId:Number(sourceSide),
            otherParams: {field_code: field_mapping?.field_code},
          })
        ).then((response) => {
          if (response.payload?.length) {
            setPlatformFields((pre) => {
              return {...pre, sourcePlatformField: response.payload[0]}
            })
            dispatch(
              getPlatformParentFieldDataCategoriesAsync({platform_Id: response.payload[0].id})
            ).then((response) => {
              setShipmentMappingFieldData((pre) => {
                return {...pre, sourcePlatformFieldData: response.payload}
              })
            })
          }else{
            // setLoadingLocationOption(false)
          }
        }),

       
        dispatch(
          getPlatformFieldsAsync({    //call for get platform field for destination side
            platformId:Number(destinationSide),
            otherParams: {field_code: field_mapping?.field_code},
          })
        ).then((response) => {
          if (response.payload?.length) {
            setPlatformFields((pre) => {
              return {...pre, destinationPlatformField: response.payload[0]}
            })
            dispatch(
              getPlatformParentFieldDataCategoriesAsync({platform_Id: response.payload[0].id})
            ).then((response) => {
              setShipmentMappingFieldData((pre) => {
                return {...pre, destinationPlatformFieldData: response.payload}
              })
         
            })
          
          }
      
        }),

      ]).finally(()=>{
        setLoadingShippingMappingFieldData(false)
      })
    }
  }, [isMappingVisible])

  
  if (loadingShippingMappingFieldData || loadingMappingFields) return <Loader />

    return (
            <div className={clsx('row ', platformFields.sourcePlatformField === null || field_mapping.isDefault  ? 'w-sm-50' : '' )}>
              <div className='mapping'>
                <div className='p-2 '>
                  <div>
                    <label className='form-label'>{field_mapping?.display_name}</label>
                  </div>
                  <div className='border-bottom my-2 border-2 my-2'></div>
                  {shippingMethodMappings?.length
                    ? shippingMethodMappings?.map((mapping, index) => {
                        return (
                          <div key={index} className='mb-sm-0 mb-2 p-2'>
                            <div className='row'>
                           {platformFields.sourcePlatformField && !field_mapping?.isDefault ? ( platformFields.sourcePlatformField?.input_type === 'SELECT' ? (
                                <div className='col-12 col-sm-5'>
                                  <div>
                                    <label
                                      htmlFor={`primaryStoreShippingMethod_${index}`}
                                      className='form-check-label'
                                    >
                                      Primary Store Method
                                    </label>
                                    <select
                                      className='form-select form-select-lg mt-2'
                                      id='primaryStoreShippingMethod'
                                      name={`mappings[${index}][source_platform_data_id]`}
                                      value={mapping?.source_platform_data_id}
                                      onChange={(ev) => {
                                        let val: ShippingMethodMapping = {
                                          id: mapping.id ? mapping.id : null,
                                          source_platform_data_id: ev.target.value,
                                        }

                                        if (
                                          platformFields.destinationPlatformField
                                            ?.input_type === 'SELECT'
                                        ) {
                                          val.destination_platform_data_id =
                                            mapping.destination_platform_data_id
                                        } else {
                                          val.destination_platform_data_str =
                                            mapping.destination_platform_data_str
                                        }

                                        let shadowArrayMapping = [...shippingMethodMappings]
                                        shadowArrayMapping[index] = val

                                        let shadowFieldMappings = [...fieldMappings]
                                        let existingFieldMapping = shadowFieldMappings.find((item)=>(item.fieldCode===field_mapping.field_code))
                                        let existingFieldMappingIndex = shadowFieldMappings.findIndex((item)=>(item.fieldCode===field_mapping.field_code))
                                        
                                        if(existingFieldMapping){
                                          existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                          shadowFieldMappings[existingFieldMappingIndex] = existingFieldMapping
                                          setFieldMappings(shadowFieldMappings)
                                        }else{
                                          setFieldMappings((pre)=>{
                                            return [...pre,
                                              {
                                                groupCode:settingName,
                                                fieldCode: field_mapping.field_code,
                                                savedSettingId:settingId,
                                                savedMappingId:savedMapping?.id!,
                                                destinationFieldId:platformFields.destinationPlatformField?.id!,
                                                sourceFieldId:platformFields.sourcePlatformField?.id ?? null ,
                                                fieldMappingDataSet:shadowArrayMapping
                                              }
                                            ]
                                          })
                                        }
                                       

                                        setShippingMethodMappings([...shadowArrayMapping])
                                      }}
                                    >
                                      <option value=''>Open this select menu</option>
                                      {shipmentMappingFieldData
                                        ?.sourcePlatformFieldData?.length &&
                                        shipmentMappingFieldData.sourcePlatformFieldData.map(
                                          (item: platformfielddata_set) => {
                                            return <option value={item.id}>{item.api_name}</option>
                                          }
                                        )}
                                    </select>
                                  </div>
                                </div>
                              ) : (
                                <div className='col-12 col-sm-5'>
                                  <div className=''>
                                    <label
                                      htmlFor={`primaryStoreShippingMethod_${index}`}
                                      className='form-check-label'
                                    >
                                      Primary Store Method
                                    </label>
                                    <input
                                      type='text'
                                      placeholder='Add a method'
                                      className='form-control form-control-sm mt-2'
                                      name={`mappings[${index}][source_platform_data_str]`}
                                      value={mapping.source_platform_data_str}
                                      onChange={(ev) => {
                                        let val: ShippingMethodMapping = {
                                          id: mapping.id ? mapping.id : null,
                                          source_platform_data_str: ev.target.value,
                                        }
                                        if (
                                          platformFields.destinationPlatformField
                                            ?.input_type === 'SELECT'
                                        ) {
                                          val.destination_platform_data_id =
                                            mapping.destination_platform_data_id
                                        } else {
                                          val.destination_platform_data_str =
                                            mapping.destination_platform_data_str
                                        }
                                        let shadowArrayMapping = [...shippingMethodMappings]
                                        shadowArrayMapping[index] = val

                                        let shadowFieldMappings = [...fieldMappings]
                                        let existingFieldMapping = shadowFieldMappings.find((item)=>(item.fieldCode===field_mapping.field_code))
                                        let existingFieldMappingIndex = shadowFieldMappings.findIndex((item)=>(item.fieldCode===field_mapping.field_code))
                                        
                                        if(existingFieldMapping){
                                          existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                          shadowFieldMappings[existingFieldMappingIndex] = existingFieldMapping
                                          setFieldMappings(shadowFieldMappings)
                                        }else{
                                          setFieldMappings((pre)=>{
                                            return [...pre,
                                              {
                                                groupCode:settingName,
                                                fieldCode: field_mapping.field_code,
                                                savedSettingId:settingId,
                                                savedMappingId:savedMapping?.id!,
                                                destinationFieldId:platformFields.destinationPlatformField?.id!,
                                                sourceFieldId:platformFields.sourcePlatformField?.id ?? null ,
                                                fieldMappingDataSet:shadowArrayMapping
                                              }
                                            ]
                                          })
                                        }
                                       
                                        setShippingMethodMappings([...shadowArrayMapping])
                                      }}
                                    />
                                  </div>
                                </div>
                              )):null}
                           {  platformFields.sourcePlatformField && !field_mapping?.isDefault ?( <div className='col-12 col-sm-1 my-2 my-sm-0'>
                                <div className='d-flex justify-content-center align-items-center h-100'>
                                  <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                                </div>
                              </div>):null}
                              { platformFields.destinationPlatformField? platformFields.destinationPlatformField?.input_type ===
                              'SELECT' ? (
                                <div className={clsx('col-12', platformFields.sourcePlatformField === null || field_mapping.isDefault ? '' : 'col-sm-5' )}>
                                  <div className=''>
                                    <label
                                      htmlFor={`secondaryStoreShippingMethod_${index}`}
                                      className='form-check-label'
                                    >
                                      Secondary Store Method
                                    </label>
                                    <select
                                      className='form-select form-select-lg mt-2'
                                      id='secondaryStoreShippingMethod'
                                      name={`mappings[${index}][destination_platform_data_id]`}
                                      value={mapping?.destination_platform_data_id}
                                      onChange={(ev) => {
                                        let val: ShippingMethodMapping = {
                                          id: mapping.id ? mapping.id : null,
                                          destination_platform_data_id: ev.target.value,
                                        }
                                        if (
                                          platformFields.sourcePlatformField?.input_type ===
                                          'SELECT'
                                        ) {
                                          val.source_platform_data_id =
                                            mapping.source_platform_data_id
                                        } else {
                                          val.source_platform_data_str =
                                            mapping.source_platform_data_str
                                        }
                                        let shadowArrayMapping = [...shippingMethodMappings]
                                        shadowArrayMapping[index] = val

                                        let shadowFieldMappings = [...fieldMappings]
                                        let existingFieldMapping = shadowFieldMappings.find((item)=>(item.fieldCode===field_mapping.field_code))
                                        let existingFieldMappingIndex = shadowFieldMappings.findIndex((item)=>(item.fieldCode===field_mapping.field_code))
                                        
                                        if(existingFieldMapping){
                                          existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                          shadowFieldMappings[existingFieldMappingIndex] = existingFieldMapping
                                          setFieldMappings(shadowFieldMappings)
                                        }else{
                                          setFieldMappings((pre)=>{
                                            return [...pre,
                                              {
                                                groupCode:settingName,
                                                fieldCode: field_mapping.field_code,
                                                savedSettingId:settingId,
                                                savedMappingId:savedMapping?.id!,
                                                destinationFieldId:platformFields.destinationPlatformField?.id!,
                                                sourceFieldId:platformFields.sourcePlatformField?.id ?? null ,
                                                fieldMappingDataSet:shadowArrayMapping
                                              }
                                            ]
                                          })
                                        }
                                        setShippingMethodMappings([...shadowArrayMapping])
                                      }}
                                    >
                                      <option value=''>Open this select menu</option>
                                      {shipmentMappingFieldData
                                        ?.destinationPlatformFieldData?.length &&
                                        shipmentMappingFieldData.destinationPlatformFieldData?.map(
                                          (item: platformfielddata_set) => {
                                            return <option value={item.id}>{item.api_name}</option>
                                          }
                                        )}
                                    </select>
                                  </div>
                                </div>
                              ) : (
                                <div className='col-12 col-sm-5'>
                                  <div className=''>
                                    <label
                                      htmlFor={`secondaryStoreShippingMethod_${index}`}
                                      className='form-check-label'
                                    >
                                      Secondary Store Method
                                    </label>
                                    <input
                                      type='text'
                                      placeholder='Add a method'
                                      className='form-control form-control-lg mt-2'
                                      name={`mappings[${index}][destination_platform_data_str]`}
                                      value={mapping.destination_platform_data_str}
                                      onChange={(ev) => {
                                        let val: ShippingMethodMapping = {
                                          id: mapping.id ? mapping.id : null,
                                          destination_platform_data_str: ev.target.value,
                                        }
                                        if (
                                          platformFields.sourcePlatformField?.input_type ===
                                          'SELECT'
                                        ) {
                                          val.source_platform_data_id =
                                            mapping.source_platform_data_id
                                        } else {
                                          val.source_platform_data_str =
                                            mapping.source_platform_data_str
                                        }
                                        let shadowArrayMapping = [...shippingMethodMappings]
                                        shadowArrayMapping[index] = val

                                        let shadowFieldMappings = [...fieldMappings]
                                        let existingFieldMapping = shadowFieldMappings.find((item)=>(item.fieldCode===field_mapping.field_code))
                                        let existingFieldMappingIndex = shadowFieldMappings.findIndex((item)=>(item.fieldCode===field_mapping.field_code))
                                        
                                        if(existingFieldMapping){
                                          existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                                          shadowFieldMappings[existingFieldMappingIndex] = existingFieldMapping
                                          setFieldMappings(shadowFieldMappings)
                                        }else{
                                          setFieldMappings((pre)=>{
                                            return [...pre,
                                              {
                                                groupCode:settingName,
                                                fieldCode: field_mapping.field_code,
                                                savedSettingId:settingId,
                                                savedMappingId:savedMapping?.id!,
                                                destinationFieldId:platformFields.destinationPlatformField?.id!,
                                                sourceFieldId:platformFields.sourcePlatformField?.id ?? null ,
                                                fieldMappingDataSet:shadowArrayMapping
                                              }
                                            ]
                                          })
                                        }
                                        setShippingMethodMappings([...shadowArrayMapping])
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : null }
                              {index !== 0 && shippingMethodMappings?.length > 1 ? (
                                <div className='align-content-end col-12 col-sm-1'>
                                  <div className='d-flex justify-content-end'>
                                    <button
                                      type='button'
                                      onClick={() => {
                                        let copyMppings = [...shippingMethodMappings]
                                        if (mapping.id) {
                                          dispatch(
                                            deleteFieldMappingAsync({mappingDataId: mapping.id})
                                          ).then((response) => {
                                            successToast(response.payload.message)
                                            
                                            if(savedMapping){
                                              setLoadingMappingFields(true)
                                              dispatch(getSavedFieldMappingsDataAsyncByMappingId({mappingId:savedMapping.id}))
                                              .then((response) => {
                                                if(response?.payload?.fieldmappingdata_set?.length){
                                                  const mappings =
                                                    response?.payload?.fieldmappingdata_set.map(
                                                      (item) => ({
                                                        id: item?.id,
                                                        destination_platform_data_id:
                                                          item?.destination_platform_data?.id + '' ??
                                                          '',
                                                        source_platform_data_id:
                                                          item?.source_platform_data?.id + '' ?? '',
                                                        destination_platform_data_str:
                                                          item.destination_platform_data_str ?? '',
                                                        source_platform_data_str:
                                                          item.source_platform_data_str ?? '',
                                                      })
                                                    )
                                                  setShippingMethodMappings(mappings)
                                                }
                                                setLoadingMappingFields(false)
                                              })
                                            }
                                          })
                                        } else {
                                          let filteredMappings = copyMppings.filter((it, ind) => {
                                            if (ind !== index) {
                                              return it
                                            }
                                          })
                                          setShippingMethodMappings([...filteredMappings])
                                        }
                                      }}
                                      className='btn btn-sm mt-2'
                                      style={{color: 'red'}}
                                    >
                                      <FontAwesomeIcon
                                        icon={faDeleteLeft}
                                        style={{fontSize: '20px'}}
                                      />
                                    </button>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        )
                      })
                    : null}
                 { platformFields.sourcePlatformField && !field_mapping?.isDefault  ?
                  <div className='d-flex justify-content-end'>
                    <button
                      className='btn btn-primary btn-sm me-3'
                      type='button'
                      onClick={() => {
                        let newItem: ShippingMethodMapping = {id: null}
                        if (platformFields.sourcePlatformField?.input_type === 'SELECT') {
                          newItem.source_platform_data_id = ''
                        } else {
                          newItem.source_platform_data_str = ''
                        }

                        if (
                          platformFields.destinationPlatformField?.input_type === 'SELECT'
                        ) {
                          newItem.destination_platform_data_id = ''
                        } else {
                          newItem.destination_platform_data_str = ''
                        }

                        let shadowArrayMapping = [...shippingMethodMappings]
                        shadowArrayMapping.push(newItem)

                        let shadowFieldMappings = [...fieldMappings]
                        let existingFieldMapping = shadowFieldMappings.find((item)=>(item.fieldCode===field_mapping.field_code))
                        let existingFieldMappingIndex = shadowFieldMappings.findIndex((item)=>(item.fieldCode===field_mapping.field_code))
                        
                        if(existingFieldMapping){
                          existingFieldMapping.fieldMappingDataSet = shadowArrayMapping
                          shadowFieldMappings[existingFieldMappingIndex] = existingFieldMapping
                          setFieldMappings(shadowFieldMappings)
                        }else{
                          setFieldMappings((pre)=>{
                            return [...pre,
                              {
                                groupCode:settingName,
                                fieldCode: field_mapping.field_code,
                                savedSettingId:settingId,
                                savedMappingId:savedMapping?.id!,
                                destinationFieldId:platformFields.destinationPlatformField?.id!,
                                sourceFieldId:platformFields.sourcePlatformField?.id ?? null ,
                                fieldMappingDataSet:shadowArrayMapping
                              }
                            ]
                          })
                        }
                        setShippingMethodMappings([...shadowArrayMapping])
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>{' '}
                  </div> : null
                  }

                  {/* { platformFields.sourcePlatformField || platformFields.destinationPlatformField ? 
                  <div className='d-flex justify-content-end'>
                    <button
                      type='button'
                      form='mapping_form'
                      className='btn btn-success btn-sm mt-2 me-3 align-self-end'
                      onClick={() => !platformFields.sourcePlatformField ? handleSingleValues(values.mappings, setSubmitting):   handleSaveShipmentMappings(values.mappings, setSubmitting)}
                    >
                      {loadingMappingSave || loadingLocationOption? 'Saving...' : 'Save Mapping'}
                    </button>
                  </div> : null } */}
                </div>
              </div>
            </div>
    )
  
}

export default MappingInput
