import moment from 'moment'
import {FC} from 'react'
import {Button} from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import StatusButton from '../orders/StatusButton'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRotate} from '@fortawesome/free-solid-svg-icons'

const ColProductName: FC<{
  name: string
}> = ({name}) => {
  return (
    <>
      <div className='d-flex align-items-center'>
        <div className='ms-2'>
          <p className='mb-0'>
            <Button
              variant='link'
              // className=' fw-bold fs-5 link-underline-info py-0 text-success text-decoration-underline mb-0'
              className=' fw-bold fs-5 py-0 text-primary mb-0'
            >
              {name}
            </Button>
          </p>
          {/* <p className='h6 text-muted'>{name.subtitle}</p> */}
        </div>
      </div>
    </>
  )
}

const CommonColumn: FC<{rowData: string}> = ({rowData}) => {
  return (
    <>
      <p className='mb-0 fw-bold fs-6'>{rowData}</p>
    </>
  )
}

const Invoices: FC = () => {

  
  const columns = [
    {
      name: 'Order #',
      cell: (row) => <ColProductName name={row.orderNumber} />,
      center: true,
    },
    {
      name: 'Sync Date',
      cell: (row) => (
        <CommonColumn rowData={moment.utc(new Date(row.syncDate)).format('hh:mm:A, DD-MMM-YY')} />
      ),
      sortable: true,
      center: true,
    },
   
    {
      name: 'Sync Status',
      cell: (row) =>
        row ? (
          row?.syncStatus === 'ERROR' || row?.syncStatus === 'ERROR_UNLINKED' ? (
            <div className='d-flex' style={{paddingLeft:'30px'}} >
                <StatusButton syncStatus={row?.syncStatus} syncMessage={row?.sync_message} />
              <button className=' btn btn-sm px-2 '>
                <FontAwesomeIcon icon={faRotate} color='red' size='lg' />
              </button>
            </div>
          ) : (
            <StatusButton syncStatus={row?.syncStatus} syncMessage={row?.sync_message} />
          )
        ) : null,
      center:true,
      right:true,
      style:{
        marginLeft:'30px'
      }
    },
    {
      name: 'Channel',
      cell: (row) => <CommonColumn rowData={row.channel} />,
      center:true,
    },
    {
      name: 'Invoice #',
      cell: (row) => <CommonColumn rowData={row.invoiceNumber} />,
      sortable: true,
      center: true,
    },
    
  ]

  const invoiceLogs = [
    {
      orderNumber: 'ORD123456',
      syncDate: '2024-08-12',
      syncStatus: 'SYNCED',
      channel: 'Amazon',
      invoiceNumber: 'INV987654',
    },
    {
      orderNumber: 'ORD123457',
      syncDate: '2024-08-12',
      syncStatus: 'PENDING',
      channel: 'eBay',
      invoiceNumber: 'INV987655',
    },
    {
      orderNumber: 'ORD123458',
      syncDate: '2024-08-11',
      syncStatus: 'PENDING',
      channel: 'Shopify',
      invoiceNumber: 'INV987656',
    },
    {
      orderNumber: 'ORD123459',
      syncDate: '2024-08-10',
      syncStatus: 'SUCCESS',
      channel: 'Walmart',
      invoiceNumber: 'INV987657',
    },
    {
      orderNumber: 'ORD123460',
      syncDate: '2024-08-09',
      syncStatus: 'ERROR',
      channel: 'Etsy',
      invoiceNumber: 'INV987658',
    },
    {
      orderNumber: 'ORD123461',
      syncDate: '2024-08-08',
      syncStatus: 'PENDING',
      channel: 'Amazon',
      invoiceNumber: 'INV987659',
    },
    {
      orderNumber: 'ORD123462',
      syncDate: '2024-08-07',
      syncStatus: 'SYNCED',
      channel: 'eBay',
      invoiceNumber: 'INV987660',
    },
    {
      orderNumber: 'ORD123463',
      syncDate: '2024-08-06',
      syncStatus: 'SYNCED',
      channel: 'Shopify',
      invoiceNumber: 'INV987661',
    },
    {
      orderNumber: 'ORD123464',
      syncDate: '2024-08-05',
      syncStatus: 'ERROR',
      channel: 'Walmart',
      invoiceNumber: 'INV987662',
    },
    {
      orderNumber: 'ORD123465',
      syncDate: '2024-08-04',
      syncStatus: 'ERROR',
      channel: 'Etsy',
      invoiceNumber: 'INV987663',
    },
    {
      orderNumber: 'ORD123466',
      syncDate: '2024-08-03',
      syncStatus: 'SUCCESS',
      channel: 'Amazon',
      invoiceNumber: 'INV987664',
    },
    {
      orderNumber: 'ORD123467',
      syncDate: '2024-08-02',
      syncStatus: 'SUCCESS',
      channel: 'eBay',
      invoiceNumber: 'INV987665',
    },
    {
      orderNumber: 'ORD123468',
      syncDate: '2024-08-01',
      syncStatus: 'SUCCESS',
      channel: 'Shopify',
      invoiceNumber: 'INV987666',
    },
    {
      orderNumber: 'ORD123469',
      syncDate: '2024-07-31',
      syncStatus: 'SUCCESS',
      channel: 'Walmart',
      invoiceNumber: 'INV987667',
    },
    {
      orderNumber: 'ORD123470',
      syncDate: '2024-07-30',
      syncStatus: 'PENDING',
      channel: 'Etsy',
      invoiceNumber: 'INV987668',
    },
  ]

  return (
    <>
      <div className=''>
       <h3>Invoice Logs</h3>
      </div>
      <div className='card p-0 shadow-sm'>
        <div className='card-header'>
          <div className='card-title'>
            <input
              className='form-control me-2'
              type='search'
              placeholder='Search'
              aria-label='Search'
            />
          </div>
          <div className='card-toolbar'>
            <div className='d-flex flex-sm-row   '>
              <select className='form-select me-2 w-sm-200px' name='' id='statusFilter'>
                <option value=''>Select Status</option>
                <option value='LINKED'>LINKED</option>
                <option value='PENDING'>PENDING</option>
                <option value='CREATED'>CREATED</option>
                <option value='ERROR'>ERROR</option>
              </select>

              <button className='btn btn-sm btn-primary px-2 fw-bold w-100px'>Reset Filters</button>
            </div>
          </div>
        </div>
        <div className='card-body pb-0'>
          <DataTable
            title=''
            fixedHeader={true}
            data={invoiceLogs}
            columns={columns}
            selectableRows
            paginationTotalRows={20}
            pagination
            paginationServer
            customStyles={{
              table: {
                style: {
                  height: '50vh',
                },
              },
              headCells: {
                style: {fontWeight: 'bold', fontSize: '14px'},
              },
              cells: {
                style: {
                  padding: '10px',
                  borderBottom: '1px solid gainsboro',
                },
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

export default Invoices
