import React, {useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {getOrderDetailsAsync} from 'src/redux/actions/orderActions'
import {useAppDispatch, useAppSelector} from 'src/redux/storeHooks'

import OrderSummary1 from './components/OrderSummary1'

const OrderDetails = () => {
  const dispatch = useAppDispatch()
  const {loadingOrderDetails, orderDetails} = useAppSelector((state) => state.orderDetailsData)

  const {orderId} = useParams()

  useEffect(() => {
    const getOrderDetailsFromId = () => {
      try {
        if (orderId !== undefined) {
          // Dispatch the async thunk action with an object parameter
          dispatch(getOrderDetailsAsync(orderId))
        }
      } catch (err) {
        console.error('Error fetching order details:', err)
      }
    }

    // Call the getOrderDetailsFromId function
    getOrderDetailsFromId()
  }, [orderId])

  // ... rest of your component code

  console.log('Order details:', orderDetails)
  console.log('Order loading:', loadingOrderDetails)

  return (
    <div>
              <h1 className='page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0'>
                Order Details
              </h1>
              <div className='separator my-4' ></div>
              <OrderSummary1 />
    
    </div>
  )
}

export default OrderDetails
