import React, {FC, useState} from 'react'
import Dropzone from 'react-dropzone'
import {KTSVG} from 'src/marketConnect/helpers'
import MappedVariantSubItem from './MappedVariantSubItem'
import { InitialValuesForCreateProduct, OPTION_ADD_ITEM, OPTION_VALUE } from 'src/interfaces/createProductInterface'

type MappedVariantItemProps = {
  item : OPTION_VALUE, 
  index : number , 
  mapIndex : number , 
  options : OPTION_ADD_ITEM[]|[]
  values:InitialValuesForCreateProduct
}

const MappedVariantItem:FC<MappedVariantItemProps> = ({item, index, mapIndex, options,values}) => {
  const [selectedImages, setSelectedImages] = useState<string | null>(null)
  const [isQuantityFieldDisable, setIsQuantityFieldDisable] = useState<boolean>(true)

  return (
    <div className=''>
      <div>
        <div className='row w-100 align-items-center'>
          <div className='col-2'>
            <Dropzone
              onDrop={(acceptedFiles) => {
                console.log(acceptedFiles)
                if (acceptedFiles.length) {
                  setSelectedImages(URL.createObjectURL(acceptedFiles[0]))
                }
              }}
            >
              {({getRootProps, getInputProps}) => (
                <section>
                  <div {...getRootProps()} className='text-center'>
                    <input {...getInputProps()} />
                    {!selectedImages ? (
                      <KTSVG
                        path='media/icons/duotune/general/gen006.svg'
                        className='svg-icon-muted svg-icon-3hx cursor-pointer'
                      />
                    ) : (
                      <img src={selectedImages} className='h-40px w-40px shadow cursor-pointer' />
                    )}
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
          <div className='col-4'>
            <div
              className='accordion-header py-3 d-flex'
              data-bs-toggle='collapse'
              data-bs-target={`#kt_accordion_mappedVariantItem${index + 1}_item_${mapIndex + 1}`}
            >
              <div className='w-100'>
                <h5 className='mb-0'>{item.value}</h5>
                {options?.length === 2 ? (
                  <p className=' mb-0 p-0 w-120px text-primary d-flex align-items-center'>
                    {options[index + 1]?.option_values?.length + ' Variants'}
                    <span className='accordion-icon'>
                      <KTSVG
                        className='svg-icon svg-icon-4'
                        path='media/icons/duotune/arrows/arr064.svg'
                      />
                    </span>
                  </p>
                ) : options?.length === 3 ? (
                  <p className=' mb-0 p-0 w-120px text-primary d-flex align-items-center'>
                    {options[index + 1]?.option_values?.length *
                      options[index + 2]?.option_values?.length +
                      ' Variants'}
                    <span className='accordion-icon'>
                      <KTSVG
                        className='svg-icon svg-icon-4'
                        path='media/icons/duotune/arrows/arr064.svg'
                      />
                    </span>
                  </p>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className='col-4'>
            <input type='text' defaultValue={values.basePrice} className='form-control form-control-sm' />
          </div>
          <div className='col-2'>
            <input type='text' defaultValue={30} disabled={isQuantityFieldDisable} className='form-control form-control-sm' />
          </div>
        </div>
        <div className='separator' ></div>
      </div>
      <div
        id={`kt_accordion_mappedVariantItem${index + 1}_item_${mapIndex + 1}`}
        className='fs-6 collapse show'
        data-bs-parent={`#kt_accordion_mappedVariantItem${index}`}
      >
        {/* when only 2 variants added  */}
        {options[1]?.option_values?.length && !options[2]?.option_values?.length ? (
          <div
            className='accordion accordion-icon-toggle'
            id={`kt_accordion_mappedVariantItem${index + 1}`}
          >
            {options[1]?.option_values.map((mappedValue, innerMapIndex) => {
              return (
                <div>
                  <MappedVariantSubItem
                    key={mapIndex+innerMapIndex}
                    index={innerMapIndex}
                    item={mappedValue.value}
                    mapIndex={mapIndex}
                    options={options}
                    values={values}
                  />
                </div>
              )
            })}
          </div>
        ) : null}

        {/* when 3 variants are added  */}
        {options[1]?.option_values?.length && options[2]?.option_values?.length !== 0
          ? options[1]?.option_values.map((mappedValue1, innerMapIndex1) => {
              return options[2]?.option_values.map((mappedValue2, innerMapIndex2) => {
                return (
                  <div>
                    <MappedVariantSubItem
                      index={innerMapIndex1}
                      item={mappedValue1.value + '/' + mappedValue2.value}
                      mapIndex={mapIndex}
                      options={options}
                      values={values}
                    />
                  </div>
                )
              })
            })
          : null}
      </div>
      <div className="separator"></div>
    </div>
  )
}

export default MappedVariantItem
