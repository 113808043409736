import React, { FC, useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import { hideDialog } from 'src/redux/slices/errorSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/storeHooks'

const AlertError = () => {
    
  const { isVisible, message, messageType, subtitle } = useAppSelector(state=>state.error)
    

  return (
    <Alert show={isVisible} variant={messageType} className=' position-absolute end-0 top-2 w-25 me-2 mt-2 alert alert-dismissible d-flex flex-column flex-sm-row p-5 mb-10' >

    {/* <div className='alert alert-dismissible bg-light-primary d-flex flex-column flex-sm-row p-5 mb-10'> */}
      <span className='svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0'></span>
      <div className='d-flex flex-column  pe-0 pe-sm-10'>
        <Alert.Heading>{message}</Alert.Heading>
        <span>
          {subtitle}
        </span>
      </div>

      <button
        type='button'
        className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto'
        data-bs-dismiss='alert'
        >
        <span className='svg-icon svg-icon-1 svg-icon-primary'>x</span>
      </button>
    {/* </div> */}
</Alert>
  )
}

export default AlertError
