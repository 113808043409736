class MyUploadAdapter {
    loader: any;

    constructor(loader: any) {
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then((file: Blob) => {
                if (!file.type.startsWith('image/')) {
                    return Promise.reject('Invalid file type');
                }

                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => {
                        resolve({ default: reader.result as string });
                    };
                    reader.onerror = (error) => {
                        reject(error);
                    };
                    reader.readAsDataURL(file);
                });
            });
    }

    // Aborts the upload process.
    abort() {
        if (this.loader) {
            this.loader.abort();
        }
    }
}

export function CustomUploadAdapterPlugin(editor: any) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
        return new MyUploadAdapter(loader);
    };
}
